import { Modal } from '@pankod/refine'
import React from 'react'
import styled from 'styled-components'

interface Props {
  uploads: string[] | undefined
  closeModal: () => void
}

const SpecialtyPhotos = ({ uploads, closeModal }: Props) => {
  return (
    <Modal
      visible={(uploads || [])?.length > 0}
      footer={null}
      onCancel={closeModal}
    >
      <Title>المرفقات</Title>
      <Body>
        <PhotosRow>
          {(uploads || []).map((photo, index) => {
            return (
              <PhotosWrapper key={index}>
                <Link href={photo} target="_blank">
                  <ProfilePic src={photo} width={'80'} height={'80'} />
                </Link>
              </PhotosWrapper>
            )
          })}
        </PhotosRow>
      </Body>
    </Modal>
  )
}

export default SpecialtyPhotos

const Title = styled.p`
  direction: rtl;
  text-align: center;
  font-size: 20px;
`

const Body = styled.div`
  padding: 10px 40px 30px;
  direction: rtl;
`

const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const ProfilePic = styled.img<{ width?: string; height?: string }>`
  border-radius: 10px;
  width: ${(props) => (props.width ? `${props.width}` : '55px')};
  height: ${(props) => (props.height ? `${props.height}` : '55px')};
  margin-top: 10px;
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`

export const PhotosWrapper = styled.div`
  position: relative;
`

export const Link = styled.a`
  text-decoration: none;
  margin-right: 5px;
`
