import React, { useCallback, useEffect, useState } from 'react'
import '@pankod/refine/dist/styles.min.css'
import { ErrorComponent, Refine } from '@pankod/refine'
import routerProvider from '@pankod/refine-react-router'
import dataProvider from './Containers/Providers/DataProvider'
import AuthProvider from './Containers/Providers/AuthProvider'
import { API_URL } from './Configs'
import AdminList from './Containers/Admin/AdminsListing'
import {
  RiAdminFill,
  RiContactsBook2Fill,
  RiHandHeartFill,
} from 'react-icons/ri'
import { BiComment } from 'react-icons/bi'
import { AiFillDashboard, AiOutlineHome } from 'react-icons/ai'
import arEG from 'antd/lib/locale/ar_EG'
import {
  MdOutlineFolderSpecial,
  MdOutlinePlace,
  MdCardMembership,
  MdSettings,
  MdOutlineAnnouncement,
  MdOutlineLocalPolice,
} from 'react-icons/md'
import { SiGooglenews } from 'react-icons/si'
import { GiChoice } from 'react-icons/gi'
import {
  axiosInstance,
  CreateDeviceAction,
  FindAllSpecialties,
  LogoutAction,
} from './Containers/Store/Actions'
import { LoginPage } from './Containers/Auth/Login/Login'
import { AccountStore, ConfigsStore } from './Containers/Store'
import dayjs from 'dayjs'
import { CreateAdmin } from './Containers/Admin/CreateAdmin'
import EditAdmin from './Containers/Admin/EditAdmin'
import ShowAdmin from './Containers/Admin/ShowAdmin'
import { Header } from './Components/Header/header'
import logo from './Assets/Images/whiteLogo.png'
import { FaUsers, FaSearch } from 'react-icons/fa'
import LawyerList from './Containers/Lawyer/LawyersListing'
import ShowLawyer from './Containers/Lawyer/ShowLawyer'
import { BsBuilding, BsBook } from 'react-icons/bs'
import ComapnyList from './Containers/Company/CompanyListing'
import ShowCompany from './Containers/Company/ShowCompany'
import { ForgotPassword } from './Containers/Auth/ForgotPassword'
import { Verification } from './Containers/Auth/Verification'
import { ResetPassword } from './Containers/Auth/ResetPassword'
import BlogsList from './Containers/Blogs/BlogsList'
import ShowBlog from './Containers/Blogs/ShowBlog'
import CommentsList from './Containers/Comments/CommentsListing'
import ShowComment from './Containers/Comments/ShowComment'
import DashboardPage from './Containers/Dashboard'
import EditCompany from './Containers/Company/EditCompany'
import { CreateCompany } from './Containers/Company/CreateCompany'
import { CreateLawyer } from './Containers/Lawyer/CreateLawyer'
import EditLawyer from './Containers/Lawyer/EditLawyer'
import SpecialtyList from './Containers/Specialty/SpecialtyListing'
import ShowSpecialty from './Containers/Specialty/ShowSpecialty'
import EditSpecialty from './Containers/Specialty/EditSpecialty'
import { CreateSpecialty } from './Containers/Specialty/CreateSpecialty'
import NewsLetterListing from './Containers/NewsLetter/NewsLetterListing'
import ShowNewsLetter from './Containers/NewsLetter/ShowNewsLetter'
import EditNewsLetter from './Containers/NewsLetter/EditNewsLetter'
import SystemConfigs from './Containers/Country/CountryList'
import EditCountry from './Containers/Country/EditCountry'
import EditCity from './Containers/City/EditCity'
import CityList from './Containers/City/CityList'
import { CreateCity } from './Containers/City/CreateCity'
import ShowCity from './Containers/City/showCity'
import ShowCountry from './Containers/Country/ShowCountry'
import { CreateCountry } from './Containers/Country/CreateCountry'
import MembershipList from './Containers/Membership/MembershipList'
import ShowMembership from './Containers/Membership/ShowMembership'
import EditMembership from './Containers/Membership/EditMembership'
import { CreateMembership } from './Containers/Membership/CreateMembership'
import AssociationList from './Containers/Association/AssociationList'
import { CreateAssociation } from './Containers/Association/CreateAssociation'
import { EditAssociation } from './Containers/Association/EditAssociation'
import ShowAssociation from './Containers/Association/ShowAssociation'
import SystemConfigsList from './Containers/SystemConfigs/SystemConfigsList'
import { CreateSystemConfigs } from './Containers/SystemConfigs/CreateSystemConfigs'
import EditSystemConfigs from './Containers/SystemConfigs/EditSystemConfigs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ContactUsList from './Containers/ContactUs/ContactUsList'
import ShowContactUs from './Containers/ContactUs/ShowContactUs'
import SearchConfigsList from './Containers/SearchConfigs/SearchConfigsList'
import ShowSearchConfigs from './Containers/SearchConfigs/ShowSearchConfigs'
import { CreateSearchConfigs } from './Containers/SearchConfigs/CreateSearchConfigs'
import { EditSearchConfigs } from './Containers/SearchConfigs/EditSearchConfigs'
import LawyerSuggestionList from './Containers/LawyerSuggestion/LawyerSuggestionList'
import { CreateLawyerSuggestion } from './Containers/LawyerSuggestion/CreateLawyerSuggestion'
import ShowLawyerSuggestion from './Containers/LawyerSuggestion/ShowLawyerSuggestion'
import { EditLawyerSuggestion } from './Containers/LawyerSuggestion/EditLawyerSuggestion'
import AnnouncementList from './Containers/Announcement/AnnouncementList'
import { CreateAnnouncement } from './Containers/Announcement/CreateAnnouncement'
import { EditAnnouncement } from './Containers/Announcement/EditAnnouncement'
import ShowAnnouncement from './Containers/Announcement/ShowAnnouncement'
import CourtDegreeList from './Containers/CourtDegree/CourtDegreeList'
import ShowCourtDegree from './Containers/CourtDegree/ShowCourtDegree'
import { EditCourtDegree } from './Containers/CourtDegree/EditCourtDegree'
import { CreateCourtDegree } from './Containers/CourtDegree/CreateCourtDegree'
import ShowHomePageContent from './Containers/HomePageContent/ShowHomePageContent'
import HomePageContentList from './Containers/HomePageContent/HomePageContentList'
import EditHomePageContent from './Containers/HomePageContent/EditHomePageContent'

const Routes = () => {
  const { t, i18n } = useTranslation()

  const [checkCookie, setCheckCookie] = useState<any>()
  const [checkSessionStorage, setSessionStorage] = useState<any>()
  const [loggedIn, setLoggedIn] = useState<boolean>()
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => 'ar',
  }

  // const { RouterComponent } = routerProvider
  // const CustomRouterComponent = () => <RouterComponent basename="/admin" />

  const getCookie = async () => {
    setCheckCookie(await ConfigsStore.get('cookieExpiration'))
    setLoggedIn(await ConfigsStore.get('logged_in'))
    setSessionStorage(await sessionStorage.getItem('logoutOnEndSession'))
  }

  const getCountry = useCallback(async () => {
    const country = await ConfigsStore.get('country')
    if (country) return
    const account = await AccountStore.get('account')
    ConfigsStore.set('country', account?.countries && account?.countries[0]?.id)
  }, [])

  useEffect(() => {
    if (checkCookie?.value) {
      if (dayjs(checkCookie?.expiry).format() <= dayjs(new Date()).format()) {
        LogoutAction(true)
      }
    } else {
      if (checkSessionStorage !== 'true' && loggedIn) {
        LogoutAction(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCookie, checkCookie?.value, checkSessionStorage])

  useEffect(() => {
    getCookie()
    CreateDeviceAction()
    FindAllSpecialties()
  }, [])

  useEffect(() => {
    getCountry()
  }, [getCountry])

  return (
    <Refine
      Title={({ collapsed }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
            marginBottom: 10,
          }}
        >
          {!collapsed && <img width="80" src={logo} alt="logo" />}
          {collapsed && <img width="50" src={logo} alt="logo" />}
        </div>
      )}
      authProvider={AuthProvider}
      LoginPage={LoginPage}
      i18nProvider={i18nProvider}
      configProviderProps={{
        direction: 'rtl',
        locale: arEG,
      }}
      Header={Header}
      routerProvider={{
        ...routerProvider,
        // RouterComponent: CustomRouterComponent,
        routes: [
          {
            exact: true,
            component: loggedIn ? ErrorComponent : ForgotPassword,
            path: '/forgot-password',
          },
          {
            exact: true,
            component: loggedIn ? ErrorComponent : Verification,
            path: '/verification',
          },
          {
            exact: true,
            component: loggedIn ? ErrorComponent : ResetPassword,
            path: '/reset-password',
          },
        ],
      }}
      dataProvider={dataProvider(API_URL, axiosInstance)}
      resources={[
        {
          name: 'dashboard',
          options: { label: `لوحة التحكم` },
          list: DashboardPage,
          icon: <AiFillDashboard size={20} />,
        },
        {
          name: 'admin',
          options: { label: `${'المشرفين'}` },
          list: AdminList,
          create: CreateAdmin,
          edit: EditAdmin,
          show: ShowAdmin,
          icon: <RiAdminFill size={20} />,
        },
        {
          name: 'lawyer',
          options: { label: `${'المحامين'}` },
          list: LawyerList,
          show: ShowLawyer,
          create: CreateLawyer,
          edit: EditLawyer,
          icon: <FaUsers size={20} />,
        },
        {
          name: 'company',
          options: { label: `${'الشركات'}` },
          list: ComapnyList,
          show: ShowCompany,
          edit: EditCompany,
          create: CreateCompany,
          icon: <BsBuilding size={20} />,
        },
        {
          name: 'blog',
          options: { label: `${'المجلة القانونية'}` },
          list: BlogsList,
          show: ShowBlog,
          icon: <BsBook size={20} />,
        },
        {
          name: 'comment',
          options: { label: `${'التعليقات'}` },
          list: CommentsList,
          show: ShowComment,
          icon: <BiComment size={20} />,
        },
        {
          name: 'specialty',
          options: { label: `${'التخصصات'}` },
          list: SpecialtyList,
          show: ShowSpecialty,
          create: CreateSpecialty,
          edit: EditSpecialty,
          icon: <MdOutlineFolderSpecial size={20} />,
        },
        {
          name: 'newsletter',
          options: { label: `${'مجلتنا'}` },
          list: NewsLetterListing,
          show: ShowNewsLetter,
          edit: EditNewsLetter,
          icon: <SiGooglenews size={20} />,
        },
        {
          name: 'announcement',
          options: { label: `${'الإعلانات'}` },
          list: AnnouncementList,
          show: ShowAnnouncement,
          edit: EditAnnouncement,
          create: CreateAnnouncement,
          icon: <MdOutlineAnnouncement size={20} />,
        },

        {
          name: 'home-page-content',
          options: { label: `${'الصفحة الرئيسية'}` },
          show: ShowHomePageContent,
          list: HomePageContentList,
          edit: EditHomePageContent,
          icon: <AiOutlineHome size={20} />,
        },

        {
          name: 'membership',
          options: { label: `${'العضوية'}` },
          list: MembershipList,
          show: ShowMembership,
          edit: EditMembership,
          create: CreateMembership,
          icon: <MdCardMembership size={20} />,
        },
        {
          name: 'association',
          options: { label: `${'الجهات'}` },
          list: AssociationList,
          show: ShowAssociation,
          edit: EditAssociation,
          create: CreateAssociation,
          icon: <RiHandHeartFill size={20} />,
        },
        {
          name: 'courtDegree',
          options: { label: `${'درجات المحاكم'}` },
          list: CourtDegreeList,
          show: ShowCourtDegree,
          edit: EditCourtDegree,
          create: CreateCourtDegree,
          icon: <MdOutlineLocalPolice size={20} />,
        },
        {
          name: 'suggestion',
          options: { label: `${'اقتراحات المحامين'}` },
          list: LawyerSuggestionList,
          show: ShowLawyerSuggestion,
          edit: EditLawyerSuggestion,
          create: CreateLawyerSuggestion,
          icon: <GiChoice size={20} />,
        },
        {
          name: 'search-configs',
          options: { label: `${'إعدادات البحث'}` },
          list: SearchConfigsList,
          show: ShowSearchConfigs,
          edit: EditSearchConfigs,
          create: CreateSearchConfigs,
          icon: <FaSearch size={20} />,
        },
        {
          name: 'contact-us',
          options: { label: `${'تواصل معنا'}` },
          list: ContactUsList,
          show: ShowContactUs,
          icon: <RiContactsBook2Fill size={20} />,
        },
        {
          name: 'country',
          options: { label: `${'الدول'}` },
          list: SystemConfigs,
          show: ShowCountry,
          edit: EditCountry,
          create: CreateCountry,
          icon: <MdOutlinePlace size={20} />,
        },
        {
          name: 'city',
          options: { label: `${'المدن'}` },
          list: CityList,
          show: ShowCity,
          edit: EditCity,
          create: CreateCity,
          icon: <MdOutlinePlace size={20} />,
        },
        {
          name: 'system-configs',
          options: { label: `${'إعدادات النظام'}` },
          list: SystemConfigsList,
          edit: EditSystemConfigs,
          create: CreateSystemConfigs,
          icon: <MdSettings size={20} />,
        },
      ]}
    />
  )
}

export default Routes

export const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
