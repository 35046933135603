import * as React from 'react'
import { Edit, Form, Input, useForm, useNavigation } from '@pankod/refine'
import { parsePhoneNumber } from 'libphonenumber-js'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { EditAction } from '../Store/Actions'

export interface Inputs {
  name?: string
  email?: string
  phoneNumber?: string
  suggestion?: string
}

export const EditLawyerSuggestion = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const record = queryResult?.data?.data
  const { show } = useNavigation()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    phoneNumber: Yup.string().required('مطلوب'),
    email: Yup.string()
      .email('يرجى ادخال بريد الكتروني صحيح')
      .required('يرجى ادخال بريد الكتروني صحيح'),
    suggestion: Yup.string().required('مطلوب'),
  })

  const navigateToShow = () => {
    record?.id && show('suggestion', record?.id)
  }

  const { handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      suggestion: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}

      if (submittedValues.name && submittedValues.name !== record?.name) {
        inputs.name = submittedValues.name
      }

      if (submittedValues.email && submittedValues.email !== record?.email) {
        inputs.email = submittedValues.email
      }

      if (
        submittedValues.phoneNumber &&
        submittedValues.phoneNumber !== record?.phoneNumber
      ) {
        inputs.phoneNumber = submittedValues.phoneNumber
      }

      if (
        submittedValues.suggestion &&
        submittedValues.suggestion !== record?.suggestion
      ) {
        inputs.suggestion = submittedValues.suggestion
      }

      if (Object.keys(inputs)?.length) {
        EditAction('suggestion', record?.id, inputs, navigateToShow)
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => {
      handleSubmit()
    },
  }

  React.useEffect(() => {
    if (record) {
      setFieldValue('name', record?.name)
      setFieldValue('email', record?.email)
      setFieldValue('phoneNumber', record?.phoneNumber)
      setFieldValue('suggestion', record?.suggestion)
    }
  }, [record, setFieldValue])

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'تعديل الاقتراح'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'الاسم'}
          name="name"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="name" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label={'البريد الإلكتروني'}
          name={'email'}
          required
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!regex.test(email)) {
                  return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                }
              },
            },
          ]}
        >
          <Input name="email" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          required
          label={'رقم الهاتف'}
          name="phoneNumber"
          rules={[
            {
              validator: async (_, phoneNumber) => {
                const phone = parsePhoneNumber(phoneNumber, 'JO')
                if (!phone?.isValid()) {
                  return Promise.reject(new Error('رقم الهاتف غير صالح'))
                }
              },
            },
          ]}
        >
          <Input name="phoneNumber" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          required
          label={'الاقتراح'}
          name="suggestion"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input.TextArea name="suggestion" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
