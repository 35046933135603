import React from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'
import { HomePageContent } from '../Store/types'

const HomePageContentList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const { tableProps } = useTable<HomePageContent, HttpError>({
    syncWithLocation: true,
  })

  return (
    <List
      title={`${'محتوى الصفحة الرئيسية'}`}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Table
        {...tableProps}
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('home-page-content', record.id)
            },
          }
        }}
      >
        <Table.Column<any>
          title={'العنوان الرئيسي'}
          dataIndex={['main', 'title']}
          key="title"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<any>
          title={'المحتوى الأول'}
          dataIndex={['main', 'firstContent']}
          key="firstContent"
          render={(value) => (
            <TextField
              value={
                value.length > 30
                  ? `${value.substring(0, 30)}...`
                  : value || '-'
              }
            />
          )}
        />

        <Table.Column<any>
          title={'المحتوى الثاني'}
          dataIndex={['main', 'secondContent']}
          key="secondContent"
          render={(value) => (
            <TextField
              value={
                value.length > 30
                  ? `${value.substring(0, 30)}...`
                  : value || '-'
              }
            />
          )}
        />

        <Table.Column<any>
          title={'عنوان تعرف أكثر'}
          dataIndex={['learnMore', 'title']}
          key="title"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column<any>
          title={'محتوى تعرف أكثر'}
          dataIndex={['learnMore', 'content']}
          key="content"
          render={(value) => (
            <TextField
              value={
                value.length > 30
                  ? `${value.substring(0, 30)}...`
                  : value || '-'
              }
            />
          )}
        />

        <Table.Column<any>
          title={'عنوان الأسئلة الشائعة'}
          dataIndex={['afaq', 'title']}
          key="title"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column<any>
          title={'محتوى الأسئلة الشائعة'}
          dataIndex={['afaq', 'content']}
          key="content"
          render={(value) => (
            <TextField
              value={
                value.length > 30
                  ? `${value.substring(0, 30)}...`
                  : value || '-'
              }
            />
          )}
        />

        <Table.Column<any>
          title={'الدولة'}
          dataIndex={['country', 'country']}
          key="content"
          render={(value) => <TextField value={value} />}
        />

        {user?.roles !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            fixed="right"
            key="actions"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar=""
                  type="edit"
                  edit
                  record={record}
                  onClickEdit={() => edit('home-page-content', record.id)}
                />
              )
            }}
          />
        )}
      </Table>
    </List>
  )
}

export default HomePageContentList
