import React, { useEffect } from 'react'
import {
  Form,
  Input,
  Edit,
  Button,
  useForm,
  useNavigation,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAction } from '../Store/Actions'

interface Inputs {
  main?: {
    title: string
    firstContent: string
    secondContent: string
  }
  learnMore?: {
    title: string
    content: string
  }
  afaq?: {
    title: string
    content: string
  }
  afaqPoints?: {
    title: string
    content: string
  }[]
}

function EditHomePageContent() {
  const { saveButtonProps, queryResult } = useForm({
    resource: 'home-page-content',
  })
  const { show } = useNavigation()

  const navigateToShow = () => {
    record?.id && show('home-page-content', record?.id)
  }

  const record = queryResult?.data?.data

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      id: record?.id,
      mainTitle: '',
      mainFirstContent: '',
      mainSecondContent: '',
      learnMoreTitle: '',
      learnMoreContent: '',
      afaqTitle: '',
      afaqContent: '',
      afaqPoints: [] as Inputs['afaqPoints'],
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}

      if (submittedValues?.mainTitle) {
        inputs.main = {
          title: submittedValues?.mainTitle,
          firstContent: submittedValues?.mainFirstContent,
          secondContent: submittedValues?.mainSecondContent,
        }
      }

      if (submittedValues?.learnMoreTitle) {
        inputs.learnMore = {
          title: submittedValues?.learnMoreTitle,
          content: submittedValues?.learnMoreContent,
        }
      }

      if (submittedValues?.afaqTitle) {
        inputs.afaq = {
          title: submittedValues?.afaqTitle,
          content: submittedValues?.afaqContent,
        }
      }

      if (submittedValues?.afaqPoints) {
        inputs.afaqPoints = submittedValues?.afaqPoints
      }

      EditAction('home-page-content', record?.id, inputs, navigateToShow)
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  useEffect(() => {
    if (record) {
      setFieldValue('mainTitle', record?.main?.title)
      setFieldValue('mainFirstContent', record?.main?.firstContent)
      setFieldValue('mainSecondContent', record?.main?.secondContent)
      setFieldValue('learnMoreTitle', record?.learnMore?.title)
      setFieldValue('learnMoreContent', record?.learnMore?.content)
      setFieldValue('afaqTitle', record?.afaq?.title)
      setFieldValue('afaqContent', record?.afaq?.content)
      setFieldValue('afaqPoints', record?.afaqPoints)
    }
  }, [record, setFieldValue])

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل محتوى الصفحة الرئيسية'}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Form layout="vertical">
          <Form.Item label={'الرئيسية'}>
            <Form.Item required label={'العنوان'}>
              <Input
                name="mainTitle"
                onChange={handleChange}
                placeholder={'العنوان'}
                value={values.mainTitle}
              />
            </Form.Item>
            <Form.Item required label={'المحتوى الأول'}>
              <Input.TextArea
                name="mainFirstContent"
                onChange={handleChange}
                placeholder={'المحتوى الأول'}
                value={values.mainFirstContent}
              />
            </Form.Item>
            <Form.Item required label={'المحتوى الثاني'}>
              <Input.TextArea
                name="mainSecondContent"
                onChange={handleChange}
                placeholder={'المحتوى الثاني'}
                value={values.mainSecondContent}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label={'تعلم أكثر'}>
            <Form.Item required label={'العنوان'}>
              <Input
                name="learnMoreTitle"
                onChange={handleChange}
                placeholder={'العنوان'}
                value={values.learnMoreTitle}
              />
            </Form.Item>
            <Form.Item required label={'العنوان'}>
              <Input.TextArea
                name="learnMoreContent"
                onChange={handleChange}
                placeholder={'المحتوى'}
                value={values.learnMoreContent}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label={'الأسئلة الشائعة'}>
            <Form.Item required label={'العنوان'}>
              <Input
                name="afaqTitle"
                onChange={handleChange}
                placeholder={'العنوان'}
                value={values.afaqTitle}
              />
            </Form.Item>
            <Form.Item required label={'المحتوى'}>
              <Input.TextArea
                name="afaqContent"
                onChange={handleChange}
                placeholder={'المحتوى'}
                value={values.afaqContent}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label={'نقاط الأسئلة الشائعة'}>
            {values.afaqPoints?.map((point, index) => (
              <div key={index}>
                <Form.Item required label={'العنوان'}>
                  <Input
                    name={`afaqPoints[${index}].title`}
                    onChange={handleChange}
                    placeholder={'العنوان'}
                    value={point.title}
                  />
                </Form.Item>
                <Form.Item required label={'المحتوى'}>
                  <Input.TextArea
                    name={`afaqPoints[${index}].content`}
                    onChange={handleChange}
                    placeholder={'المحتوى'}
                    value={point.content}
                  />
                </Form.Item>
              </div>
            ))}
            <div style={{ marginTop: 20 }} />

            <Button
              type="default"
              size="large"
              onClick={() =>
                setFieldValue('afaqPoints', [
                  ...(values?.afaqPoints || []),
                  { title: '', content: '' },
                ])
              }
              style={{ marginBottom: 10, width: 100 }}
            >
              إضافة
            </Button>
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}

export default EditHomePageContent
