import * as React from 'react'
import {
  Create,
  Form,
  Input,
  notification,
  Select,
  useForm,
  useList,
  useNavigation,
  useTable,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { Inputs } from '../Lawyer/CreateLawyer'
import { CreateAccountAction } from '../Store/Actions'
import * as Yup from 'yup'

export const CreateCompany = () => {
  const { saveButtonProps } = useForm({})
  const { edit } = useNavigation()

  const cities = useTable({
    resource: 'city',
    initialPageSize: 10000000,
  })

  const membership = useList({ resource: 'membership' })

  const filteredCities = (cities?.tableProps.dataSource || [])?.map((item) => {
    return { label: item?.city, value: item?.id }
  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    ssn: Yup.string().required('مطلوب'),
    city: Yup.string().required('مطلوب'),
    authorized_signatory: Yup.string().required('مطلوب'),
    email: Yup.string().required('يرجى ادخال بريد الكتروني صحيح'),
    password: Yup.string().required('يرجى ادخال كلمة سر صحيحة'),
  })
  const {
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      type: '',
      name: '',
      email: '',
      phone: '',
      photo: '',
      naqabeh_number: '',
      authorized_signatory: '',
      ejazeh_number: '',
      commercial_name: '',
      ssn: '',
      city: '',
      password: '',
      membership_id: '',
      fax: '',
      land_line: '',
      google_maps_link: '',
      youtube: '',
      telegram: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        type: submittedValues.type,
        name: submittedValues.name,
        email: submittedValues.email,
        city: submittedValues.city,
        password: submittedValues.password,
        membership_id: membership?.data?.data[0]?.id,
      }
      if (submittedValues.commercial_name) {
        inputs.commercial_name = submittedValues.commercial_name
      }
      if (submittedValues.ssn) inputs.ssn = submittedValues.ssn
      if (submittedValues.authorized_signatory) {
        inputs.authorized_signatory = submittedValues.authorized_signatory
      }

      if (submittedValues.fax) inputs.fax = submittedValues.fax
      if (submittedValues.land_line) {
        inputs.land_line = submittedValues.land_line
      }
      if (submittedValues.google_maps_link) {
        inputs.google_maps_link = submittedValues.google_maps_link
      }
      if (submittedValues.youtube) inputs.youtube = submittedValues.youtube
      if (submittedValues.telegram) inputs.telegram = submittedValues.telegram

      if (Object.keys(inputs)?.length) {
        CreateAccountAction('company', inputs, resetForm, edit)
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => {
      if (Object.keys(errors)?.length > 0) {
        return notification.error({
          message: 'خطأ',
          description: 'الرجاء إدخال جميع الحقول المطلوبة',
        })
      }
      handleSubmit()
    },
  }

  return (
    <Create saveButtonProps={buttonProps} title={'إنشاء شركة'}>
      <Form layout="vertical" initialValues={{ isActive: true }}>
        <Form.Item label={'الاسم'} name="name" required>
          <Input placeholder={'الاسم...'} name="name" onChange={handleChange} />
        </Form.Item>

        <Form.Item label={'الرقم الوطني للمنشأة'} name="ssn" required>
          <Input
            placeholder={'الرقم الوطني للمنشأة'}
            name="ssn"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={'اسم المفوض بالتوقيع'}
          name="authorized_signatory"
          required
        >
          <Input
            placeholder={'اسم المفوض بالتوقيع'}
            name="authorized_signatory"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={'البريد الإلكتروني'}
          name="email"
          required
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!regex.test(email)) {
                  return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                }
              },
            },
          ]}
        >
          <Input
            placeholder="example@example.com"
            name="email"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'الاسم التجاري'} name="commercial_name">
          <Input
            placeholder={'الاسم التجاري'}
            name="commercial_name"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'المدينة'} name={'city'} required>
          <Select
            // @ts-ignore
            options={filteredCities}
            placeholder={'اختر'}
            onSelect={(city) => setFieldValue('city', city)}
          />
        </Form.Item>

        <Form.Item label={'الفاكس'} name="fax">
          <Input name="fax" placeholder={'الفاكس'} onChange={handleChange} />
        </Form.Item>

        <Form.Item label={'الهاتف الارضي'} name="land_line">
          <Input
            name="land_line"
            placeholder={'الهاتف الارضي'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'خرائط جوجل'} name="google_maps_link">
          <Input
            name="google_maps_link"
            placeholder={'خرائط جوجل'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'اليوتيوب'} name="youtube">
          <Input
            name="youtube"
            placeholder={'اليوتيوب'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'التليجرام'} name="telegram">
          <Input
            name="telegram"
            placeholder={'التليجرام'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'كلمة السر'} name="password" required>
          <Input
            type="password"
            placeholder="●●●●●●●●"
            name="password"
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
