import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  useGetIdentity,
  List,
  Table,
  TextField,
  useNavigation,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'
import { SEARCH_BUTTON_ENUM_ARRAY } from '../Store/types'

const { useBreakpoint } = Grid

function ShowSearchConfigs() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { data: user } = useGetIdentity()
  const { edit } = useNavigation()

  const { queryResult } = useShow({})
  const { data, isLoading, refetch } = queryResult

  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'إعدادات النظام'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/search-configs"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'إعدادات النظام'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل النظام'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'إعدادات النظام'}</Text>
              </ListButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="النظام"
                  type="delete"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('search-configs', record?.id)
                  }
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `search-configs/${record?.id}`,
                      refetch,
                      true,
                      undefined,
                      record?.deleted
                    )
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الدولة'}</Title>
            <Text>{record?.country?.country}</Text>
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.deleted} />
          </div>
        </div>
      </Show>

      <div style={{ marginTop: 20 }} />

      <List
        title={`${'أزرار البحث'}`}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Table dataSource={record?.configs || []} rowKey="id">
          <Table.Column
            dataIndex={'labelAr'}
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'button'}
            title={'نوع البحث'}
            render={(value) => (
              <TextField value={SEARCH_BUTTON_ENUM_ARRAY[+value]} />
            )}
          />
        </Table>
      </List>
    </>
  )
}

export default ShowSearchConfigs
