import { notification } from '@pankod/refine'
import { Dispatch, SetStateAction } from 'react'
import Swal from 'sweetalert2'
import { API_KEY } from '../../../Configs'
import { apiRequest } from '../../../Util/api'
import { SwitchAccountInputs } from '../../Lawyer/LawyersListing'
import { AccountStore } from '../Stores/Account'
import { ConfigsStore } from '../Stores/Configs'
import {
  Certificate,
  IAccount,
  LanguageEnum,
  OsEnum,
  Specialty,
} from '../types'

export const CreateDeviceAction = async () => {
  const currentDevice = await ConfigsStore.get('device_uuid')
  if (currentDevice) return

  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: 'admin/create-device',
      method: 'POST',
      data: {
        os: OsEnum.computer,
        language: LanguageEnum.ar,
      },
    })
    if (!deviceData) {
      throw new Error('Something went wrong!')
    }
    await ConfigsStore.set('device_uuid', deviceData?.id)
  } catch (error: any) {
    throw new Error('Error')
  }
}

export const AcceptOrRejectAction = (
  url: string,
  data?: any,
  method?: any,
  refetch?: () => void
  // actiontype?: string // approve, reject
) => {
  Swal.fire({
    title: 'هل أنت متأكد أنك تريد الاستمرار؟',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'إرسال',
    cancelButtonText: 'إلغاء',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const { data: AcceptOrReject } = await apiRequest<any>({
          url,
          method,
          data,
        })
        if (!AcceptOrReject) {
          throw new Error('Something went wrong!')
        }
        refetch && refetch()
        notification.success({
          message: 'نجاح',
          description: 'تمت العملية بنجاح',
        })
      } catch (error: any) {
        throw new Error('Error')
      }
    }
  })
}

export const DeleteRecordAction = (
  url: string,
  refetch?: () => void,
  goBack?: boolean,
  data?: any,
  archived?: boolean
) => {
  Swal.fire({
    title: 'هل أنت متأكد أنك تريد الاستمرار؟',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'إرسال',
    cancelButtonText: 'إلغاء',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const { data: deleteData } = await apiRequest<any>({
          url,
          method: 'PUT',
          data: data ? data : { deleted: !archived },
          headers: {
            device_id: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        if (!deleteData) {
          throw new Error('Something went wrong!')
        }
        refetch && refetch()
        notification.success({
          message: 'نجاح',
          description: 'تمت العملية بنجاح',
        })
        // if (goBack) {
        //   window.history.back()
        // }
      } catch (error: any) {
        notification.error({
          message: 'خطأ',
          description: error?.message,
        })
      }
    }
  })
}

export const CreateAccountAction = async (
  type: string,
  data: any,
  resetForm?: () => void,
  edit?: (type: string, id: string) => void
) => {
  try {
    const account = await apiRequest<any>({
      url: `/${type}/create-${type}`,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    resetForm && resetForm()
    notification.success({
      message: 'نجاح',
      description: 'تم الإنشاء بنجاح',
    })
    edit && edit(type, account?.data?.id)
  } catch (error: any) {
    // if (error?.message.includes('401')) {
    //   RefreshToken();
    // } else {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const EditAccountAction = async (
  type: string,
  id: any,
  data: any,
  resetForm?: () => void,
  refetch?: () => void,
  withRefresh?: boolean,
  message?: string
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PUT',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    resetForm && resetForm()
    refetch && refetch()
    withRefresh && window.location.reload()
    message &&
      notification.success({
        message: 'نجاح',
        description: message ? message : 'تم التعديل بنجاح',
      })
  } catch (error: any) {
    // if (error?.message.includes('401')) {
    //   RefreshToken();
    // } else {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const UpdateMembership = async (
  type: string,
  id: any,
  data: any,
  resetForm?: () => void,
  refetch?: () => void,
  upgrade?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: upgrade
        ? `/${type}/upgrade-membership/${id}`
        : `/${type}/downgrade-membership/${id}`,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    resetForm && resetForm()
    refetch && refetch()
  } catch (error: any) {
    // if (error?.message.includes('401')) {
    //   RefreshToken();
    // } else {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const FindAllSpecialties = async () => {
  try {
    const { data } = await apiRequest<{ data: Specialty }>({
      url: '/specialty?query={%22limit%22:10000,%22page%22:1}',
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    await ConfigsStore.set('specialties', data?.data)
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const SwitchAccount = async (inputs: SwitchAccountInputs) => {
  try {
    const { data } = await apiRequest<string | undefined>({
      url: '/admin/switch-to-account',
      method: 'POST',
      data: inputs,
      headers: {
        api_key: API_KEY,
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    data && window.open(data, '_blank')
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const VerifySpecialtyAction = async (
  type: string,
  id: any,
  data: any,
  resetForm?: () => void,
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/verify-specialty/${id}`,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    resetForm && resetForm()
    refetch && refetch()
  } catch (error: any) {
    // if (error?.message.includes('401')) {
    //   RefreshToken();
    // } else {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const FindLawyerByNaqabehNumber = async (
  naqabehNumber: string,
  setLawyerByNaqabeNumber: (val: IAccount | undefined) => void
) => {
  try {
    const { data: lawyerByNaqabeh } = await apiRequest<IAccount>({
      url: `lawyer/get-lawyer-by-naqabeh-number/${naqabehNumber}`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    lawyerByNaqabeh && setLawyerByNaqabeNumber(lawyerByNaqabeh)
  } catch (error) {
    setLawyerByNaqabeNumber(undefined)
  }
}

export const FindLawyerCertificates = async (
  id: string,
  setLawyerCertificated: Dispatch<SetStateAction<Certificate[]>>
) => {
  const { data } = await apiRequest<Certificate[]>({
    url: `certificate/lawyerId/${id}`,
    method: 'GET',
    headers: {
      device_id: await ConfigsStore.get('device_uuid'),
      authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
    },
  })
  setLawyerCertificated(data || [])
}

export const SearchModule = async (
  data: any,
  path: string,
  setResults: Dispatch<SetStateAction<any[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true)
  const search = await apiRequest<any[]>({
    url: `${path}`,
    method: 'POST',
    data,
    headers: {
      device_id: await ConfigsStore.get('device_uuid'),
      authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
    },
  })
  if (search.data.length === 0) {
    setLoading(false)
    return notification.info({
      message: '',
      description: 'لم يتم العثور على نتائج',
    })
  }
  setResults(search.data || [])
  setLoading(false)
}

export const FindTeamMembers = async (
  id: string,
  seTeam: Dispatch<SetStateAction<any[]>>
) => {
  const { data } = await apiRequest<any>({
    url: `team-member`,
    method: 'GET',
    headers: {
      device_id: await ConfigsStore.get('device_uuid'),
      authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
    },
  })
  const filtered = data?.data?.filter(
    (member: { company: { id: string } }) => member?.company.id === id
  )

  seTeam(filtered || [])
}

export const VerifyLawyerAction = async (
  type: string,
  id: any,
  refetch?: () => void,
  data?: any
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/verify-${type}/${id}`,
      method: 'POST',
      // data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    notification.success({
      message: 'نجاح',
      description: 'تمت العملية بنجاح',
    })
    refetch && refetch()
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const RejectSpecialtyAction = async (
  id: string,
  data: { specialty_id: string },
  refetch: () => void
) => {
  try {
    await apiRequest<string | undefined>({
      url: `lawyer/reject-specialty/${id}`,
      method: 'POST',
      data,
      headers: {
        api_key: API_KEY,
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    refetch()
    notification.success({
      message: 'نجاح',
      description: 'تم رفض التخصص بنجاح',
    })
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const CreateAction = async (
  path: string,
  payload: any,
  edit?: (type: string, id: string) => void
) => {
  try {
    const { data } = await apiRequest<any>({
      url: `/${path}`,
      method: 'POST',
      data: payload,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    notification.success({
      message: 'نجاح',
      description: 'تم الإنشاء بنجاح',
    })
    edit && edit(path, data?.id)
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const EditAction = async (
  type: string,
  id: any,
  data: any,
  navigateToShow?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PUT',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    navigateToShow && navigateToShow()
    notification.success({
      message: 'نجاح',
      description: 'تم التعديل بنجاح',
    })
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}

export const SyncCountriesAction = async () => {
  try {
    await apiRequest<boolean>({
      url: '/home-page-content/seed',
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    notification.success({
      message: 'نجاح',
      description: 'تمت المزامنة بنجاح',
    })
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
  }
}
