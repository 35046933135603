import React from 'react'
import { Typography, Grid } from '@pankod/refine'
import './style.less'
import { Statistcis } from '../../../Components/Statistcis'
import { BsBuilding } from 'react-icons/bs'

interface Props {
  record: any
}
export const DailyCompanies = ({ record }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const value = {
    currentWeek: record?.companies_weekly_ratio?.current,
    previousWeek: record?.companies_weekly_ratio?.previous,
    currentMonth: record?.companies_monthly_ratio?.current,
    previousMonth: record?.companies_monthly_ratio?.previous,
  }
  return (
    <div>
      {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
      <div className="title-area__number">
        <BsBuilding
          size={25}
          color="#50606B"
          style={{ margin: '0px 8px 10px' }}
        />
        <Typography.Title
          level={screens.xs ? 5 : 4}
          style={{ color: '#50606B' }}
        >
          {'الشركات'}
          {record?.current_companies ? ` ${record?.current_companies}` : ' 0'}
        </Typography.Title>
      </div>
      <Statistcis record={record} value={value} />
    </div>
    // </div>
  )
}
