import React, { useEffect, useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  BooleanField,
  CreateButton,
  useGetIdentity,
  Select,
} from '@pankod/refine'
import {
  IAccount,
  TYPES_ENUM_ARRAY,
  TYPES_ENUM_ARRAY_QATAR,
} from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import {
  DeleteRecordAction,
  EditAccountAction,
  SwitchAccount,
  VerifyLawyerAction,
} from '../Store/Actions'
import { Search } from '../../Components/Search'
import { AccountStore, ConfigsStore } from '../Store'
import ListVerifiedLawyers from './Components/ListVerifiedLawyers'
import { lawyerTypeByIndex } from '../../Util/Helpers'
import { EditMemberShip } from '../../Components/MemberShip'

export interface SwitchAccountInputs {
  token: string
  lawyer_id?: string
  company_id?: string
}

const LawyerList = () => {
  const { data: user } = useGetIdentity()
  const { show, edit } = useNavigation()
  const [searchResults, setSearchResults] = useState<any>([])
  const [sort, setSort] = useState<string>('')
  const [currentCountry, setCurrentCountry] = React.useState<string>()
  const [
    filteredByLawyerType,
    setFilteredByLawyerType,
  ] = React.useState<string>('-1')

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  const { tableProps, tableQueryResult } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
    permanentFilter: [
      {
        field: 'type',
        operator: 'eq',
        value: filteredByLawyerType === '-1' ? -1 : `"${filteredByLawyerType}"`,
      },
    ],
  })

  const handleSwitchToAccount = (lawyerId: string) => {
    const inputs: SwitchAccountInputs = {
      token: user?.token,
      lawyer_id: lawyerId,
    }
    SwitchAccount(inputs)
  }

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults
  }

  const getSortValue = async () => {
    const sortValue = await ConfigsStore.get('sort')
    setSort(sortValue || 'created_at')
  }

  useEffect(() => {
    getSortValue()
  }, [tableProps.dataSource])

  return (
    <>
      <List
        title={`${'المحامين'}`}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Search
                path="lawyer"
                setSearchResults={setSearchResults}
                searchResults={searchResults}
                lawyer
                type={0}
              />
              <Select
                style={{ width: 140, color: '#000', margin: '0px 10px' }}
                value={
                  filteredByLawyerType === '-1'
                    ? 'جميع أنواع الحسابات'
                    : filteredByLawyerType
                }
                options={[
                  { label: 'جميع أنواع الحسابات', value: '-1' },
                ]?.concat(
                  TYPES_ENUM_ARRAY?.map((type, index) => {
                    if (currentCountry === 'QA' && index === 1) {
                      return {
                        label: TYPES_ENUM_ARRAY_QATAR[4],
                        value: '5',
                      }
                    }
                    return {
                      label: type,
                      value: `${index}`,
                    }
                  })
                )}
                onChange={(val: string) => {
                  setFilteredByLawyerType(val)
                }}
              />

              <Select
                placeholder={'الترتيب'}
                style={{ width: 120, color: '#000' }}
                value={sort}
                onChange={(val: string) => {
                  ConfigsStore.set('sort', val)
                  setTimeout(() => {
                    // window.location.reload()
                    tableQueryResult.refetch()
                  }, 400)
                }}
              >
                <Select.Option value="created_at">
                  {'تاريخ الإنشاء'}
                </Select.Option>
                <Select.Option value="type"> {'نوع الحساب'}</Select.Option>
              </Select>

              {user?.role !== 2 && (
                <CreateButton style={{ marginRight: 10 }}>
                  إنشاء محامي
                </CreateButton>
              )}
            </div>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('lawyer', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="type"
            title={'نوع الحساب'}
            align="center"
            render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
          />
          <Table.Column
            dataIndex="phone"
            title={'رقم الهاتف'}
            render={(value) => (
              <div style={{ direction: 'ltr' }}>
                <TextField value={value ? value : 'لا يوجد'} />
              </div>
            )}
          />

          <Table.Column
            dataIndex="email"
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['membership', 'name']}
            title={'نوع الاشتراك'}
            align="center"
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />
          <Table.Column
            dataIndex={'stand_alone_signup'}
            title={'تسجيل مستقل'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={'is_disabled'}
            title={'حساب موقف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={'is_phone_confirmed'}
            title={'حساب مؤكد برقم الهاتف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <div>
                    {' '}
                    <Actions
                      name_ar="المحامي"
                      type="delete"
                      edit
                      verifyAccount={record?.is_disabled}
                      switchToAccount={record?.email?.includes(
                        'he_doesnt_have_email'
                      )}
                      record={record}
                      onClickDelete={() =>
                        DeleteRecordAction(
                          `lawyer/${record?.id}`,
                          tableQueryResult?.refetch,
                          false,
                          undefined,
                          record?.deleted
                        )
                      }
                      onClickEdit={() => edit('lawyer', record?.id)}
                      onClickSwitch={() => handleSwitchToAccount(record?.id)}
                      onClickVerifyAccount={() => {
                        if (record?.stand_alone_signup) {
                          VerifyLawyerAction(
                            'lawyer',
                            record?.id,
                            tableQueryResult?.refetch
                          )
                        } else {
                          EditAccountAction(
                            'lawyer',
                            record?.id,
                            { is_disabled: false },
                            undefined,
                            tableQueryResult?.refetch
                          )
                        }
                      }}
                    />
                    <EditMemberShip record={record} categoryType="lawyer" />
                  </div>
                )
              }}
            />
          )}
        </Table>
      </List>

      <div style={{ marginTop: 20 }} />

      {currentCountry && (
        <ListVerifiedLawyers currentCountry={currentCountry} />
      )}
    </>
  )
}

export default LawyerList
