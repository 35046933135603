import { AccountStore, ConfigsStore } from '../Store'
import { AuthProvider } from '@pankod/refine'
import { LoginAction, LogoutAction } from '../Store/Actions'

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    return LoginAction({
      email: username,
      password,
    })
  },
  logout: async () => {
    return (await ConfigsStore.get('logged_in'))
      ? LogoutAction()
      : Promise.resolve()
  },
  checkError: async (error) => {
    Promise.resolve()
    if (error?.message.includes('401') || error?.message.includes('403')) {
      LogoutAction()
    }
  },
  checkAuth: async () =>
    (await ConfigsStore.get('logged_in'))
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: async () => {
    return Promise.resolve()
    // const roles = await ConfigsStore.get('adminRoles');
    // const filteredRoles = (roles || [])?.map((item: { role: number }) => {
    //   return AdminRoleEnum[+item?.role];
    // });
    // return Promise.resolve(filteredRoles);
  },
  getUserIdentity: async () => {
    const currentAccount = await AccountStore.get('account')

    return Promise.resolve({
      id: currentAccount?.id || '',
      fullName: currentAccount?.name || '',
      avatar: currentAccount?.photo?.filename,
      ...((currentAccount as object) || {}),
    })
  },
}

export default authProvider
