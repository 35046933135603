import React from 'react'
import { Typography, Grid } from '@pankod/refine'
import './style.less'
import { BsBook } from 'react-icons/bs'
import { Statistcis } from '../../../Components/Statistcis'

interface Props {
  record: any
}
export const DailyBlogs = ({ record }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const value = {
    currentWeek: record?.blogs_weekly_ratio?.current,
    previousWeek: record?.blogs_weekly_ratio?.previous,
    currentMonth: record?.blogs_monthly_ratio?.current,
    previousMonth: record?.blogs_monthly_ratio?.previous,
  }
  return (
    <div>
      <div className="title-area__number">
        <BsBook size={25} color="#50606B" style={{ margin: '0px 8px 10px' }} />
        <Typography.Title
          level={screens.xs ? 5 : 4}
          style={{ color: '#50606B' }}
        >
          {'المجلة القانونية'}
          {record?.current_blogs ? ` ${record?.current_blogs}` : ' 0'}
        </Typography.Title>
      </div>
      <Statistcis record={record} value={value} />
    </div>
  )
}
