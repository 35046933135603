import React from 'react'
import { Modal, Form, notification, Input } from '@pankod/refine'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { apiRequest } from '../../Util/api'
import { AccountStore, ConfigsStore } from '../../Containers/Store'
import * as Yup from 'yup'

interface Props {
  visible: any
  setVisible: (val: any) => void
  redirect?: string
  info?: any
  refresh?: () => void
  modalType?: string
}

interface Inputs {
  id?: string
  poster_name?: string
  title?: string
  body?: string
}

const MassNewsletterModal = ({ visible, setVisible, refresh }: Props) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('مطلوب'),
    body: Yup.string().required('مطلوب'),
  })

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      poster_name: '',
      title: '',
      body: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        poster_name: submittedValues.poster_name,
        title: submittedValues.title,
        body: submittedValues.body,
      }

      try {
        await apiRequest<boolean>({
          url: `/newsletter/send-mass-newsletter`,
          method: 'POST',
          data: inputs,
          headers: {
            device_uuid: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        setVisible('')
        refresh && refresh()
        resetForm()
        notification.success({
          message: 'نجاح',
          description: 'تم التحديث بنجاح',
        })
      } catch (error: any) {
        notification.error({
          message: 'خطأ',
          description: error?.message,
        })
      }
    },
  })

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={700}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => {
        if (Object.keys(errors)?.length > 0) {
          return notification.error({
            message: 'خطأ',
            description: 'الرجاء إدخال جميع الحقول المطلوبة',
          })
        }
        Swal.fire({
          title: 'هل أنت متأكد أنك تريد الاستمرار؟',
          showCancelButton: true,
          confirmButtonColor: '#db2828',
          confirmButtonText: 'إرسال',
          cancelButtonText: 'إلغاء',
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmit()
          }
        })
      }}
    >
      <Form layout="vertical">
        <Form.Item required label={'اسم المرسل'} name="poster_name">
          <Input
            name="poster_name"
            onChange={handleChange}
            value={values.poster_name}
            placeholder={'اسم المرسل'}
          />
        </Form.Item>

        <Form.Item
          required
          label={'العنوان'}
          name="title"
          requiredMark
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input
            name="title"
            onChange={handleChange}
            placeholder={'العنوان'}
            value={values.title}
          />
        </Form.Item>

        <Form.Item
          required
          label={'النص'}
          name="body"
          requiredMark
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input.TextArea
            name="body"
            onChange={handleChange}
            placeholder={'النص'}
            value={values.body}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MassNewsletterModal
