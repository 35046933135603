import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  useNavigation,
  BooleanField,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'

const { useBreakpoint } = Grid

function ShowLawyerSuggestion() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { edit } = useNavigation()

  const { queryResult } = useShow({})
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'اقتراحات المحامين'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/suggestion"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'اقتراحات المحامين'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل الاقتراح'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'اقتراحات المحامين'}</Text>
              </ListButton>
              <Actions
                name_ar="اقتراح"
                deleteRecord
                onClickDelete={() =>
                  DeleteRecordAction(
                    `suggestion/${record?.id}`,
                    queryResult?.refetch,
                    false,
                    undefined,
                    record?.deleted
                  )
                }
                record={record}
                edit
                onClickEdit={() => record?.id && edit('suggestion', record.id)}
                type="edit"
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الاسم'}</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>{'البريد الإلكتروني'}</Title>
            <Text>{record?.email}</Text>

            <Title level={5}>{'رقم الهاتف'}</Title>
            <Text>{record?.phoneNumber}</Text>
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الاقتراح'}</Title>
            <div
              style={{
                width: record?.suggestion?.length > 50 ? 400 : 200,
                whiteSpace: 'pre-wrap',
              }}
            >
              <Text>{record?.suggestion || '-'}</Text>
            </div>
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الدولة'}</Title>
            <Text>{record?.country?.country || '-'}</Text>

            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>

            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.deleted} />
          </div>
        </div>
      </Show>
    </>
  )
}

export default ShowLawyerSuggestion
