// eslint-disable-next-line import/no-anonymous-default-export
export default {
  white: '#FFFFFF',
  darkGray: '#363a3e',
  mediumGray: '#ccced0',
  Gray: '#c2c2c2',
  lightGray: '#808386',
  lightGray2: '#777777',
  lightGray3: '#808487',
  lightGray4: '#00000040',
  blue: '#5292CA',
  seaBlue: '#4f95d4',
  borderGray: '#c1c1c1',
  borderGray2: '#C1C1C180',
  lightBlueBg: '#F6FAFD',
  darkBlue: '#3B66B8',
  lightBlue: '#4285F4',
  orange: '#E55C12',
  shadow: '#e9edf0',
  black: '#000',
  bgBlue: '#4f95d40f',
  textGray: '#777777',
  red: '#ff0000',
  buttonColor1: '#F4AC64',
  buttonColor2: '#E55B0E',
  gradientSect1: '#395FA5',
  gradientSect2: '#385894',
  gradientSect3: '#374968',
  gradientSect4: '#363A3E',
  darkGray1: '#9B999E',
  scrollThumb: '#9a9a9a',
  scrollBackground: '#efeff2',
  lightGrayBorder: '#ebebeb',
  gradientBlue: 'linear-gradient(90deg,#3b62ae 0,#5195d0 100%)',
  gradientFromLeft: 'linear-gradient(90deg, #f2ac64 0, #e35d25 100%)',
}
