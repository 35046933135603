import React from 'react'
import { Typography, Grid } from '@pankod/refine'
import './style.less'
import { Statistcis } from '../../../Components/Statistcis'
import { FiUsers } from 'react-icons/fi'

interface Props {
  record: any
}
export const DailyLawyers = ({ record }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  const value = {
    currentWeek: record?.lawyers_weekly_ratio?.current,
    previousWeek: record?.lawyers_weekly_ratio?.previous,
    currentMonth: record?.lawyers_monthly_ratio?.current,
    previousMonth: record?.lawyers_monthly_ratio?.previous,
  }

  return (
    <div>
      <div className="title-area__number">
        <FiUsers size={25} color="#50606B" style={{ margin: '0px 8px 10px' }} />
        <Typography.Title
          level={screens.xs ? 5 : 4}
          style={{ color: '#50606B' }}
        >
          {'المحامين'}
          {record?.current_lawyers ? ` ${record?.current_lawyers}` : ' 0'}
        </Typography.Title>
      </div>
      <Statistcis record={record} value={value} />
    </div>
  )
}
