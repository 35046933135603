import {
  HttpError,
  List,
  Table,
  TextField,
  useNavigation,
  useTable,
} from '@pankod/refine'
import React, { useState } from 'react'
import { Search } from '../../Components/Search'
import { ContactUs } from '../Store/types'

const ContactUsList = () => {
  const { show } = useNavigation()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps } = useTable<ContactUs, HttpError>({
    resource: 'contact-us',
    syncWithLocation: true,
  })

  return (
    <List
      title="تواصل معنا"
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="contact-us"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('association', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'name'}
            title="الاسم"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column dataIndex="email" title="البريد الالكتروني" />
          <Table.Column
            dataIndex={'message'}
            render={(value) => <TextField value={value} />}
            title="الرسالة"
          />
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('contact-us', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'name'}
            title="الاسم"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column dataIndex="email" title="البريد الالكتروني" />
          <Table.Column
            dataIndex={'messageBody'}
            render={(value) => (
              <TextField
                value={
                  value && value?.length > 15
                    ? value.split(' ').slice(0, 6).join(' ') + String('...')
                    : value
                }
              />
            )}
            title="الرسالة"
          />
        </Table>
      )}
    </List>
  )
}

export default ContactUsList
