export enum GenderEnum {
  male = 'male',
  female = 'female',
}

export enum ADMIN_ENUM {
  superadmin = 0,
  admin = 1,
  data_entry = 2,
}

export enum OsEnum {
  ios = 'ios',
  android = 'android',
  computer = 'computer',
}

export enum LanguageEnum {
  ar = 'ar',
  en = 'en',
}

export enum TYPES_ENUM {
  lawyer = 'lawyer',
  shari = 'shari',
  judge = 'judge',
  expert = 'expert',
  company = 'company',
  advisor = 'advisor',
}

export enum LAWYER_ENUM {
  lawyer = 'lawyer',
  shari = 'shari',
  judge = 'judge',
  expert = 'expert',
}

export enum SEARCH_BUTTON_ENUM {
  search_for_lawyer = 0,
  search_for_judge = 1,
  search_for_expert = 2,
  search_for_shari = 3,
  search_for_company = 4,
  search_for_advisor = 5,
}

export enum TYPES_ENUM_INDEX {
  lawyer = 0,
  judge = 1,
  expert = 2,
  shari = 3,
  company = 4,
  advisor = 5,
}

export enum TYPES_AR_ENUM_INDEX {
  محامي = 0,
  محكم = 1,
  خبير = 2,
  شرعي = 3,
  شركة = 4,
  مستشار_قانوني = 5,
}

export const ADMIN_ENUM_ARRAY = ['مدير النظام', 'مشرف', 'مدخل بيانات']

export const TYPES_ENUM_ARRAY = ['محامي', 'محكم', 'خبير', 'شرعي', 'شركة']
export const LAWYER_ENUM_ARRAY = ['محامي', 'محكم', 'خبير', 'شرعي']

export const TYPES_ENUM_ARRAY_QATAR = [
  'محامي', // 0
  'محكم', // 1
  'خبير', // 2
  'شركة', // 3
  'مستشار قانوني', // 4
]
export const LAWYER_ENUM_ARRAY_QATAR = [
  'محامي', // 0
  'مستشار قانوني', // 1
  'محكم', // 2
  'خبير', // 3
]

export const SEARCH_BUTTON_ENUM_ARRAY = [
  'ابحث عن محامي',
  'ابحث عن محكم',
  'ابحث عن خبير',
  'ابحث عن شرعي',
  'ابحث عن شركة',
  'ابحث عن مستشار',
]

export interface IAccount {
  id: string
  email?: string
  password?: string
  name?: string
  address?: string
  fax_phone?: string
  mobile_phone?: string
  whats_app_phone?: string
  logo?: Upload
  photos?: Upload[]
  token?: string
  rating?: number
  city?: City
  role?: number
  authorized_signatory?: string
  activities?: [string]
  research?: Research[] | []
  company_type?: string
  company_nature?: string
  commercial_name?: string
  date_of_establishment?: Date
  hours_of_operation?: string
  number_of_employees?: number
  biography?: string
  team?: [TeamMember]
  type?: TYPES_ENUM
  phone?: string
  work_phone?: string
  clients?: [string]
  years_of_practice?: number
  pending_specialties?: [Specialty?]
  specialty_photos?: [SpecialtyPhotosReturn]
  verified_specialties?: [Specialty]
  specialties?: [Specialty]
  goals?: [Specialty]
  photo?: Upload
  building_name?: string
  street_name?: string
  facebook_id?: string
  google_id?: string
  apple_id?: string
  linkedin_id?: string
  twitter_handle?: string
  ejazeh_number?: string
  naqabeh_number?: string
  ssn?: string
  training_courses?: [TrainingCourses?]
  work_experience?: [WorkExperience?]
  is_email_confirmed?: boolean
  is_phone_confirmed?: boolean
  deleted?: boolean
  updated_at?: Date
  created_at?: Date
  rememberMe?: boolean
  membership?: Membership
  membership_expiry?: string
  countries?: Country[]
}

export interface Company {
  id: string
  email: string
  password: string
  name: string
  address: string
  fax_phone: string
  mobile_phone: string
  whats_app_phone: string
  membership: Membership
  membership_expiry: Date
  payment_id: string
  logo: Upload
  photos: Upload[]
  token: string
  rating: number
  city: City
  specialties: Specialty[]
  ssn: string
  authorized_signatory: string
  goals: Specialty[]
  clients: string[]
  activities: string[]
  research: Research[]
  company_type: string
  company_nature: string
  commercial_name: string
  date_of_establishment: Date
  hours_of_operation: string
  number_of_employees: number
  biography: string
  is_email_confirmed: boolean
  deleted: boolean
  updated_at: Date
  created_at: Date
  team: TeamMember[]
  ratingCountCompany: number
}

export interface Country {
  id: string
  country: string
  show: boolean
  currency: string
  isoCode?: string
  disabled: boolean
  updated_at: boolean
  created_at: boolean
}

export interface Specialty {
  id: string
  type: string
  specialty: string
  show: string
  updated_at: Date
  created_at: Date
}

export interface City {
  id: string
  country: Country
  city: string
  show: boolean
  updated_at: Date
  created_at: Date
}

export interface Association {
  id: string
  name: string
  type: TYPES_ENUM
  show: boolean
  updated_at: Date
  created_at: Date
}

export interface Membership {
  id: string
  type: number
  name: string
  cost: number
  lawyer_privileges: LawyerPrivileges
  company_privileges: CompanyPrivileges
  new_cost: number
}

export interface LawyerPrivileges {
  pending_specialties: number
  scholar_degree: boolean
  training_courses: boolean
  research: boolean
  cv: boolean
}

export interface CompanyPrivileges {
  goals: number
  activities: boolean
  clients: boolean
  research: boolean
  team: boolean
}
export interface TrainingCourses {
  title: string
  acquired_from: string
  acquired_at: Date
}

export interface Research {
  label: string
  url: string
}

export interface WorkExperience {
  name: string
  years: string
}

export interface Upload {
  id: string
  label: string
  type: string
  filename: string
  created_at: Date
}
export interface TeamMember {
  id: string
  photo: Upload
  name: string
  role: string
  updated_at: Date
  ratingCountCompany: number
  company: Company
}

export interface SpecialtyPhotosReturn {
  photos: string[]
  specialty: string
}

export interface Certificate {
  id: string
  lawyer: IAccount
  certificate_id: string
  certificate_name: string
  certificate_url: string
  photo: Upload
  certificate_issued_from: string
  certificate_issue_date: Date
  deleted: boolean
  updated_at: Date
  created_at: Date
}

export interface ContactUs {
  deleted: boolean
  name: string
  email: string
  phoneNumber: string
  messageBody: string
  created_at: string
  country: string
  id: string
}

export interface HomePageContent {
  id?: string
  country?: Country
  main: {
    title: string
    firstContent: string
    secondContent: string
  }
  learnMore: {
    title: string
    content: string
  }
  afaq: {
    title: string
    content: string
  }
  afaqPoints: {
    title: string
    content: string
  }[]
}
