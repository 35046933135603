import React from 'react'
import { Card, Statistic, Col, Row, Grid, Tooltip } from '@pankod/refine'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

interface Props {
  record: any
  value: {
    currentWeek: number
    previousWeek: number
    currentMonth: number
    previousMonth: number
  }
}
export const Statistcis = ({ record, value }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  const calcualteWeeklyPercentage =
    value?.previousWeek !== 0
      ? (value?.currentWeek / value?.previousWeek - 1) * 100
      : 100
  const calcualteMonthlyPercentage =
    value?.previousMonth !== 0
      ? (value?.currentMonth / value?.previousMonth - 1) * 100
      : 100

  const currenMonthDate =
    `${dayjs().subtract(1, 'month').format('DD/MM')}` +
    ` ${'إلى'} ` +
    `${dayjs().format('DD/MM')}`

  const prevMonthDate =
    `${dayjs().subtract(2, 'month').format('DD/MM')}` +
    ` ${'إلى'} ` +
    `${dayjs().subtract(1, 'month').format('DD/MM')}`

  const currenWeekDate =
    `${dayjs().subtract(1, 'week').format('DD/MM')}` +
    ` ${'إلى'} ` +
    `${dayjs().format('DD/MM')}`

  const prevWeekDate =
    `${dayjs().subtract(2, 'week').format('DD/MM')}` +
    ` ${'إلى'} ` +
    `${dayjs().subtract(1, 'week').format('DD/MM')}`

  const renderTooltip = (weekly?: boolean) => {
    return (
      <>
        <p style={{ fontSize: 12, marginBottom: -2 }}>
          {weekly ? currenWeekDate : currenMonthDate}
        </p>
        <p>
          {weekly
            ? `${'الأسبوع الحالي'}: ${value?.currentWeek}`
            : `${'الشهر الحالي'}: ${value?.currentMonth}`}
        </p>
        <p style={{ fontSize: 12, marginBottom: -2 }}>
          {weekly ? prevWeekDate : prevMonthDate}
        </p>
        <p>
          {weekly
            ? `${'الأسبوع السابق'}: ${value?.previousWeek}`
            : `${'الشهر الماضى'}: ${value?.previousMonth}`}
        </p>
      </>
    )
  }

  return (
    <Row gutter={[16, 8]} justify="center">
      <Col>
        <Card
          style={{ background: '#FBFBFB', border: 'none', borderRadius: 10 }}
        >
          <Tooltip
            placement="top"
            title={renderTooltip(true)}
            style={{ maxWidth: 20 }}
          >
            <Statistic
              title={'هذا الاسبوع'}
              value={
                calcualteWeeklyPercentage
                  ? Math.abs(calcualteWeeklyPercentage)
                  : 0
              }
              precision={2}
              style={{ background: '#FBFBFB' }}
              valueStyle={{
                color: calcualteWeeklyPercentage
                  ? calcualteWeeklyPercentage >= 0
                    ? '#67DC88'
                    : '#EA6744'
                  : '#50606B',
                fontSize: screens.xs ? 16 : 22,
              }}
              prefix={
                calcualteWeeklyPercentage ? (
                  calcualteWeeklyPercentage >= 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )
                ) : (
                  <></>
                )
              }
              suffix="%"
            />
          </Tooltip>
        </Card>
      </Col>
      <Col>
        <Card
          style={{ background: '#FBFBFB', border: 'none', borderRadius: 10 }}
        >
          <Tooltip
            placement="top"
            title={renderTooltip}
            style={{ maxWidth: 200 }}
          >
            <Statistic
              title={'هذا الشهر'}
              value={
                calcualteMonthlyPercentage
                  ? Math.abs(calcualteMonthlyPercentage)
                  : 0
              }
              precision={2}
              style={{ background: '#FBFBFB' }}
              valueStyle={{
                color: calcualteMonthlyPercentage
                  ? calcualteMonthlyPercentage >= 0
                    ? '#67DC88'
                    : '#EA6744'
                  : '#50606B',
                fontSize: screens.xs ? 16 : 22,
              }}
              prefix={
                calcualteMonthlyPercentage ? (
                  calcualteMonthlyPercentage >= 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )
                ) : (
                  <></>
                )
              }
              suffix="%"
            />
          </Tooltip>
        </Card>
      </Col>
    </Row>
  )
}
