import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  useGetIdentity,
  useNavigation,
  UrlField,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'
import { UPLOAD_URI } from '../../Configs'

const { useBreakpoint } = Grid

function ShowAnnouncement() {
  const { data: user } = useGetIdentity()
  const { edit } = useNavigation()

  const screens = useBreakpoint()
  const { Title, Text } = Typography

  const { queryResult } = useShow({})

  const { data, isLoading, refetch } = queryResult
  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'الإعلانات'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/announcement"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'الإعلانات'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل الإعلان'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'الإعلانات'}</Text>
              </ListButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="الإعلان"
                  type="delete"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('announcement', record?.id)
                  }
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `announcement/${record?.id}`,
                      refetch,
                      true,
                      undefined,
                      record?.deleted
                    )
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'العنوان'}</Title>
            <Text>{record?.title || '-'}</Text>

            <Title level={5}>{'صورة الخلفية'}</Title>
            {record?.background_image?.filename ? (
              <a
                href={`${UPLOAD_URI}/${record?.background_image?.filename}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${UPLOAD_URI}/${record?.background_image?.filename}`}
                  alt={record?.background_image}
                  style={{
                    width: 100,
                    height: 50,
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                />
              </a>
            ) : (
              <Text>{'-'}</Text>
            )}

            <Title level={5}>{'النص'}</Title>
            <div
              style={{
                width: record?.body?.length > 50 ? 400 : 200,
              }}
            >
              <Text>{record?.body || '-'}</Text>
            </div>
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الرابط'}</Title>
            {record?.redirect_url ? (
              <UrlField target="_blank" value={record?.redirect_url} />
            ) : (
              <Text>{'-'}</Text>
            )}

            <Title level={5}>{'الترتيب'}</Title>
            <Text>{record?.order || '0'}</Text>

            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'فعال'}</Title>
            <BooleanField value={record?.active} />

            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.deleted} />
          </div>
        </div>
      </Show>
    </>
  )
}

export default ShowAnnouncement
