import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  BooleanField,
  useGetIdentity,
} from '@pankod/refine'
import { IAccount } from '../Store/types'
import {
  AcceptOrRejectAction,
  DeleteRecordAction,
} from '../Store/Actions/ConfigsActions'
import { Actions } from '../../Components/ActionButtons'
import dayjs from 'dayjs'
import { Search } from '../../Components/Search'

const BlogsList = () => {
  const { data: user } = useGetIdentity()
  const { show } = useNavigation()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps, tableQueryResult } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
  })

  return (
    <List
      title={`${'المجلة القانونية'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="blog"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('blog', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="poster_name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="title"
            title={'عنوان المجلة'}
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />

          <Table.Column
            dataIndex={'approved'}
            title={'معتمد'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'deleted'}
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'created_at'}
            title={'أنشأ في'}
            align="center"
            render={(value) => (
              <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
            )}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="المجلة"
                    type="approveReject"
                    record={record}
                    deleteRecord
                    onClickApprove={() =>
                      AcceptOrRejectAction(
                        `blog/${record?.id}`,
                        { approved: true },
                        'PUT',
                        tableQueryResult?.refetch
                      )
                    }
                    onClickReject={() =>
                      AcceptOrRejectAction(
                        `blog/${record?.id}`,
                        { approved: false },
                        'PUT',
                        tableQueryResult?.refetch
                      )
                    }
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `blog/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                  />
                )
              }}
            />
          )}
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('blog', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="poster_name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="title"
            title={'عنوان المجلة'}
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />

          <Table.Column
            dataIndex={'approved'}
            title={'معتمد'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'deleted'}
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'created_at'}
            title={'أنشأ في'}
            align="center"
            render={(value) => (
              <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
            )}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="المجلة"
                    type="approveReject"
                    record={record}
                    deleteRecord
                    showApproveButton={record.approved === true}
                    showRejectButton={record.approved === false}
                    onClickApprove={() =>
                      AcceptOrRejectAction(
                        `blog/${record?.id}`,
                        { approved: true },
                        'PUT',
                        tableQueryResult?.refetch
                      )
                    }
                    onClickReject={() =>
                      AcceptOrRejectAction(
                        `blog/${record?.id}`,
                        { approved: false },
                        'PUT',
                        tableQueryResult?.refetch
                      )
                    }
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `blog/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                  />
                )
              }}
            />
          )}
        </Table>
      )}
    </List>
  )
}

export default BlogsList
