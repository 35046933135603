import * as React from 'react'
import {
  Button,
  Create,
  Form,
  Input,
  Select,
  notification,
  useNavigation,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { SEARCH_BUTTON_ENUM, SEARCH_BUTTON_ENUM_ARRAY } from '../Store/types'
import { CreateAction } from '../Store/Actions'

interface Inputs {
  buttonParams: string
  labelAr: string
  labelEn: string
  button: SEARCH_BUTTON_ENUM
}

export const CreateSearchConfigs = () => {
  // const countries = useList({ resource: 'country' })
  const { show } = useNavigation()

  // const filteredCountries = (countries?.data?.data || [])?.map((item) => {
  //   return { label: item?.country, value: item?.id }
  // })

  const selectButtons = SEARCH_BUTTON_ENUM_ARRAY.map(
    (item: any, index: number) => {
      return { label: item, value: index }
    }
  )

  const [inputs, setInputs] = React.useState<Inputs[]>([
    {
      buttonParams: '',
      button: ('' as unknown) as SEARCH_BUTTON_ENUM,
      labelEn: '',
      labelAr: '',
    },
  ])

  const { handleSubmit } = useFormik({
    initialValues: {
      // country: '',
    },
    onSubmit: async () => {
      const emptyFields = inputs.filter((input) => {
        return (
          !input.labelAr ||
          input.button === undefined ||
          input.button === null ||
          input.button === (('' as unknown) as SEARCH_BUTTON_ENUM)
        )
      })

      if (emptyFields.length > 0) {
        notification.error({
          message: 'يجب ملء جميع الحقول',
        })
        return
      }

      const filteredInputs = inputs.filter(
        (input: { labelAr: any; button: any }) => input.labelAr || input.button
      )
      const payload = {
        configs: filteredInputs.map((input: Inputs) => ({
          buttonParams: input.buttonParams,
          button: input.button,
          labelEn: input.labelAr,
          labelAr: input.labelAr,
        })),
        // country: values.country,
      }

      CreateAction('search-configs', payload, show)
    },
  })

  const addInput = () => {
    if (inputs.length < 5) {
      setInputs([
        ...inputs,
        {
          buttonParams: '',
          button: SEARCH_BUTTON_ENUM.search_for_lawyer,
          labelEn: '',
          labelAr: '',
        },
      ])
    } else {
      alert('Cannot add more than 5 inputs')
    }
  }

  const removeInput = (index: number) => {
    setInputs([...inputs.slice(0, index), ...inputs.slice(index + 1)])
  }

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  }

  return (
    <Create saveButtonProps={buttonProps} title={'إنشاء إعدادات بحث'}>
      <Form
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        {inputs.map(
          (
            input: {
              labelAr: string | number | readonly string[] | undefined
              button: any
            },
            index: number
          ) => (
            <div key={index}>
              <Form.Item
                required
                label={'الاسم'}
                name={`configs[${index}].labelAr`}
                rules={[
                  {
                    validator: async (_, type) => {
                      if (!type) {
                        return Promise.reject(new Error('مطلوب'))
                      }
                    },
                  },
                ]}
              >
                <Input
                  name={`configs[${index}].labelAr`}
                  value={input.labelAr}
                  onChange={(event) =>
                    // @ts-ignore
                    setInputs([
                      ...inputs.slice(0, index),
                      { ...input, labelAr: event.target.value },
                      ...inputs.slice(index + 1),
                    ])
                  }
                />
              </Form.Item>

              <Form.Item
                label={'نوع البحث'}
                name={`configs[${index}].button`}
                required
                rules={[
                  {
                    validator: async (_, type) => {
                      if (type !== 0 && !type) {
                        return Promise.reject(new Error('مطلوب'))
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Select
                  options={selectButtons}
                  value={input.button}
                  onChange={(value) =>
                    // @ts-ignore
                    setInputs([
                      ...inputs.slice(0, index),
                      { ...input, button: value },
                      ...inputs.slice(index + 1),
                    ])
                  }
                />
              </Form.Item>

              {index > 0 && (
                <Button
                  type="default"
                  size="large"
                  onClick={() => removeInput(index)}
                  style={{ marginBottom: 10, width: 100 }}
                >
                  حذف
                </Button>
              )}
            </div>
          )
        )}

        {inputs.length < 5 && (
          <Button
            type="default"
            size="large"
            onClick={addInput}
            style={{ marginBottom: 10, width: 100 }}
          >
            إضافة
          </Button>
        )}

        {/* <Form.Item
          label={'الدولة'}
          name={'country'}
          required
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Select
            // @ts-ignore
            options={filteredCountries}
            value={values.country}
            onChange={(value) => setFieldValue('country', value)}
          />
        </Form.Item> */}
      </Form>
    </Create>
  )
}
