import React, { useCallback, useEffect, useState } from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  Row,
  Col,
  Avatar,
  ListButton,
  Typography,
  Select,
  notification,
  Upload,
  Space,
  Grid,
  useTable,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { API_KEY, POST_UPLOAD_URI, UPLOAD_URI } from '../../Configs'
import { useFormik } from 'formik'
import { apiRequest } from '../../Util/api'
import { AccountStore, ConfigsStore } from '../Store'
import { RefetchAccountAction } from '../Store/Actions'
const { Text } = Typography
const { useBreakpoint } = Grid

function EditAdmin() {
  const screens = useBreakpoint()
  const [getAccount, setAccount] = useState<any>()
  const [photoName, setPhotoName] = useState<string>()

  const handleGetToken = async () => {
    const account = await AccountStore.get('account')
    setAccount(account)
  }

  useEffect(() => {
    handleGetToken()
  }, [])

  const { formProps, saveButtonProps, queryResult } = useForm<any>({})

  const [selectedCountries, setSelectedCountries] = useState<string[]>([])

  const [allCountriesIds, setAllCountriesIds] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const countries = useTable({
    resource: 'country',
  })

  const updateCountriesIds = () => {
    const countriesIds = [] as string[]
    countries?.tableProps.dataSource?.map((item) => {
      countriesIds?.push(item.id ? item.id : '')
      return null
    })
    setAllCountriesIds(countriesIds)
    setIsLoading(false)
  }

  const getOptions = () => {
    return (
      countries?.tableProps?.dataSource
        // ?.filter((item) => !item.disabled)
        ?.map((item) => {
          return {
            label: item.country,
            value: item.id,
          } as any
        })
    )
  }

  const assignCountry = (country: any) => {
    setSelectedCountries([...selectedCountries, country])
  }

  const unAssignCountry = (id: any) => {
    setSelectedCountries(selectedCountries.filter((item) => item !== id))
  }

  const record = queryResult?.data?.data

  const getDefaultValues = useCallback(() => {
    if (record && allCountriesIds.length > 0) {
      const defaultValues: string[] = []
      record.countries.map((item: any) => {
        allCountriesIds.includes(item.id) && defaultValues.push(item.id)
        return null
      })

      return defaultValues
    }
    return []
  }, [allCountriesIds, record])

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      id: record?.id,
      name: '',
      email: '',
      photo: '',
      role: +'',
      country: getDefaultValues(),
    },
    onSubmit: async (submittedValues) => {
      const inputs: {
        id?: string
        name?: string
        email?: string
        photo?: string
        role?: number | string
        countries?: string[]
      } = {}

      if (submittedValues.name) inputs.name = submittedValues.name
      if (submittedValues.email) inputs.email = submittedValues.email
      if (submittedValues.photo) inputs.photo = submittedValues.photo
      if (record.role !== submittedValues.role) {
        inputs.role = submittedValues.role
      }
      if (submittedValues.country?.length > 0) {
        inputs.countries = submittedValues.country
      }

      try {
        if (
          submittedValues.country?.length === 0 ||
          values.country?.length === 0 ||
          selectedCountries?.length === 0
        ) {
          throw new Error('يجب اختيار دولة واحدة على الأقل')
        }

        await apiRequest<boolean>({
          url: `/admin/${record?.id}`,
          method: 'PUT',
          data: inputs,
          headers: {
            device_uuid: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        resetForm()
        submittedValues.photo && window.location.reload()
        notification.success({
          message: 'نجاح',
          description: 'تم التحديث بنجاح',
        })

        setFieldValue('country', getDefaultValues())
        if (getAccount?.id === record?.id) {
          RefetchAccountAction(record?.id, true)
        }
      } catch (error: any) {
        notification.error({
          message: 'خطأ',
          description: error?.message,
        })
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  useEffect(() => {
    if (
      (countries.tableProps.dataSource || []).length > 0 &&
      queryResult?.data?.data
    ) {
      updateCountriesIds()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.tableProps.dataSource, queryResult?.data?.data])

  useEffect(() => {
    setSelectedCountries(getDefaultValues())
    setFieldValue('country', getDefaultValues())
    setFieldValue('role', record?.role)
  }, [getDefaultValues, record?.role, setFieldValue])

  useEffect(() => {
    if (values.photo) {
      notification.success({
        message: 'نحاح',
        description: 'تم التحميل بنجاح',
      })
    }
  }, [values.photo])

  if (isLoading) {
    return (
      <Edit
        isLoading={isLoading}
        title={'تعديل المشرف'}
        pageHeaderProps={{
          extra: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListButton>
                <Text>{'المشرفين'}</Text>
              </ListButton>
            </div>
          ),
        }}
      ></Edit>
    )
  }

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل المشرف'}
        pageHeaderProps={{
          extra: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListButton>
                <Text>{'المشرفين'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Row gutter={[64, 0]} wrap align={'middle'}>
          <Col xs={24} lg={7}>
            <Form.Item>
              <Form.Item name="photo" noStyle>
                <Upload.Dragger
                  name="file"
                  action={POST_UPLOAD_URI}
                  listType="picture"
                  showUploadList={false}
                  onChange={(file) => {
                    setFieldValue('photo', file?.file?.response?.id)
                    setPhotoName(file?.file?.response?.filename)
                  }}
                  maxCount={1}
                  headers={{
                    api_key: API_KEY,
                    authorization: `Bearer ${getAccount?.token}`,
                  }}
                  style={{
                    border: 'none',
                    width: '100%',
                    background: 'none',
                  }}
                >
                  <Space direction="vertical" size={2}>
                    <Avatar
                      style={{
                        width: '200px',
                        height: '200px',
                        marginBottom: screens?.xs ? 20 : 0,
                      }}
                      src={
                        photoName
                          ? `${UPLOAD_URI}/${photoName}`
                          : record?.photo?.filename
                          ? `${UPLOAD_URI}/${record?.photo?.filename}`
                          : noPhoto
                      }
                      alt="Profile Picture"
                    />
                  </Space>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={24} lg={17}>
            <Form {...formProps} layout="vertical">
              <Row gutter={[64, 0]} wrap align={'middle'}>
                <Col xs={24} lg={15}>
                  <Form.Item required label={'الاسم'} name="name">
                    <Input name="name" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item label={'البريد الالكتروني'} name="email" required>
                    <Input name="email" onChange={handleChange} />
                  </Form.Item>
                  <Form.Item label={'دور الحساب'} name={'role'} required>
                    <Select
                      placeholder={'اختر دوراً...'}
                      options={[
                        { label: 'مدير النظام', value: 0 },
                        { label: 'مشرف', value: 1 },
                        { label: 'مدخل بيانات', value: 2 },
                      ]}
                      onChange={(val) => setFieldValue('role', val)}
                    />
                  </Form.Item>
                  <Form.Item label={'قائمة الدول'} name={'country'}>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={'قائمة الدول'}
                      options={getOptions()}
                      defaultValue={getDefaultValues()}
                      onSelect={(id) => assignCountry(id)}
                      onDeselect={(id) => unAssignCountry(id)}
                      onChange={(val) => setFieldValue('country', val)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Edit>
    </>
  )
}

export default EditAdmin
