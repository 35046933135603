import React, { useEffect } from 'react'
import Routes from './Routes'
import * as Sentry from '@sentry/react'
import { AccountStore, ConfigsStore } from './Containers/Store'

const setSentryUserInfo = async () => {
  const account = await AccountStore.getStore()
  const configs = await ConfigsStore.getStore()
  if (account.account) {
    Sentry.setUser({
      id: account?.account?.id,
      email: account?.account?.email,
      name: account?.account?.name,
      device: configs?.device_uuid,
    })
  } else {
    Sentry.setUser({
      device: configs?.device_uuid,
    })
  }
}

function App() {
  useEffect(() => {
    setSentryUserInfo()
  }, [])
  return <Routes />
}

export default Sentry.withProfiler(App)
