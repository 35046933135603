import React from 'react'
import {
  List,
  Table,
  useTable,
  useNavigation,
  TextField,
  useGetIdentity,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'

const MembershipList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const { tableProps, tableQueryResult } = useTable<any>({
    syncWithLocation: true,
    // onSearch: (params) => {
    //   const filters: CrudFilters = [];
    //   const { q, labelType, createdAt } = params;

    //   filters.push({
    //     field: 'filter_like',
    //     operator: 'eq',
    //     value: q,
    //   });

    //   filters.push({
    //     field: 'type',
    //     operator: 'eq',
    //     value: labelType,
    //   });

    //   filters.push(
    //     {
    //       field: 'createdAt_gte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[0].startOf('day'.toISOString()
    //         : undefined,
    //     },
    //     {
    //       field: 'createdAt_lte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[1].endOf('day'.toISOString()
    //         : undefined,
    //     }
    //   );

    //   return filters;
    // },
  })

  return (
    <>
      {/* <Row gutter={[16, 16]}> */}
      {/* <Col xl={18} xs={24}> */}
      <List
        // @ts-ignore
        title={`${'العضوية'} (${tableQueryResult?.data?.total || 0})`}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('membership', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'العضوية'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'cost'}
            title={'التكلفة'}
            align="center"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={['lawyer_privileges', 'pending_specialties']}
            title={'عدد التخصصات المسموحة للمحامي'}
            align="center"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={['company_privileges', 'goals']}
            title={'عدد التخصصات المسموحة للشركة'}
            align="center"
            render={(value) => <TextField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="العضوية"
                    type="edit"
                    // deleteRecord
                    edit
                    record={record}
                    // onClickDelete={() =>
                    //   DeleteRecordAction(
                    //     `membership/${record?.id}`,
                    //     tableQueryResult?.refetch
                    //   )
                    // }
                    onClickEdit={() => edit('membership', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
      {/* </Col> */}
      {/* </Row> */}
    </>
  )
}

export default MembershipList
