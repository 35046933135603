import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend' // adding lazy loading for translations, more information here: https://react.i18next.com/legacy-v9/step-by-step-guide#2-lazy-loading-translations
import detector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(detector)
  .init({
    supportedLngs: ['ar', 'en'],
    backend: {
      loadPath: '/locales/ar/common.json',
    },
    defaultNS: 'common',
    fallbackLng: ['ar', 'en'],
  })

export default i18n
