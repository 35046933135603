import React, { useEffect, useState } from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  Row,
  Col,
  Avatar,
  ListButton,
  Typography,
  Upload,
  Space,
  Grid,
  Select,
  useList,
  notification,
  Switch,
  useGetIdentity,
  useTable,
  ShowButton,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { API_KEY, POST_UPLOAD_URI, UPLOAD_URI } from '../../Configs'
import { useFormik } from 'formik'
import { AccountStore, ConfigsStore } from '../Store'
import { EditAccountAction, UpdateMembership } from '../Store/Actions'
import styled from 'styled-components'
import { RiCloseCircleFill } from 'react-icons/ri'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Inputs {
  id?: string
  name?: string
  email?: string
  phone?: string
  photo?: string
  linkedin_id?: string
  twitter_handle?: string
  whats_app_phone?: string
  membership_id?: string
  street_name?: string
  building_name?: string
  pending_specialties?: string[]
  verified_by?: string[]
  is_disabled?: string | boolean
  disable_review?: string | boolean
  fax?: string
  land_line?: string
  google_maps_link?: string
  youtube?: string
  telegram?: string
  website?: string
}

function EditLawyer() {
  const screens = useBreakpoint()
  const [getAccount, setAccount] = useState<any>()
  const [getGoals, setGoals] = useState<any>()
  const [photoName, setPhotoName] = useState<string>()

  const associations = useTable({
    resource: 'association',
    initialPageSize: 1000000,
  })

  const filteredAssociations = (associations?.tableProps.dataSource || [])?.map(
    (item) => {
      return { label: item?.name, value: item?.id }
    }
  )

  const { data: user } = useGetIdentity()

  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data

  const handleGetToken = async () => {
    const account = await AccountStore.get('account')
    const specialties = await ConfigsStore.get('specialties')
    setAccount(account)
    setGoals(specialties)
  }

  useEffect(() => {
    handleGetToken()
  }, [])

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      id: record?.id,
      name: '',
      email: '',
      phone: '',
      photo: '',
      membership_id: '',
      pending_specialties: ('' as unknown) as string[],
      verified_by: ('' as unknown) as string[],
      building_name: '',
      street_name: '',
      linkedin_id: '',
      twitter_handle: '',
      whats_app_phone: '',
      is_disabled: '',
      disable_review: '',
      fax: '',
      land_line: '',
      google_maps_link: '',
      youtube: '',
      telegram: '',
      website: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}

      const urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g
      const twitterRegex = /^[a-zA-Z0-9_]{1,15}$/
      const linkedInRegex = /[A-z0-9_-]+/

      if (
        submittedValues.twitter_handle &&
        urlRegex.test(submittedValues.twitter_handle)
      ) {
        return notification.error({
          message: 'خطأ',
          description: 'يرجى ادخال اسم المستخدم',
        })
      }
      if (
        submittedValues.linkedin_id &&
        urlRegex.test(submittedValues.linkedin_id)
      ) {
        return notification.error({
          message: 'خطأ',
          description: 'يرجى ادخال اسم المستخدم',
        })
      }
      if (
        submittedValues.twitter_handle &&
        !twitterRegex.test(submittedValues.twitter_handle)
      ) {
        return notification.error({
          message: 'خطأ',
          description: 'يرجى ادخال اسم مستخدم صحيح',
        })
      }
      if (
        submittedValues.linkedin_id &&
        !linkedInRegex.test(submittedValues.linkedin_id)
      ) {
        return notification.error({
          message: 'خطأ',
          description: 'يرجى ادخال اسم مستخدم صحيح',
        })
      }

      if (submittedValues.name) inputs.name = submittedValues.name
      if (submittedValues.email) inputs.email = submittedValues.email
      if (submittedValues.building_name !== record?.building_name) {
        inputs.building_name = submittedValues.building_name
      }
      if (submittedValues.street_name !== record?.street_name) {
        inputs.street_name = submittedValues.street_name
      }

      if (submittedValues.phone) {
        inputs.phone = submittedValues.phone
      }

      if (submittedValues.verified_by) {
        inputs.verified_by = submittedValues.verified_by
      }

      if (submittedValues.linkedin_id) {
        inputs.linkedin_id = submittedValues.linkedin_id
      }
      if (submittedValues.twitter_handle) {
        inputs.twitter_handle = submittedValues.twitter_handle
      }
      if (submittedValues.whats_app_phone) {
        inputs.whats_app_phone = submittedValues.whats_app_phone
      }

      if (submittedValues.fax) inputs.fax = submittedValues.fax
      if (submittedValues.land_line) {
        inputs.land_line = submittedValues.land_line
      }
      if (submittedValues.google_maps_link) {
        inputs.google_maps_link = submittedValues.google_maps_link
      }
      if (submittedValues.youtube) inputs.youtube = submittedValues.youtube
      if (submittedValues.website) inputs.website = submittedValues.website

      if (submittedValues.telegram) inputs.telegram = submittedValues.telegram

      if (
        // @ts-ignore
        submittedValues.disable_review === false ||
        submittedValues.disable_review
      ) {
        inputs.disable_review = submittedValues.disable_review
      }
      if (
        // @ts-ignore
        submittedValues.is_disabled === false ||
        submittedValues.is_disabled
      ) {
        inputs.is_disabled = submittedValues.is_disabled
      }
      if (submittedValues.photo) inputs.photo = submittedValues.photo
      if (submittedValues.pending_specialties) {
        if (record?.membership?.type === 0) {
          if (submittedValues.pending_specialties?.length <= 1) {
            inputs.pending_specialties = submittedValues.pending_specialties
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة تخصص واحد فقط',
            })
          }
        }

        if (record?.membership?.type === 1) {
          if (submittedValues.pending_specialties?.length <= 3) {
            inputs.pending_specialties = submittedValues.pending_specialties
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة ثلاث تخصصات فقط',
            })
          }
        }

        if (record?.membership?.type === 2) {
          if (submittedValues.pending_specialties?.length <= 5) {
            inputs.pending_specialties = submittedValues.pending_specialties
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة خمس تخصصات فقط',
            })
          }
        }
      }

      if (submittedValues.membership_id) {
        const findMembership = membership?.data?.data?.find(
          (item) => item?.id === values.membership_id
        )
        if (findMembership?.type > record?.membership?.type) {
          UpdateMembership(
            'lawyer',
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            true
          )
        }

        if (findMembership?.type < record?.membership?.type) {
          UpdateMembership(
            'lawyer',
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            false
          )
        }
        if (Object.keys(inputs)?.length === 0) {
          notification.success({
            message: 'نجاح',
            description: 'تم التحديث بنجاح',
          })
        }
      }
      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'lawyer',
          record?.id,
          inputs,
          resetForm,
          queryResult?.refetch,
          !!submittedValues?.photo
        )
        notification.success({
          message: 'نجاح',
          description: 'تم التحديث بنجاح',
        })
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  const membership = useList({ resource: 'membership' })

  const filteredMembership = (membership?.data?.data || [])?.map((item) => {
    return { label: item?.name, value: item?.id }
  })

  const filteredGoals = (getGoals || [])
    ?.filter(
      (item: { type: number; show: boolean }) =>
        item?.type === record?.type && item?.show === true
    )
    ?.map((item: { specialty: string; id: string }) => {
      return { label: item?.specialty, value: item?.id }
    })
  const filteredSpecialties = (filteredGoals || []).filter(
    (item: { value: string }) => {
      return !record?.verified_specialties?.find(
        (specialty: { id: string }) => specialty?.id === item?.value
      )
    }
  )

  const selectGoalsProps: any = {
    mode: 'tags',
    style: { width: '100%' },
    value: values.pending_specialties || [],
    options: filteredSpecialties || [],
    onChange: (goal: string[]) => setFieldValue('pending_specialties', goal),
    placeholder: 'تخصصات...',
    maxTagCount: 'responsive',
  }

  const selectAssociationProps: any = {
    mode: 'tags',
    style: { width: '100%' },
    value: values.verified_by || [],
    options: filteredAssociations || [],
    onChange: (goal: string[]) => setFieldValue('verified_by', goal),
    placeholder: 'الجهات...',
    maxTagCount: 'responsive',
  }

  useEffect(() => {
    if (record && record?.pending_specialties?.length > 0) {
      setFieldValue(
        'pending_specialties',
        record?.pending_specialties?.map((goal: { id: string }) => goal?.id)
      )
    }
    if (record && record?.verified_by?.length > 0) {
      setFieldValue(
        'verified_by',
        record?.verified_by?.map((goal: { id: string }) => goal?.id)
      )
    }
    setFieldValue('street_name', record?.street_name)
    setFieldValue('building_name', record?.building_name)
    handleGetToken()
  }, [record, setFieldValue])

  useEffect(() => {
    if (values.photo) {
      notification.success({
        message: 'نحاح',
        description: 'تم التحميل بنجاح',
      })
    }
  }, [values.photo])

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل المحامي'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'المحامين'}</Text>
              </ListButton>
              <ShowButton>
                <Text>{'عرض المحامي'}</Text>
              </ShowButton>
            </Space>
          ),
        }}
      >
        <Row gutter={[64, 0]} wrap align={'middle'}>
          <Col xs={24} lg={7}>
            <Form.Item>
              <Form.Item name="photo" noStyle>
                <Upload.Dragger
                  name="file"
                  action={POST_UPLOAD_URI}
                  listType="picture"
                  maxCount={1}
                  showUploadList={false}
                  onChange={(file) => {
                    setFieldValue('photo', file?.file?.response?.id)
                    setPhotoName(file?.file?.response?.filename)
                  }}
                  headers={{
                    api_key: API_KEY,
                    authorization: `Bearer ${getAccount?.token}`,
                  }}
                  style={{
                    border: 'none',
                    width: '100%',
                    background: 'none',
                  }}
                >
                  <Space direction="vertical" size={2}>
                    <Avatar
                      style={{
                        width: '200px',
                        height: '200px',
                        marginBottom: screens?.xs ? 20 : 0,
                      }}
                      src={
                        photoName
                          ? `${UPLOAD_URI}/${photoName}`
                          : record?.photo?.filename
                          ? `${UPLOAD_URI}/${record?.photo?.filename}`
                          : noPhoto
                      }
                      alt="Profile Picture"
                    />
                  </Space>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={24} lg={17}>
            <Form {...formProps} layout="vertical">
              <Row gutter={[64, 0]} wrap align={'middle'}>
                <Col xs={24} lg={15} style={{ alignSelf: 'flex-start' }}>
                  <Form.Item required label={'الاسم'} name="name">
                    <Input name="name" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item label={'البريد الالكتروني'} name="email" required>
                    <Input
                      name="email"
                      onChange={handleChange}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'رقم الهاتف'} name="phone" required>
                    <Input
                      name="phone"
                      onChange={handleChange}
                      style={{ direction: 'ltr', textAlign: 'right' }}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'العنوان'} name="street_name">
                    <Input name="street_name" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item
                    label={'اسم الشارع و رقم المبنى'}
                    name="building_name"
                  >
                    <Input name="building_name" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item label={'العضوية'} name={['membership', 'name']}>
                    <Select
                      // @ts-ignore
                      options={filteredMembership}
                      onSelect={(id) => setFieldValue('membership_id', id)}
                    />
                  </Form.Item>
                  <Form.Item label={'التخصص'}>
                    <Select
                      {...selectGoalsProps}
                      disabled={record?.membership_expiry && user?.role !== 0}
                      showSearch={false}
                    />
                  </Form.Item>

                  <Form.Item label={'الجهات'}>
                    <Select
                      {...selectAssociationProps}
                      disabled={user?.role !== 0}
                      showSearch={false}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={9} style={{ alignSelf: 'flex-start' }}>
                  <Form.Item label={'الفاكس'} name="fax">
                    <Input
                      name="fax"
                      placeholder={'الفاكس'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'الهاتف الارضي'} name="land_line">
                    <Input
                      name="land_line"
                      placeholder={'الهاتف الارضي'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'خرائط جوجل'} name="google_maps_link">
                    <Input
                      name="google_maps_link"
                      placeholder={'خرائط جوجل'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'موقع الكتروني'} name="website">
                    <Input
                      name="website"
                      placeholder={'موقع الكتروني'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'اليوتيوب'} name="youtube">
                    <Input
                      name="youtube"
                      placeholder={'اليوتيوب'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'التليجرام'} name="telegram">
                    <Input
                      name="telegram"
                      placeholder={'التليجرام'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'لينكد إن'} name="linkedin_id">
                    <Input
                      name="linkedin_id"
                      onChange={handleChange}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'تويتر'} name="twitter_handle">
                    <Input
                      name="twitter_handle"
                      onChange={handleChange}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'واتساب'} name="whats_app_phone">
                    <Input
                      name="whats_app_phone"
                      onChange={handleChange}
                      style={{ direction: 'ltr', textAlign: 'right' }}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'توقيف الحساب'} name="is_disabled">
                    <Switch
                      defaultChecked={record?.is_disabled}
                      onChange={(val) => setFieldValue('is_disabled', val)}
                    />
                  </Form.Item>

                  <Form.Item label={'تعطيل التقييم'} name="disable_review">
                    <Switch
                      defaultChecked={record?.disable_review}
                      onChange={(val) => setFieldValue('disable_review', val)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Edit>
    </>
  )
}

export default EditLawyer

export const PhotosWapper = styled.div`
  position: relative;
`

export const Link = styled.a`
  text-decoration: none;
  margin-right: 5px;
`

export const ProfilePic = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`

export const RemoveButton = styled(RiCloseCircleFill)`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 1px;
  &:hover {
    opacity: 0.7;
  }
`

export const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 25px;
  margin-bottom: 20px;
`
