import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'
import { Search } from '../../Components/Search'

const AnnouncementList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps, tableQueryResult } = useTable<any, HttpError>({
    syncWithLocation: true,
  })

  if (searchResults.length > 0) {
    tableProps.dataSource = searchResults
  }

  return (
    <>
      <List
        title={`${'الإعلانات'}`}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Search
                path="announcement"
                setSearchResults={setSearchResults}
                searchResults={searchResults}
              />
              {user?.role !== 2 && (
                <CreateButton style={{ marginRight: 10 }}>
                  {'إنشاء إعلان'}{' '}
                </CreateButton>
              )}
            </div>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('announcement', record?.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'title'}
            title={'العنوان'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'body'}
            title={'النص'}
            width={300}
            render={(value) => (
              <TextField
                value={
                  (value || [])?.length > 50
                    ? `${value?.substring(0, 50)}...`
                    : value || '-'
                }
              />
            )}
          />

          <Table.Column
            dataIndex={'redirect_url'}
            title={'الرابط'}
            align="center"
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex={'order'}
            title={'الترتيب'}
            align="center"
            render={(value) => <TextField value={value || '-'} />}
          />

          <Table.Column
            dataIndex="active"
            title={'فعال'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex="deleted"
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="إعلان"
                    deleteRecord
                    type="edit"
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `announcement/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => edit('announcement', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
    </>
  )
}

export default AnnouncementList
