import * as React from 'react'
import { Create, Form, Input, Select, useForm, useTable } from '@pankod/refine'
import { ADMIN_ENUM } from '../Store/types'

export const CreateAdmin = () => {
  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: 'admin/create',
  })

  const countries = useTable({
    resource: 'country',
  })

  const getOptions = () => {
    return (
      countries?.tableProps?.dataSource
        // ?.filter((item) => !item.disabled)
        ?.map((item) => {
          return {
            label: item.country,
            value: item.id,
          } as any
        })
    )
  }

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء مشرف نظام'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          label={'الاسم'}
          name="name"
          required
          rules={[
            {
              validator: async (_, name) => {
                if (!name) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input placeholder={'الاسم...'} />
        </Form.Item>

        <Form.Item
          label={'البريد الإلكتروني'}
          name="email"
          required
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!regex.test(email)) {
                  return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                }
              },
            },
          ]}
        >
          <Input placeholder="example@example.com" name="email" />
        </Form.Item>

        <Form.Item label={'الدور'} name={'role'}>
          <Select
            placeholder={'اختر دوراً...'}
            options={[
              { label: 'مدير النظام', value: ADMIN_ENUM.superadmin },
              { label: 'مشرف', value: ADMIN_ENUM.admin },
              { label: 'مدخل بيانات', value: ADMIN_ENUM.data_entry },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={'كلمة السر'}
          name="password"
          required
          rules={[
            {
              validator: async (_, name) => {
                if (!name) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input type="password" placeholder="●●●●●●●●" />
        </Form.Item>
        <Form.Item label={'قائمة الدول'} name={'countries'}>
          <Select
            mode="multiple"
            allowClear
            placeholder={'قائمة الدول'}
            options={getOptions()}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
