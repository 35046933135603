import React from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
  Button,
} from '@pankod/refine'
import { Country } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction, SyncCountriesAction } from '../Store/Actions'
import { AiOutlineCloudSync } from 'react-icons/ai'

const SystemConfigs = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const { tableProps, tableQueryResult } = useTable<Country, HttpError>({
    syncWithLocation: true,
    // onSearch: (params) => {
    //   const filters: CrudFilters = [];
    //   const { q, labelType, createdAt } = params;

    //   filters.push({
    //     field: 'filter_like',
    //     operator: 'eq',
    //     value: q,
    //   });

    //   filters.push({
    //     field: 'type',
    //     operator: 'eq',
    //     value: labelType,
    //   });

    //   filters.push(
    //     {
    //       field: 'createdAt_gte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[0].startOf('day'.toISOString()
    //         : undefined,
    //     },
    //     {
    //       field: 'createdAt_lte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[1].endOf('day'.toISOString()
    //         : undefined,
    //     }
    //   );

    //   return filters;
    // },
  })

  return (
    <>
      {/* <Row gutter={[16, 16]}> */}
      {/* <Col xl={18} xs={24}> */}
      <List
        // @ts-ignore
        title={`${'الدول'} (${tableProps?.pagination?.total || 0})`}
        pageHeaderProps={{
          extra: user?.role === 0 && (
            <>
              <Button
                onClick={() => SyncCountriesAction()}
                icon={
                  <AiOutlineCloudSync
                    style={{ marginLeft: 7, marginBottom: -3, marginRight: -5 }}
                    size={18}
                  />
                }
              >
                مزامنة
              </Button>
              <CreateButton>إنشاء دولة</CreateButton>
            </>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('country', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="country"
            title={'الدولة'}
            render={(value) => <TextField value={value} />}
            key={'id'}
          />

          <Table.Column
            dataIndex="isoCode"
            title={'كود الدولة'}
            align="center"
            render={(value) => <TextField value={value} />}
            key={'id'}
          />

          <Table.Column
            dataIndex={'currency'}
            title={'العملة'}
            render={(value) => <TextField value={value || 'غير متوفر'} />}
          />

          <Table.Column
            dataIndex="show"
            title={'إظهار'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex="disabled"
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role === 0 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الدولة"
                    type="delete"
                    deleteRecord
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `country/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        { disabled: !record?.disabled },
                        record?.disabled
                      )
                    }
                    onClickEdit={() => edit('country', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
      {/* </Col> */}
      {/* </Row> */}
    </>
  )
}

export default SystemConfigs
