import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  useNavigation,
  BooleanField,
  useGetIdentity,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'
import { lawyerTypeByIndex } from '../../Util/Helpers'

const { useBreakpoint } = Grid

function ShowCourtDegree() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const { queryResult } = useShow({})
  const { data, isLoading, refetch } = queryResult

  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'درجات المحاكم'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/courtDegree"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'درجات المحاكم'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل درجة المحكمة'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'درجات المحاكم'}</Text>
              </ListButton>
              {user?.roles !== 2 && (
                <Actions
                  name_ar="درجة المحكمة"
                  type="show-inside"
                  record={record}
                  edit
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `courtDegree/${record?.id}`,
                      refetch,
                      true,
                      { show: !record?.show },
                      record?.show
                    )
                  }
                  onClickEdit={() =>
                    record?.id && edit('courtDegree', record.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <Title level={5}>{'اسم الدرجة'}</Title>
        <Text>{record?.name}</Text>

        <Title level={5}>{'النوعية'}</Title>
        <Text>{lawyerTypeByIndex(+record?.type)}</Text>

        <Title level={5}>{'أنشأ في'}</Title>
        <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>

        <Title level={5}>{'محذوف'}</Title>
        <BooleanField value={record?.show} />
      </Show>
    </>
  )
}

export default ShowCourtDegree
