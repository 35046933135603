import axios, { Method } from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { API_KEY, API_URL } from '../Configs'
import { AccountStore, ConfigsStore } from '../Containers/Store'
import * as Sentry from '@sentry/react'

export const getDeviceUUID = async () => ConfigsStore.get('device_uuid')

const api = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    api_key: API_KEY,
  },
})

api.interceptors.response.use(
  (response) => {
    Sentry.addBreadcrumb({
      category: 'API Response',
      message: 'Response Successfull',
      level: 'info',
      data: {
        ...response,
      },
    })
    return response
  },
  (error) => {
    Sentry.addBreadcrumb({
      category: 'API Response',
      message: 'Response Failed',
      level: 'error',
      data: {
        ...error,
      },
    })

    Sentry.captureException(error, {
      level: 'error',
      fingerprint: ['{{ default }}', '{{ transaction }}'],
      contexts: {
        error: {
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
      },
    })

    return Promise.reject(error)
  }
)
api.interceptors.request.use(
  (config) => {
    Sentry.addBreadcrumb({
      category: 'API Request',
      message: 'Request',
      level: 'info',
      data: {
        ...config,
      },
    })
    return config
  },
  (error) => {
    Sentry.addBreadcrumb({
      category: 'API Request',
      message: 'Request Failed',
      level: 'error',
      data: {
        ...error,
      },
    })

    Sentry.captureException(error, {
      level: 'error',
      fingerprint: ['{{ default }}', '{{ transaction }}'],
      contexts: {
        error: {
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
      },
    })
    return Promise.reject(error)
  }
)
const useAxios = makeUseAxios({ axios: api })

type Api_Request = {
  url: string
  method?: Method
  data?: {}
  params?: {}
  headers?: {}
  manualTrigger?: boolean
}

/**
 * useApi Hook
 * @example
 * ```
 * const {} = useApi({});
 * ```
 **/

export const useApi = <DATA>(req: Api_Request) => {
  const [{ data, loading, error, response: debug }, run] = useAxios<DATA>(
    {
      url: req.url,
      method: req.method || 'GET',
      data: req.data || {},
      params: req.params || {},
      headers: req.headers || {},
    },
    {
      manual: req.manualTrigger || false,
    }
  )

  return { data, loading, error, run, debug }
}

/**
 * Simple asynchronous api request method
 * @example without token
 * ```
 * const data = await apiRequest({});
 * ```
 **/
export const apiRequest = async <DATA>(req: Api_Request) => {
  const account = await AccountStore.get('account')
  const country =
    (await ConfigsStore.get('country')) ||
    (account?.countries && account?.countries[0]?.id)

  if (checkIfResourceIncluded(req.url) && country) {
    return api.request<DATA>({
      url: req.url,
      method: req.method || 'GET',
      data: req.data
        ? {
            ...req.data,
            country,
          }
        : {},
      params: req.params || {},
      headers: req.headers
        ? {
            ...req.headers,
            'x-country-id': country,
          }
        : {},
    })
  }

  return api.request<DATA>({
    url: req.url,
    method: req.method || 'GET',
    data: req.data ? req.data : {},
    params: req.params || {},
    headers: req.headers
      ? {
          ...req.headers,
          'x-country-id': country,
        }
      : {},
  })
}

const checkIfResourceIncluded = (resource: string) => {
  switch (resource) {
    case '/lawyer':
      return true
    case '/company':
      return true
    case '/blog':
      return true
    case '/comment':
      return true
    case '/specialty':
      return true
    case '/newsletter':
      return true
    case '/announcement':
      return true
    case '/membership':
      return true
    case '/association':
      return true
    case '/search-configs':
      return true
    case '/contact-us':
      return true
    case '/city':
      return true
    case '/system-configs':
      return true
    case '/courtDegree':
      return true
    case 'home-page-content':
      return true
    case '/suggestion':
      return true
    // case 'dashboard':
    //   return true
    default:
      return false
  }
}
