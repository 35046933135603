import React, { useEffect } from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  notification,
  Switch,
  Select,
  useTable,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
const { Text } = Typography

interface Inputs {
  id?: string
  country?: string
  city?: string
  currency?: string
  disabled?: boolean | string
  show?: boolean | string
}

function EditCity() {
  const { formProps, saveButtonProps, queryResult } = useForm<any>({
    resource: 'city',
  })
  const countries = useTable({ resource: 'country', initialPageSize: 1000000 })

  const filteredCountries = (countries?.tableProps?.dataSource || [])?.map(
    (item) => {
      return { label: item?.country, value: item?.id }
    }
  )

  const record = queryResult?.data?.data

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      id: record?.id,
      city: '',
      country: record?.country?.id,
      show: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}
      if (submittedValues.city) {
        inputs.city = submittedValues.city
      }
      if (submittedValues.country) {
        inputs.country = submittedValues.country
      }
      // @ts-ignore
      if (submittedValues.show === false || submittedValues.show) {
        inputs.show = submittedValues.show
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'city',
          record?.id,
          inputs,
          resetForm,
          queryResult?.refetch
        )
      }

      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  useEffect(() => {
    if (record?.country?.id) {
      setFieldValue('country', record?.country?.id)
    }
  }, [record, setFieldValue])

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل المدينة'}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ListButton>
                <Text>{'المدن'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            required
            label={'المدينة'}
            name={'city'}
            rules={[
              {
                validator: async (_, type) => {
                  if (!type) {
                    return Promise.reject(new Error('مطلوب'))
                  }
                },
              },
            ]}
          >
            <Input name="city" onChange={handleChange} />
          </Form.Item>
          <Form.Item label={'الدولة'} required>
            <Select
              placeholder={'اختر دولة...'}
              // @ts-ignore
              options={filteredCountries || []}
              onChange={(val) => setFieldValue('country', val)}
              value={
                filteredCountries?.find(
                  (item: any) => item?.value === values.country
                )?.value
              }
            />
          </Form.Item>
          <Form.Item label={'إظهار'} name="show">
            <Switch
              defaultChecked={record?.show}
              onChange={(val) => setFieldValue('show', val)}
            />
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}

export default EditCity
