import React from 'react'
import { Typography, Grid } from '@pankod/refine'
import './style.less'
import { Statistcis } from '../../../Components/Statistcis'
import { FaUsers } from 'react-icons/fa'

interface Props {
  record: any
}
export const DailyAllUsers = ({ record }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  const value = {
    currentWeek: record?.all_users_weekly_ratio?.current,
    previousWeek: record?.all_users_weekly_ratio?.previous,
    currentMonth: record?.all_user_monthly_ratio?.current,
    previousMonth: record?.all_user_monthly_ratio?.previous,
  }

  return (
    <div>
      <div className="title-area__number">
        <FaUsers size={25} color="#50606B" style={{ margin: '0px 8px 10px' }} />
        <Typography.Title
          level={screens.xs ? 5 : 4}
          style={{ color: '#50606B' }}
        >
          {'إجمالي المستخدمين'}
          {record?.all_users ? ` ${record?.all_users}` : ' 0'}
        </Typography.Title>
      </div>
      <Statistcis record={record} value={value} />
    </div>
  )
}
