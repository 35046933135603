import React, { useState } from 'react'
import {
  Tooltip,
  Select,
  Icons,
  useList,
  useForm,
  notification,
} from '@pankod/refine'
import { UpdateMembership } from '../../Containers/Store/Actions'
import { useFormik } from 'formik'

interface Inputs {
  id?: string
  membership_id?: string
  record?: any
  categoryType?: any
}

export const EditMemberShip: React.FC<Inputs> = ({ record, categoryType }) => {
  const membership = useList({ resource: 'membership' })

  const [isClicked, setIsClicked] = useState(false)

  const filteredMembership = (membership?.data?.data || [])?.map((item) => {
    return { label: item?.name, value: item?.id }
  })
  const { queryResult } = useForm<any>({})

  const { values, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      membership_id: '',
    },
    onSubmit: async (submittedValues) => {
      console.log('submittedValues', submittedValues)
      console.log('Insideonsumbitline45')
      const inputs: Inputs = {}

      if (submittedValues.membership_id) {
        const findMembership = membership?.data?.data?.find(
          (item) => item?.id === values.membership_id
        )

        if (findMembership?.type > record?.membership?.type) {
          UpdateMembership(
            categoryType,
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            true
          )
        }

        if (findMembership?.type < record?.membership?.type) {
          console.log('insidesecondif')
          UpdateMembership(
            categoryType,
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            false
          )
        }
        if (Object.keys(inputs)?.length === 0) {
          notification.success({
            message: 'نجاح',
            description: 'تم التحديث بنجاح',
          })
        }
      }
    },
  })

  const handleIconClicked = (e: any) => {
    e.stopPropagation()
    setIsClicked(!isClicked)
  }

  const handleSelectValue = (id: any) => {
    console.log('id from selectValue', id)
    setFieldValue('membership_id', id)
    handleSubmit()
    setIsClicked(!isClicked)
  }

  return (
    <Tooltip
      placement="top"
      title={<p style={{ marginBottom: -1 }}>{'العضوية'}</p>}
      style={{ maxWidth: 20 }}
    >
      <Icons.IdcardOutlined
        style={{ fontSize: 27 }}
        onClick={(e) => handleIconClicked(e)}
      />
      {isClicked && (
        <Select
          onClick={(e) => e.stopPropagation()}
          open={isClicked}
          style={{ width: '0px', border: 'none' }}
          dropdownStyle={{ minWidth: '70px' }}
          // @ts-ignore
          options={filteredMembership}
          onSelect={(id) => handleSelectValue(id)}
        />
      )}
    </Tooltip>
  )
}
