import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  useNavigation,
  BooleanField,
  useGetIdentity,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'

const { useBreakpoint } = Grid

function ShowMembership() {
  const screens = useBreakpoint()
  const { data: user } = useGetIdentity()
  const { Title, Text } = Typography
  const { edit } = useNavigation()

  const { queryResult } = useShow({})
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'العضوية'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/membership"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'العضوية'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل العضوية'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'العضوية'}</Text>
              </ListButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="العضوية"
                  type="edit"
                  record={record}
                  // deleteRecord
                  edit
                  // onClickDelete={() =>
                  //   DeleteRecordAction(
                  //     `membership/${record?.id}`,
                  //     refetch,
                  //     true
                  //   )
                  // }
                  onClickEdit={() =>
                    record?.id && edit('membership', record.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            {record?.name && (
              <>
                <Title level={5}>{'العضوية'}</Title>
                <Text>{record?.name}</Text>
              </>
            )}

            <Title level={5}>{'التكلفة'}</Title>
            <Text>{record?.cost || 0}</Text>

            <Title level={5}>{'عدد التخصصات المسموحة للمحامي'}</Title>
            <Text>{record?.lawyer_privileges?.pending_specialties || 0}</Text>

            <Title level={5}>{'عدد التخصصات المسموحة للشركة'}</Title>
            <Text>{record?.company_privileges?.goals || 0}</Text>

            {/* {record?.updated_at && (
              <>
                <Title level={5}>{'تم التعديل في'}</Title>
                <Text>
                  {dayjs(record?.updated_at).format('YYYY-MM-DD, HH:mm')}
                </Text>
              </>
            )} */}

            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'النشاطات (للشركة)'}</Title>
            <BooleanField value={record?.company_privileges?.activities} />

            <Title level={5}>{'العملاء (للشركة)'}</Title>
            <BooleanField value={record?.company_privileges?.clients} />
            <Title level={5}>{'الأبحاث (للشركة)'}</Title>
            <BooleanField value={record?.company_privileges?.research} />
            <Title level={5}>{'الفريق (للشركة)'}</Title>
            <BooleanField value={record?.company_privileges?.team} />
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الشهادات العلمية (للمحامي)'}</Title>
            <BooleanField value={record?.lawyer_privileges?.scholar_degree} />
            <Title level={5}>{'الأبحاث (للمحامي)'}</Title>
            <BooleanField value={record?.lawyer_privileges?.research} />
            <Title level={5}>{'السيره الذاتيه (للمحامي)'}</Title>
            <BooleanField value={record?.lawyer_privileges?.cv} />
            <Title level={5}>
              {'الخبرات العملية / الدورات التدريبية (للمحامي)'}
            </Title>
            <BooleanField value={record?.lawyer_privileges?.training_courses} />
          </div>
        </div>
      </Show>
    </>
  )
}

export default ShowMembership
