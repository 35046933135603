import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
} from '@pankod/refine'
import logo from '../../../Assets/Images/darkLogo.png'

import '../ForgotPassword//styles.css'
import { VerificationAction } from '../../Store/Actions'
import Colors from '../../../Style/Colors'
import { ConfigsStore } from '../../Store'

const { Title } = Typography

export const Verification: React.FC = () => {
  const [email, setEmail] = useState<string | undefined>()

  const getEmail = async () => {
    const email = await ConfigsStore.get('resetPasswordEmail')
    setEmail(email)
  }
  useEffect(() => {
    getEmail()
  }, [])

  const navigateToResetPassword = () => {
    window.location.replace('/reset-password')
  }

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'رمز التحقق'}
    </Title>
  )
  return (
    <AntdLayout
      style={{
        background:
          'radial-gradient(circle, rgba(246,250,253,1) 0%, rgba(212,233,249,1) 100%)',
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="130"
              alt="logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={'#6CCEF4'}
                requiredMark={false}
                onFinish={(val: any) => {
                  VerificationAction(val?.code, email, navigateToResetPassword)
                }}
              >
                <Form.Item
                  name="code"
                  label={'رمز التحقق'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="●●●●●" />
                </Form.Item>

                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={'#6CCEF4'}
                  style={{ background: Colors.gradientFromLeft }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: '#6CCEF4',
                    }}
                  >
                    {'أرسل'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
