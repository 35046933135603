import React, { useState } from 'react'
import {
  Show,
  useShow,
  Typography,
  Icons,
  Space,
  Avatar,
  Card,
  Row,
  Col,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { MdOutlineBuild } from 'react-icons/md'
import { UPLOAD_URI } from '../../Configs'
import { ADMIN_ENUM_ARRAY } from '../Store/types'
import { GrStatusInfo } from 'react-icons/gr'
import dayjs from 'dayjs'
import RolesModal from '../../Components/RolesModal'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'

const { useBreakpoint } = Grid

function ShowAdmin() {
  const { data: user } = useGetIdentity()
  const { edit } = useNavigation()

  const [rolesModalVisible, setrolesModalVisible] = useState<any>()
  const screens = useBreakpoint()
  const { Title, Text } = Typography

  const { queryResult } = useShow({})

  const { data, isLoading, refetch } = queryResult
  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'المشرف'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="/admin" style={{ fontWeight: 700, color: Colors.seaBlue }}>
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'المشرف'}</h4>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space
              direction="vertical"
              style={{ width: '100%', height: '100%' }}
            >
              <Space
                direction="vertical"
                style={{ textAlign: 'center', width: '100%' }}
              >
                <Avatar
                  size={120}
                  src={
                    record?.photo?.filename
                      ? `${UPLOAD_URI}/${record?.photo?.filename}`
                      : noPhoto
                  }
                />
                <Typography.Title level={3}>{record?.name}</Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {record?.name && (
                  <Typography.Text>
                    <Icons.UserOutlined /> {record?.name}
                  </Typography.Text>
                )}
                {record?.username && (
                  <Typography.Text>
                    <Icons.UserOutlined /> {record?.username}
                  </Typography.Text>
                )}

                <Typography.Text>
                  <MdOutlineBuild
                    size={14}
                    style={{ marginBottom: -3, marginRight: 4, marginLeft: 4 }}
                  />
                  {ADMIN_ENUM_ARRAY[+record?.role] || 'لا يوجد'}
                </Typography.Text>

                {record?.phone && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icons.PhoneOutlined />
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.phone}</Text>
                    </div>
                  </div>
                )}
                {record?.email && (
                  <Typography.Text>
                    <Icons.MailOutlined /> {record?.email}
                  </Typography.Text>
                )}
                <Typography.Text>
                  <GrStatusInfo
                    size={14}
                    style={{ marginBottom: -3, marginRight: 4, marginLeft: 4 }}
                  />
                  {record?.deleted ? 'محذوف' : 'فعال'}
                </Typography.Text>
                {/* {record?.nationality && (
                  <Typography.Text>
                    <GrMapLocation size={18} />
                    {
                      (
                        countries.find(
                          (country) => country.alpha2 === record?.nationality
                        ) || {}
                      )?.name
                    }
                  </Typography.Text>
                )} */}
                {record?.gender && (
                  <Typography.Text>
                    <BsGenderAmbiguous size={18} /> {record?.gender}
                  </Typography.Text>
                )}
              </Space>
            </Space>
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <Show
            isLoading={isLoading}
            title={'تفاصيل المشرف'}
            pageHeaderProps={{
              extra: (
                <Space>
                  <RefreshButton>
                    <Text>{'تحديث'}</Text>
                  </RefreshButton>
                  <ListButton>
                    <Text>{'المشرفين'}</Text>
                  </ListButton>

                  {user?.role === 0 && (
                    <Actions
                      name_ar="المسؤول"
                      type="delete"
                      edit
                      role
                      record={record}
                      onClickDelete={() =>
                        DeleteRecordAction(
                          `admin/${record?.id}`,
                          refetch,
                          true,
                          undefined,
                          record?.deleted
                        )
                      }
                      onClickEdit={() =>
                        record?.id && edit('admin', record?.id)
                      }
                      onClickRole={() => setrolesModalVisible(record)}
                    />
                  )}
                </Space>
              ),
            }}
          >
            <div
              className="detailsWrapper"
              style={{ justifyContent: screens.md ? '' : 'space-between' }}
            >
              <div style={{ margin: screens.md ? '' : '20px' }}>
                <Title level={5}>{'أنشأ في'}</Title>
                <Text>
                  {dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}
                </Text>
              </div>

              <div style={{ margin: screens.md ? '' : '20px' }}>
                <Title level={5}>{'محذوف'}</Title>
                <BooleanField value={record?.deleted} />
              </div>
            </div>
          </Show>
        </Col>
      </Row>
      <RolesModal
        visible={rolesModalVisible}
        setVisible={setrolesModalVisible}
        refresh={refetch}
      />
    </>
  )
}

export default ShowAdmin
