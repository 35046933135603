import React, { useEffect } from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  notification,
  Switch,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
const { Text } = Typography

interface Inputs {
  id?: string
  name?: string
  cost?: number
  company_privileges: {
    goals?: number
    research: boolean | string
    activities: boolean | string
    clients: boolean | string
    team: boolean | string
    biography?: boolean | string
  }
  lawyer_privileges: {
    pending_specialties?: number
    research: boolean | string
    scholar_degree: boolean | string
    training_courses: boolean | string
    cv?: boolean | string
  }
}

function EditMembership() {
  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data

  const { setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      name: record?.name,
      cost: record?.cost,
      goals: record?.company_privileges?.goals,
      pending_specialties: record?.lawyer_privileges?.pending_specialties,
      scholar_degree: record?.lawyer_privileges?.scholar_degree,
      researchLawyer: record?.lawyer_privileges?.research,
      training_courses: record?.lawyer_privileges?.training_courses,
      cv: record?.lawyer_privileges.cv,
      activities: record?.company_privileges?.activities,
      clients: record?.company_privileges?.clients,
      researchCompany: record?.company_privileges?.research,
      team: record?.company_privileges?.team,
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        lawyer_privileges: {
          pending_specialties: +submittedValues.pending_specialties,
          scholar_degree: submittedValues.scholar_degree,
          research: submittedValues.researchLawyer,
          training_courses: submittedValues.training_courses,
          cv: submittedValues.cv,
        },
        company_privileges: {
          goals: +submittedValues.goals,
          activities: submittedValues.activities,
          clients: submittedValues.clients,
          research: submittedValues.researchCompany,
          team: submittedValues.team,
        },
      }
      if (submittedValues.name) {
        inputs.name = submittedValues.name
      }

      if (submittedValues.cost) {
        inputs.cost = +submittedValues.cost
      }

      EditAccountAction(
        'membership',
        record?.id,
        inputs,
        resetForm,
        queryResult?.refetch
      )

      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  useEffect(() => {
    if (record) {
      setFieldValue('goals', record?.company_privileges?.goals)
      setFieldValue(
        'pending_specialties',
        record?.lawyer_privileges?.pending_specialties
      )
    }
  }, [record, setFieldValue])

  useEffect(() => {
    if (record) {
      setFieldValue('id', record.id)
      setFieldValue('name', record.name)
      setFieldValue('cost', record.cost)
      setFieldValue('goals', record?.company_privileges?.goals)
      setFieldValue(
        'researchLawyer',
        record?.lawyer_privileges?.research || false
      )
      setFieldValue(
        'training_courses',
        record?.lawyer_privileges?.training_courses || false
      )
      setFieldValue('cv', record?.lawyer_privileges?.cv || false)
      setFieldValue(
        'activities',
        record?.company_privileges?.activities || false
      )
      setFieldValue('clients', record?.company_privileges?.clients || false)
      setFieldValue(
        'scholar_degree',
        record?.lawyer_privileges?.scholar_degree || false
      )

      setFieldValue(
        'researchCompany',
        record?.company_privileges?.research || false
      )
      setFieldValue('team', record?.company_privileges?.team || false)
    }
  }, [record, setFieldValue])

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل العضوية'}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ListButton>
                <Text>{'العضوية'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item required label={'العضوية'} name="name">
            <Input name="name" onChange={handleChange} />
          </Form.Item>

          <Form.Item required label={'التكلفة'} name="cost">
            <Input name="cost" onChange={handleChange} />
          </Form.Item>

          <Form.Item
            label={'عدد التخصصات المسموحة (للشركة)'}
            name={['company_privileges', 'goals']}
          >
            <Input name="goals" onChange={handleChange} />
          </Form.Item>

          <Form.Item
            label={'عدد التخصصات المسموحة (للمحامي)'}
            name={['lawyer_privileges', 'pending_specialties']}
          >
            <Input name="pending_specialties" onChange={handleChange} />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                maxHeight: 300,
                display: 'flex',
                flexWrap: 'wrap',
                width: '45%',
              }}
            >
              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'الشهادات العلمية (للمحامي)'}
                name={['lawyer_privileges', 'scholar_degree']}
              >
                <Switch
                  defaultChecked={record?.lawyer_privileges?.scholar_degree}
                  onChange={(val) => setFieldValue('scholar_degree', val)}
                />
              </Form.Item>

              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'الابحاث (للمحامي)'}
                name={['lawyer_privileges', 'research']}
              >
                <Switch
                  defaultChecked={record?.lawyer_privileges?.research}
                  onChange={(val) => setFieldValue('researchLawyer', val)}
                />
              </Form.Item>
              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'الخبرات العملية / الدورات التدريبية (للمحامي)'}
                name={['lawyer_privileges', 'training_courses']}
              >
                <Switch
                  defaultChecked={record?.lawyer_privileges?.training_courses}
                  onChange={(val) => setFieldValue('training_courses', val)}
                />
              </Form.Item>
              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'السيره الذاتيه (للمحامي) '}
                name={['lawyer_privileges', 'cv']}
              >
                <Switch
                  defaultChecked={record?.lawyer_privileges?.training_courses}
                  onChange={(val) => {
                    setFieldValue('cv', val)
                  }}
                />
              </Form.Item>
            </div>

            <div
              style={{
                maxHeight: 300,
                display: 'flex',
                flexWrap: 'wrap',
                width: '45%',
              }}
            >
              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'النشاطات (للشركة)'}
                name={['company_privileges', 'activities']}
              >
                <Switch
                  defaultChecked={record?.company_privileges?.activities}
                  onChange={(val) => setFieldValue('activities', val)}
                />
              </Form.Item>

              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'العملاء (للشركة)'}
                name={['company_privileges', 'clients']}
              >
                <Switch
                  defaultChecked={record?.company_privileges?.clients}
                  onChange={(val) => setFieldValue('clients', val)}
                />
              </Form.Item>

              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'الأبحاث (للشركة)'}
                name={['company_privileges', 'research']}
              >
                <Switch
                  defaultChecked={record?.company_privileges?.research}
                  onChange={(val) => setFieldValue('researchCompany', val)}
                />
              </Form.Item>

              <Form.Item
                style={{ margin: '5px 10px' }}
                label={'الفريق (للشركة)'}
                name={['company_privileges', 'team']}
              >
                <Switch
                  defaultChecked={record?.company_privileges?.team}
                  onChange={(val) => setFieldValue('team', val)}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Edit>
    </>
  )
}

export default EditMembership
