import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { Spin } from '@pankod/refine'
import { createBrowserHistory } from 'history'
import { LoadingOutlined } from '@ant-design/icons'
import { SpinWrapper } from './Routes'
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />

const history = createBrowserHistory()

const filterSpecialties = (localStorageData: string | null) => {
  const { specialties, ...configs } =
    localStorageData && JSON.parse(localStorageData)
  return { ...configs }
}
Sentry.init({
  dsn: 'https://54da896ac359450d89fad1bcfb3a8c98@sentry.wtmsrv.com/17',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // send local storage contents to sentry  (for debugging) with each event
  beforeSend(event) {
    event.contexts = {
      ...event.contexts,
      'State (localStorage)': {
        'Configs/store': filterSpecialties(
          window.localStorage.getItem('Configs/store')
        ),
        'Account/store': window.localStorage.getItem('Account/store'),
      },
    }
    return event
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: window.location.host.includes('admin.eadllaw.com')
    ? 'production'
    : window.location.host.includes('admin.eadllaw.staging.wtmsrv.com')
    ? 'staging'
    : 'development',
})

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <SpinWrapper>
          <Spin indicator={antIcon} />
        </SpinWrapper>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
