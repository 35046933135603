import React, { useEffect } from 'react'
import {
  Typography,
  Table,
  Space,
  useTable,
  useNavigation,
  BooleanField,
} from '@pankod/refine'
import './style.less'

const { Text } = Typography
interface Props {
  refresh?: boolean
}
export const RecentBlogs = ({ refresh }: Props) => {
  const { show } = useNavigation()

  const { tableProps, tableQueryResult } = useTable({
    resource: 'blog',
    initialSorter: [
      {
        field: 'created_at',
        order: 'desc',
      },
    ],
    initialPageSize: 4,
    permanentFilter: [
      {
        field: 'status.text',
        operator: 'eq',
        value: 'Pending',
      },
    ],
    syncWithLocation: false,
  })

  const handleRefresh = async () => {
    await tableQueryResult.refetch()
  }

  useEffect(() => {
    if (refresh) {
      handleRefresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <Table
      {...tableProps}
      showHeader={false}
      style={{ cursor: 'pointer' }}
      onRow={(record) => {
        return {
          onClick: () => {
            record.id && show('blog', record.id)
          },
        }
      }}
    >
      <Table.Column<any>
        className="recent-orders-col"
        key="summary"
        render={(_, record) => (
          <>
            <Text>{'الاسم'}</Text>
            <div className="recent-orders-col__title">
              <Text className="recent-orders-col__title--up" strong>
                {record?.poster_name}
              </Text>
            </div>
          </>
        )}
      />
      <Table.Column<any>
        key="summary"
        className="recent-orders-col"
        render={(_, record) => (
          <Space direction="vertical">
            <Text>{'العنوان'}</Text>
            <Text className="recent-orders-col__title--up" strong>
              {record?.title}
            </Text>
          </Space>
        )}
      />
      <Table.Column<any>
        dataIndex="approved"
        render={(value, record) => (
          <Space direction="vertical">
            <Text>{'معتمد'}</Text>
            <BooleanField value={value} />
          </Space>
        )}
      />
    </Table>
  )
}
