import * as React from 'react'
import {
  Avatar,
  Edit,
  Form,
  Input,
  Space,
  Switch,
  Upload,
  getValueFromEvent,
  notification,
  useForm,
  useNavigation,
} from '@pankod/refine'
import { API_KEY, POST_UPLOAD_URI, UPLOAD_URI } from '../../Configs'
import { AccountStore } from '../Store'
import { IAccount } from '../Store/types'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { useFormik } from 'formik'
import { EditAction } from '../Store/Actions'

type Inputs = {
  name?: string
  background_image?: string
  title?: string
  redirect_url?: string
  body?: string
  active?: boolean | string
  order?: number
}

export const EditAnnouncement = () => {
  const { formProps, saveButtonProps, queryResult } = useForm({})
  const record = queryResult?.data?.data

  const { show } = useNavigation()

  const [photoName, setPhotoName] = React.useState<string>()
  const [getAccount, setAccount] = React.useState<IAccount>()

  const handleGetToken = async () => {
    const account = await AccountStore.get('account')
    setAccount(account)
  }

  const navigateToShow = () => {
    record?.id && show('announcement', record?.id)
  }

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      id: record?.id,
      name: '',
      background_image: '',
      title: '',
      redirect_url: '',
      body: '',
      order: 0,
      active: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}

      if (submittedValues.name && submittedValues.name !== record?.name) {
        inputs.name = submittedValues.name
      }

      if (
        submittedValues.background_image &&
        submittedValues.background_image !== record?.background_image
      ) {
        inputs.background_image = submittedValues.background_image
      }

      if (submittedValues.title && submittedValues.title !== record?.title) {
        inputs.title = submittedValues.title
      }

      if (
        submittedValues.redirect_url &&
        submittedValues.redirect_url !== record?.redirect_url
      ) {
        inputs.redirect_url = submittedValues.redirect_url
      }

      if (submittedValues.body && submittedValues.body !== record?.body) {
        inputs.body = submittedValues.body
      }

      if (submittedValues.order && submittedValues.order !== record?.order) {
        inputs.order = submittedValues.order
      }

      if (submittedValues.active !== record?.active) {
        inputs.active = submittedValues.active
      }

      if (submittedValues.active !== record?.active) {
        inputs.active = submittedValues.active
      }

      if (Object.keys(inputs).length > 0) {
        EditAction('announcement', record?.id, inputs, navigateToShow)
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  React.useEffect(() => {
    handleGetToken()
  }, [])

  React.useEffect(() => {
    if (record) {
      setFieldValue('active', record?.active)
    }
  }, [record, setFieldValue])

  React.useEffect(() => {
    if (values.background_image) {
      notification.success({
        message: 'نحاح',
        description: 'تم التحميل بنجاح',
      })
    }
  }, [values.background_image])

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'تعديل إعلان'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item label={'العنوان'} name="title">
          <Input
            placeholder={'العنوان...'}
            name="title"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'الرابط'} name="redirect_url">
          <Input
            placeholder={'الرابط...'}
            name="redirect_url"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'النص'} name="body">
          <Input.TextArea
            placeholder="النص..."
            name="body"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'الترتيب'} name="order">
          <Input
            type="number"
            placeholder={'الترتيب'}
            style={{ width: 200 }}
            name="order"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'فعال'} name="active">
          <Switch
            defaultChecked={record?.active}
            onChange={(val) => setFieldValue('active', val)}
          />
        </Form.Item>
      </Form>

      <Form.Item
        name="background_image"
        getValueFromEvent={getValueFromEvent}
        noStyle
      >
        <Upload.Dragger
          name="file"
          action={POST_UPLOAD_URI}
          listType="picture"
          maxCount={1}
          showUploadList={false}
          onChange={(file) => {
            setFieldValue('background_image', file?.file?.response?.id)
            setPhotoName(file?.file?.response?.filename)
          }}
          headers={{
            authorization: `Bearer ${getAccount?.token}`,
            api_key: API_KEY,
          }}
        >
          <Space direction="vertical" size={2}>
            <Avatar
              style={{
                width: '100%',
                height: 150,
                borderRadius: 5,
              }}
              src={
                photoName
                  ? `${UPLOAD_URI}/${photoName}`
                  : record?.background_image?.filename
                  ? `${UPLOAD_URI}/${record?.background_image?.filename}`
                  : noPhoto
              }
              alt="background_image"
            />
          </Space>
        </Upload.Dragger>
      </Form.Item>
    </Edit>
  )
}
