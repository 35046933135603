import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  CreateButton,
  useTable,
  HttpError,
  useNavigation,
  useGetIdentity,
  BooleanField,
} from '@pankod/refine'
import { ADMIN_ENUM_ARRAY, IAccount } from '../Store/types'
import { DeleteRecordAction } from '../Store/Actions'
import { Actions } from '../../Components/ActionButtons'
import { Search } from '../../Components/Search'

const AdminList = () => {
  const [searchResults, setSearchResults] = useState<any>([])

  const { data: user } = useGetIdentity()

  const { show, edit } = useNavigation()

  const { tableProps, tableQueryResult } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
  })

  return (
    <List
      title={`${'المشرفين'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="admin"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {user?.role === 0 && (
              <CreateButton style={{ marginRight: 10 }}>
                إنشاء مشرف
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                record.id && show('admin', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="deleted"
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role === 0 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="المسؤول"
                    type="delete"
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `admin/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => record?.id && edit('admin', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                record.id && show('admin', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="role"
            title={'الدور'}
            render={(value) => (
              <TextField value={ADMIN_ENUM_ARRAY[+value] || 'لا يوجد'} />
            )}
          />
          <Table.Column
            dataIndex="deleted"
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role === 0 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="المسؤول"
                    type="delete"
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `admin/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => record?.id && edit('admin', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      )}
    </List>
  )
}

export default AdminList
