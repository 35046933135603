import * as React from 'react'
import {
  Create,
  Form,
  Input,
  InputNumber,
  Switch,
  useForm,
} from '@pankod/refine'

export const CreateAnnouncement = () => {
  const { formProps, saveButtonProps, queryResult } = useForm({})

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء إعلان'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item label={'العنوان'} name="title">
          <Input placeholder={'العنوان...'} />
        </Form.Item>

        <Form.Item label={'الرابط'} name="redirect_url">
          <Input placeholder={'الرابط...'} />
        </Form.Item>

        <Form.Item label={'النص'} name="body">
          <Input.TextArea placeholder="النص..." name="body" />
        </Form.Item>

        <Form.Item
          label={'الترتيب'}
          name="order"
          required
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <InputNumber placeholder={'الترتيب'} style={{ width: 200 }} />
        </Form.Item>

        <Form.Item label={'فعال'} name="active">
          <Switch />
        </Form.Item>
      </Form>
    </Create>
  )
}
