export const domain = (() => {
  return window.location.host.includes('admin.eadllaw.com')
    ? `https://${window.location.host}`
    : 'https://eadllaw.staging.wtmsrv.com'
})()

export const API_URL = `${domain}/api/v1.0`
export const API_KEY = 'TXlMZWdhaWxJREFQSUtFWQ=='

export const UPLOAD_URI = `${domain}/api/v1.0/upload/image`
export const UPLOAD_URI_ID = `${domain}/api/v1.0/upload/image-by-id`
export const POST_UPLOAD_URI = `${domain}/api/v1.0/upload`
