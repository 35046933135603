import axios, { AxiosInstance } from 'axios'
import {
  QueryFilter,
  QueryFilterArr,
  QuerySort,
  QuerySortArr,
  QuerySortOperator,
  RequestQueryBuilder,
  CondOperator,
  ComparisonOperator,
} from '@nestjsx/crud-request'
import {
  DataProvider,
  HttpError,
  CrudFilters as RefineCrudFilter,
  CrudOperators,
  CrudSorting,
} from '@pankod/refine-core'
import { stringify } from 'query-string'
import { AccountStore, ConfigsStore } from '../Store'

type SortBy = QuerySort | QuerySortArr | QuerySort[] | QuerySortArr[]
type CrudFilters =
  | QueryFilter
  | QueryFilterArr
  | QueryFilter[]
  | QueryFilterArr[]

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  }
)

const mapOperator = (operator: CrudOperators): ComparisonOperator => {
  switch (operator) {
    case 'ne':
      return CondOperator.NOT_EQUALS
    case 'lt':
      return CondOperator.LOWER_THAN
    case 'gt':
      return CondOperator.GREATER_THAN
    case 'lte':
      return CondOperator.LOWER_THAN_EQUALS
    case 'gte':
      return CondOperator.GREATER_THAN_EQUALS
    case 'in':
      return CondOperator.IN
    case 'nin':
      return CondOperator.NOT_IN
    case 'contains':
      return CondOperator.CONTAINS_LOW
    case 'ncontains':
      return CondOperator.EXCLUDES_LOW
    case 'containss':
      return CondOperator.CONTAINS
    case 'ncontainss':
      return CondOperator.EXCLUDES
    case 'null':
      return CondOperator.IS_NULL
  }

  return CondOperator.EQUALS
}

const generateSort = (sort?: CrudSorting): SortBy | undefined => {
  if (sort && sort.length > 0) {
    const multipleSort: SortBy = []
    // eslint-disable-next-line array-callback-return
    sort.map(({ field, order }) => {
      if (field && order) {
        // @ts-ignore
        multipleSort.push({
          field,
          order: order.toUpperCase() as QuerySortOperator,
        })
      }
    })
    return multipleSort
  }

  return
}

const generateFilter = (filters?: RefineCrudFilter): CrudFilters => {
  const crudFilters: CrudFilters = []
  if (filters) {
    // eslint-disable-next-line array-callback-return
    filters.map(({ field, operator, value }) => {
      // @ts-ignore
      crudFilters.push({
        field,
        operator: mapOperator(operator),
        value,
      })
    })
  }

  return crudFilters
}

const checkIfResourceIncluded = (resource: string) => {
  switch (resource) {
    case 'lawyer':
      return true
    case 'company':
      return true
    case 'blog':
      return true
    case 'comment':
      return true
    case 'specialty':
      return true
    case 'newsletter':
      return true
    case 'announcement':
      return true
    case 'membership':
      return true
    case 'association':
      return true
    case 'search-configs':
      return true
    case 'contact-us':
      return true
    case 'city':
      return true
    case 'system-configs':
      return true
    case 'courtDegree':
      return true
    case 'home-page-content':
      return true
    case 'suggestion':
      return true
    // case 'dashboard':
    //   return true
    default:
      return false
  }
}

const NestsxCrud = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  // @ts-ignore
  getList: async ({ resource, pagination, filters, sort }) => {
    let url = `${apiUrl}/${resource}`
    const current = pagination?.current || 1
    const pageSize = pagination?.pageSize || 10

    const generetedFilters = generateFilter(filters)

    const query = RequestQueryBuilder.create()
      .setFilter(generetedFilters)
      .setLimit(pageSize)
      .setPage(current)
      .setOffset((current - 1) * pageSize)

    const sortBy = generateSort(sort)
    if (sortBy) {
      query.sortBy(sortBy)
    }
    const account = await AccountStore.get('account')
    const country =
      (await ConfigsStore.get('country')) ||
      (account?.countries && account?.countries[0]?.id)

    if (resource === String('lawyerConfirmed') && country) {
      url = `${apiUrl}/lawyer`
      const sortOption = (await ConfigsStore.get('sort')) || 'created_at'
      const removeNoneFromFilter = filters?.filter(
        (filter) => filter.value !== -1
      )
      const filterList = removeNoneFromFilter
        ?.map((filter) => {
          return `"${filter.field}":${filter.value}`
        })
        ?.join(',')

      const { data } = await httpClient.get(
        `${url}?query={"limit":${pageSize},"page":${current},"sort":{"${sortOption}":"DESC"},"where":{"country": "${country}",${filterList}}}`
      )

      return {
        data: data.data,
        total: data.total,
      }
    }

    if (resource === String('lawyer') && country) {
      const sortOption = (await ConfigsStore.get('sort')) || 'created_at'
      const removeNoneFromFilter = filters?.filter(
        (filter) => filter.value !== -1
      )
      const filterList =
        (removeNoneFromFilter || [])?.length > 0
          ? `,${removeNoneFromFilter
              ?.map((filter) => {
                return `"${filter.field}":${filter.value}`
              })
              ?.join(',')}`
          : ''

      const { data } = await httpClient.get(
        `${url}?query={"limit":${pageSize},"page":${current},"sort":{"${sortOption}":"DESC"},"where":{"country": "${country}"${filterList}}}`
      )

      return {
        data: data.data,
        total: data.total,
      }
    }

    if (checkIfResourceIncluded(resource) && country) {
      const { data } = await httpClient.get(
        `${url}?query={"limit":${pageSize},"page":${current},"where":{"country": "${country}"}}`
      )

      return {
        data: data.data,
        total: data.total,
      }
    }

    const { data } = await httpClient.get(
      `${url}?query={"limit":${pageSize},"page":${current}}`
    )

    return {
      data: data.data,
      total: data.total,
    }
  },

  getMany: async ({ resource, ids }) => {
    const url = `${apiUrl}/${resource}`
    const account = await AccountStore.get('account')
    const country =
      (await ConfigsStore.get('country')) ||
      (account?.countries && account?.countries[0]?.id)

    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: ids,
      })
      .query()

    if (checkIfResourceIncluded(resource) && country) {
      const { data } = await httpClient.get(
        `${url}?${query}&country:${country}`
      )

      return {
        data,
      }
    }

    const { data } = await httpClient.get(`${url}?${query}`)

    return {
      data,
    }
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`
    const account = await AccountStore.get('account')
    const country =
      (await ConfigsStore.get('country')) ||
      (account?.countries && account?.countries[0]?.id)

    if (checkIfResourceIncluded(resource) && country) {
      const { data } = await httpClient.post(
        url,
        variables && {
          ...variables,
          country,
        }
      )

      return {
        data,
      }
    }

    const { data } = await httpClient.post(url, variables)

    return {
      data,
    }
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`

    const { data } = await httpClient.patch(url, variables)

    return {
      data,
    }
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables
        )
        return data
      })
    )

    return { data: response }
  },

  createMany: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}/bulk`
    const account = await AccountStore.get('account')
    const country =
      (await ConfigsStore.get('country')) ||
      (account?.countries && account?.countries[0]?.id)

    if (checkIfResourceIncluded(resource) && country) {
      const { data } = await httpClient.post(
        url,
        variables && {
          ...variables,
          country,
        }
      )

      return {
        data,
      }
    }

    const { data } = await httpClient.post(url, { bulk: variables })

    return {
      data,
    }
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`

    const { data } = await httpClient.get(url)

    return {
      data,
    }
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`
    const { data } = await httpClient.delete(url)

    return {
      data,
    }
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`)
        return data
      })
    )
    return { data: response }
  },

  getApiUrl: () => {
    return apiUrl
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    const requestQueryBuilder = RequestQueryBuilder.create().setFilter(
      generateFilter(filters)
    )

    const account = await AccountStore.get('account')
    const country =
      (await ConfigsStore.get('country')) ||
      (account?.countries && account?.countries[0]?.id)

    const sortBy = generateSort(sort)
    if (sortBy) {
      requestQueryBuilder.sortBy(sortBy)
    }

    let requestUrl = `${url}?${requestQueryBuilder.query()}`

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      }
    }

    let axiosResponse

    if (checkIfResourceIncluded(url)) {
      switch (method) {
        case 'put':
        case 'post':
        case 'patch':
          axiosResponse = await httpClient[method](
            url,
            payload && { ...payload, country }
          )
          break
        case 'delete':
          axiosResponse = await httpClient.delete(
            `${url}?query={"where":{"country": "${country}"}}`
          )
          break
        default:
          axiosResponse = await httpClient.get(
            `${url}?query={"where":{"country": "${country}"}}`
          )
          break
      }

      const { data } = axiosResponse

      return Promise.resolve({ data })
    }

    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload)
        break
      case 'delete':
        axiosResponse = await httpClient.delete(
          `${url}?query={"where":{"country": "${country}"}}`
        )
        break
      default:
        axiosResponse = await httpClient.get(
          `${requestUrl}?query={"where":{"country": "${country}"}}`
        )
        break
    }

    const { data } = axiosResponse

    return Promise.resolve({ data })
  },
})

export default NestsxCrud
