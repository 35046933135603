import React from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'

const LawyerSuggestionList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const { tableProps, tableQueryResult } = useTable<any, HttpError>({
    syncWithLocation: true,
  })

  return (
    <>
      <List
        title={`${'اقتراحات المحامين'}`}
        pageHeaderProps={{
          extra: user?.role !== 2 && <CreateButton>إنشاء اقتراح</CreateButton>,
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('suggestion', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'name'}
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'phoneNumber'}
            title={'رقم الهاتف'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'email'}
            title={'البريد الإلكتروني'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'suggestion'}
            title={'الاقتراح'}
            render={(value) => (
              <TextField
                value={value?.length > 39 ? `${value?.slice(0, 40)}...` : value}
              />
            )}
          />

          <Table.Column
            dataIndex={'country'}
            title={'الدولة'}
            render={(value) => <TextField value={value?.country} />}
          />

          <Table.Column
            dataIndex="deleted"
            title={'محذوف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="اقتراح"
                    deleteRecord
                    type="edit"
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `suggestion/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => edit('suggestion', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
    </>
  )
}

export default LawyerSuggestionList
