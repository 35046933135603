import React from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  notification,
  Switch,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
const { Text } = Typography

interface Inputs {
  id?: string
  specialty?: string
  show?: boolean
}

function EditSpecialty() {
  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data

  const { setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      specialty: '',
      show: true,
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        show: submittedValues.show,
      }
      if (submittedValues.specialty) {
        inputs.specialty = submittedValues.specialty
      }

      EditAccountAction(
        'specialty',
        record?.id,
        inputs,
        resetForm,
        queryResult?.refetch
      )

      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل التخصص'}
        pageHeaderProps={{
          extra: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListButton>
                <Text>{'التخصصات'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item required label={'التخصص'} name="specialty">
            <Input name="specialty" onChange={handleChange} />
          </Form.Item>

          <Form.Item label={'إظهار'} name="show">
            <Switch
              defaultChecked={record?.show}
              onChange={(val) => setFieldValue('show', val)}
            />
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}

export default EditSpecialty
