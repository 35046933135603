import React, { useEffect, useState } from 'react'
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Dropdown,
  Col,
  Avatar,
  Typography,
  useGetIdentity,
  Grid,
  useLogout,
  useNavigation,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { UPLOAD_URI } from '../../Configs'
import { AccountStore } from '../../Containers/Store'
import Clock from 'react-live-clock'
import ChangePasswordModal from '../ChangePasswordModal'
import CountriesDropdown from './CountriesDropdown'
import { RefetchAccountAction } from '../../Containers/Store/Actions'

const { useBreakpoint } = Grid

export const Header: React.FC = () => {
  const { Text } = Typography
  const { data: user } = useGetIdentity()
  const { mutate: logout } = useLogout()
  const { show } = useNavigation()
  const screens = useBreakpoint()
  const [currentId, setCurrentId] = useState<any>()
  const [currentAccount, setCurrentAccount] = useState<any>()

  const [changePasswordModal, setChangePasswordModal] = useState(false)

  const accountActions = (
    <Menu selectedKeys={['ar']}>
      <Menu.Item onClick={() => show('admin', currentId?.id)}>
        {'معلومات الحساب'}
      </Menu.Item>
      <Menu.Item onClick={() => setChangePasswordModal(currentId?.id)}>
        {'تغيير كلمة السر'}
      </Menu.Item>
      <CountriesDropdown />
      <Menu.Item onClick={() => logout()}>{'تسجيل الخروج'}</Menu.Item>
    </Menu>
  )

  const getAccountData = async () =>
    setCurrentId(await AccountStore.get('account'))

  const getCurrentAccount = async () =>
    setCurrentAccount(await AccountStore.get('account'))

  useEffect(() => {
    getCurrentAccount()
  }, [])

  useEffect(() => {
    if (currentAccount?.id) {
      RefetchAccountAction(currentAccount?.id)
    }
  }, [currentAccount?.id])

  return (
    <>
      <AntdLayout.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: screens.xs ? '0px 24px 10px' : '0px 24px',
          height: screens.xs ? 'auto' : '70px',
          backgroundColor: '#FFF',
          flexDirection: screens.xs ? 'column-reverse' : 'row',
          width: screens.xs ? '85%' : 'auto',
          margin: screens.xs ? 'auto' : '',
        }}
      >
        <Col xl={8} style={{ width: screens.xs ? '100%' : 'auto' }}></Col>
        <Col style={{ width: screens.xs ? '100%' : 'auto' }}>
          <Clock
            format={'dd, DD-MM,  HH:mm'}
            ticking={true}
            style={{ display: screens.md ? '' : 'none' }}
          />
          <Space
            size="middle"
            style={{
              width: screens.xs ? '100%' : 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Dropdown overlay={accountActions} onVisibleChange={getAccountData}>
              <Button type="link">
                <Space>
                  <Text
                    ellipsis
                    strong
                    style={{ display: screens.xs ? 'none' : '' }}
                  >
                    {user?.name}
                  </Text>
                  <Avatar
                    size="large"
                    src={
                      user?.photo?.filename
                        ? `${UPLOAD_URI}/${user?.photo?.filename}`
                        : noPhoto
                    }
                    alt={user?.name}
                  />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </Col>
      </AntdLayout.Header>
      <ChangePasswordModal
        visible={changePasswordModal}
        setVisible={setChangePasswordModal}
      />
    </>
  )
}
