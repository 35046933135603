import * as React from 'react'
import {
  Create,
  Form,
  Input,
  notification,
  useForm,
  useNavigation,
} from '@pankod/refine'
import { parsePhoneNumber } from 'libphonenumber-js'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CreateAction } from '../Store/Actions'
import dayjs from 'dayjs'

export interface Inputs {
  name?: string
  email?: string
  phoneNumber?: string
  suggestion?: string
  created_at?: string | Date
}

export const CreateLawyerSuggestion = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const { edit } = useNavigation()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    phoneNumber: Yup.string().required('مطلوب'),
    email: Yup.string()
      .email('يرجى ادخال بريد الكتروني صحيح')
      .required('يرجى ادخال بريد الكتروني صحيح'),
    suggestion: Yup.string().required('مطلوب'),
  })

  const { errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      suggestion: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        name: submittedValues.name,
        email: submittedValues.email,
        phoneNumber: submittedValues.phoneNumber,
        suggestion: submittedValues.suggestion,
        created_at: dayjs().format(),
      }

      if (Object.keys(inputs)?.length) {
        CreateAction('suggestion', inputs, edit)
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => {
      if (Object.keys(errors)?.length > 0) {
        return notification.error({
          message: 'خطأ',
          description: 'الرجاء إدخال جميع الحقول المطلوبة',
        })
      }
      handleSubmit()
    },
  }

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'إنشاء اقتراح'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'الاسم'}
          name="name"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="name" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label={'البريد الإلكتروني'}
          name={'email'}
          required
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!regex.test(email)) {
                  return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                }
              },
            },
          ]}
        >
          <Input name="email" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          required
          label={'رقم الهاتف'}
          name="phoneNumber"
          rules={[
            {
              validator: async (_, phoneNumber) => {
                const phone = parsePhoneNumber(phoneNumber, 'JO')
                if (!phone.isValid()) {
                  return Promise.reject(new Error('رقم الهاتف غير صالح'))
                }
              },
            },
          ]}
        >
          <Input name="phoneNumber" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          required
          label={'الاقتراح'}
          name="suggestion"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input.TextArea name="suggestion" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Create>
  )
}
