import React from 'react'
import { Typography, Grid } from '@pankod/refine'
import './style.less'
import { Statistcis } from '../../../Components/Statistcis'
import { BiComment } from 'react-icons/bi'

interface Props {
  record: any
}
export const DailyComments = ({ record }: Props) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const value = {
    currentWeek: record?.comments_weekly_ratio?.current,
    previousWeek: record?.comments_weekly_ratio?.previous,
    currentMonth: record?.comments_monthly_ratio?.current,
    previousMonth: record?.comments_monthly_ratio?.previous,
  }
  return (
    <div>
      <div className="title-area__number">
        <BiComment
          size={25}
          color="#50606B"
          style={{ margin: '0px 8px 10px' }}
        />
        <Typography.Title
          level={screens.xs ? 5 : 4}
          style={{ color: '#50606B' }}
        >
          {'التعليقات'}
          {record?.current_comments ? ` ${record?.current_comments}` : ' 0'}
        </Typography.Title>
      </div>
      <Statistcis record={record} value={value} />
    </div>
  )
}
