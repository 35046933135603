import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Association } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import { Search } from '../../Components/Search'
import { lawyerTypeByIndex } from '../../Util/Helpers'

const AssociationList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps } = useTable<Association, HttpError>({
    resource: 'association',
    syncWithLocation: true,
  })

  return (
    <List
      title={`${'الجهات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="association"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {user?.role !== 2 && (
              <CreateButton style={{ marginRight: 10 }}>
                {'إنشاء جمعية أو جهة'}{' '}
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('association', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'name'}
            title={'الجهة'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'type'}
            title={'النوعية'}
            render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
          />

          <Table.Column
            dataIndex="show"
            title={'إظهار'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.roles !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الجهة"
                    type="edit"
                    // deleteRecord
                    edit
                    record={record}
                    // onClickDelete={() =>
                    //   DeleteRecordAction(
                    //     `association/${record?.id}`,
                    //     tableQueryResult?.refetch,
                    //     false,
                    //     undefined,
                    //     record?.deleted
                    //   )
                    // }
                    onClickEdit={() => edit('association', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('association', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={'name'}
            title={'الجهة'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'type'}
            title={'النوعية'}
            render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
          />

          <Table.Column
            dataIndex="show"
            title={'إظهار'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.roles !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الجهة"
                    type="edit"
                    edit
                    record={record}
                    onClickEdit={() => edit('association', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      )}
    </List>
  )
}

export default AssociationList
