import * as React from 'react'
import { Create, Form, Input, Select, useForm } from '@pankod/refine'
import { TYPES_ENUM_ARRAY } from '../Store/types'
import { AccountStore, ConfigsStore } from '../Store'

export const CreateSpecialty = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const [currentCountry, setCurrentCountry] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء تخصص'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'التخصص'}
          name="specialty"
          rules={[
            {
              validator: async (_, specialty) => {
                if (!specialty) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="specialty" />
        </Form.Item>

        <Form.Item
          label={'نوع التخصص'}
          name={'type'}
          required
          rules={[
            {
              validator: async (_, type) => {
                if (type !== 0 && !type) {
                  return Promise.reject(new Error('مطلوب'))
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Select
            options={[
              { value: 0, label: TYPES_ENUM_ARRAY[0] },
              {
                value: currentCountry === 'QA' ? 5 : 1,
                label:
                  currentCountry === 'QA'
                    ? 'مستشار قانوني'
                    : TYPES_ENUM_ARRAY[1],
              },
              { value: 2, label: TYPES_ENUM_ARRAY[2] },
              { value: 3, label: TYPES_ENUM_ARRAY[3] },
              { value: 4, label: TYPES_ENUM_ARRAY[4] },
            ]}
            placeholder={'اختر'}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
