import React from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  notification,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
const { Text } = Typography

interface Inputs {
  id?: string
  video_url?: string
}

function EditSystemConfigs() {
  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data

  const { handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      video_url: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}
      if (submittedValues.video_url) {
        inputs.video_url = submittedValues.video_url
      }
      EditAccountAction(
        'system-configs',
        record?.id,
        inputs,
        resetForm,
        queryResult?.refetch
      )
      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل الفيديو'}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ListButton>
                <Text>{'إعدادات النظام'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item required label={'رابط الفيديو'} name="video_url">
            <Input name="video_url" onChange={handleChange} />
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}

export default EditSystemConfigs
