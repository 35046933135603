import * as React from 'react'
import { Create, Form, Input, Select, useForm } from '@pankod/refine'
import { ConfigsStore, AccountStore } from '../Store'
const { Option } = Select

export const CreateAssociation = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const [currentCountry, setCurrentCountry] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء جهة'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'الجهة'}
          name="name"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="name" />
        </Form.Item>

        <Form.Item
          label={'نوعية الحساب'}
          name="type"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Select placeholder={'اختر'}>
            <Option value={0}>{'محامي'}</Option>
            <Option value={currentCountry === 'QA' ? 5 : 1}>
              {currentCountry === 'QA' ? 'مستشار قانوني' : 'شرعي'}
            </Option>
            <Option value={2}>{'محكم'}</Option>
            <Option value={3}>{'خبير'}</Option>
            <Option value={4}>{'شركة'}</Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  )
}
