import * as React from 'react'
import {
  Create,
  Form,
  Input,
  notification,
  Select,
  useForm,
  useList,
  useNavigation,
  useTable,
} from '@pankod/refine'
import {
  IAccount,
  LAWYER_ENUM_ARRAY,
  LAWYER_ENUM_ARRAY_QATAR,
} from '../Store/types'
import { useFormik } from 'formik'
import {
  CreateAccountAction,
  FindLawyerByNaqabehNumber,
} from '../Store/Actions'
import * as Yup from 'yup'
import { parsePhoneNumber } from 'libphonenumber-js'
import { ConfigsStore, AccountStore } from '../Store'

export interface Inputs {
  type?: string
  name?: string
  email?: string
  phone?: string
  city?: string
  password?: string
  photo?: string
  membership_id?: string
  naqabeh_number?: string
  ejazeh_number?: string
  commercial_name?: string
  authorized_signatory?: string
  ssn?: string
  verified_specialties?: string[]
  fax?: string
  land_line?: string
  google_maps_link?: string
  youtube?: string
  telegram?: string
  country?: string
}

export const CreateLawyer = () => {
  const [baseType, setBaseType] = React.useState<any>()
  const [lawyerByNaqabeNumber, setLawyerByNaqabeNumber] = React.useState<
    IAccount | undefined
  >()
  const { edit } = useNavigation()
  const { saveButtonProps } = useForm({})
  const cities = useTable({ resource: 'city', initialPageSize: 10000000 })
  const membership = useList({ resource: 'membership' })
  const filteredCities = (cities?.tableProps.dataSource || [])?.map((item) => {
    return { label: item?.city, value: item?.id }
  })

  const [currentCountry, setCurrentCountry] = React.useState<string>()
  const [currentCountryId, setCurrentCountryId] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )
    setCurrentCountryId(filterCountry?.id)
    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    phone: Yup.string().required('مطلوب'),
    city: Yup.string().required('مطلوب'),
    email: Yup.string()
      .email('يرجى ادخال بريد الكتروني صحيح')
      .required('يرجى ادخال بريد الكتروني صحيح'),
    password: Yup.string()
      .min(8, 'يجب ان يحتوي على ٨ خانات')
      .required('يرجى ادخال كلمة سر صحيحة'),
  })

  const {
    setFieldValue,
    errors,
    values,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      type: '',
      name: '',
      email: '',
      phone: '',
      photo: '',
      naqabeh_number: '',
      ejazeh_number: '',
      ssn: '',
      city: '',
      password: '',
      membership_id: '',
      fax: '',
      land_line: '',
      google_maps_link: '',
      youtube: '',
      telegram: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      if (submittedValues.type === '') {
        return notification.info({
          message: 'تحذير',
          description: 'الرجاء اختيار نوعية الحساب',
        })
      }
      const inputs: Inputs = {
        country: currentCountryId,
        type: submittedValues.type,
        name: submittedValues.name,
        email: submittedValues.email,
        phone: submittedValues.phone,
        city: submittedValues.city,
        password: submittedValues.password,
        membership_id: membership?.data?.data[0]?.id,
      }
      if (submittedValues.naqabeh_number) {
        inputs.naqabeh_number = submittedValues.naqabeh_number
      }
      if (submittedValues.ejazeh_number) {
        inputs.ejazeh_number = submittedValues.ejazeh_number
      }
      if (submittedValues.ssn) inputs.ssn = submittedValues.ssn

      if (submittedValues.fax) inputs.fax = submittedValues.fax
      if (submittedValues.land_line) {
        inputs.land_line = submittedValues.land_line
      }
      if (submittedValues.google_maps_link) {
        inputs.google_maps_link = submittedValues.google_maps_link
      }
      if (submittedValues.youtube) inputs.youtube = submittedValues.youtube
      if (submittedValues.telegram) inputs.telegram = submittedValues.telegram

      if (Object.keys(inputs)?.length) {
        CreateAccountAction('lawyer', inputs, resetForm, edit)
      }
    },
  })

  React.useEffect(() => {
    if (values.naqabeh_number?.length >= 3) {
      FindLawyerByNaqabehNumber(values.naqabeh_number, setLawyerByNaqabeNumber)
    }
  }, [values.naqabeh_number])

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => {
      if (Object.keys(errors)?.length > 0) {
        return notification.error({
          message: 'خطأ',
          description: 'الرجاء إدخال جميع الحقول المطلوبة',
        })
      }
      handleSubmit()
    },
  }

  return (
    <Create saveButtonProps={buttonProps} title={'إنشاء محامي'}>
      <Form
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item label={'نوع الحساب'} name={'type'} required>
          <Select
            options={[
              { value: 0, label: LAWYER_ENUM_ARRAY[0] },
              {
                value: currentCountry === 'QA' ? 5 : 1,
                label:
                  currentCountry === 'QA'
                    ? LAWYER_ENUM_ARRAY_QATAR[1]
                    : LAWYER_ENUM_ARRAY[1],
              },
              { value: 2, label: LAWYER_ENUM_ARRAY[2] },
              { value: 3, label: LAWYER_ENUM_ARRAY[3] },
            ]}
            placeholder={'اختر'}
            onSelect={(val) => {
              setBaseType(val)
              setLawyerByNaqabeNumber(undefined)
              setFieldValue('type', val)
            }}
          />
        </Form.Item>

        {baseType === 0 && (
          <Form.Item
            label={currentCountry === 'QA' ? 'الرقم الشخصي' : 'الرقم النقابي'}
            name={currentCountry === 'QA' ? 'ssn' : 'naqabeh_number'}
            required
          >
            <Input
              name={currentCountry === 'QA' ? 'ssn' : 'naqabeh_number'}
              placeholder={
                currentCountry === 'QA' ? 'الرقم الشخصي' : 'الرقم النقابي'
              }
              onChange={handleChange}
            />
          </Form.Item>
        )}

        {baseType === 5 && (
          <Form.Item
            label={currentCountry === 'QA' ? 'الرقم الشخصي' : 'الرقم النقابي'}
            name={'ssn'}
            required
          >
            <Input
              name="ssn"
              placeholder={
                currentCountry === 'QA' ? 'الرقم الشخصي' : 'الرقم النقابي'
              }
              onChange={handleChange}
            />
          </Form.Item>
        )}

        {baseType === 1 && (
          <Form.Item
            label={
              currentCountry === 'QA' ? 'الرقم الشخصي' : 'رقم الاجازة الشرعية'
            }
            name={currentCountry === 'QA' ? 'ssn' : 'ejazeh_number'}
            required
          >
            <Input
              name={currentCountry === 'QA' ? 'ssn' : 'ejazeh_number'}
              placeholder={
                currentCountry === 'QA' ? 'الرقم الشخصي' : 'رقم الاجازة الشرعية'
              }
              onChange={handleChange}
            />
          </Form.Item>
        )}

        {(baseType === 2 || baseType === 3) && (
          <Form.Item label={'الرقم الوطني'} name="ssn" required>
            <Input
              name="ssn"
              placeholder={'الرقم الوطني'}
              onChange={handleChange}
            />
          </Form.Item>
        )}

        {lawyerByNaqabeNumber?.name ? (
          <Form.Item label={'الاسم'} required>
            <Input
              name="name"
              placeholder={'الاسم...'}
              onChange={handleChange}
              value={
                lawyerByNaqabeNumber?.name
                  ? lawyerByNaqabeNumber?.name
                  : values.name
              }
            />
          </Form.Item>
        ) : (
          <Form.Item label={'الاسم'} required>
            <Input
              name="name"
              placeholder={'الاسم...'}
              onChange={handleChange}
              value={values.name}
            />
          </Form.Item>
        )}

        {lawyerByNaqabeNumber?.phone ? (
          <Form.Item
            label={'رقم الهاتف'}
            required
            rules={[
              {
                validator: async (_, phone) => {
                  const phoneNumber = parsePhoneNumber(phone, 'JO')
                  if (!phoneNumber.isValid()) {
                    return Promise.reject(new Error('رقم الهاتف غير صالح'))
                  }
                },
              },
            ]}
          >
            <Input
              name="phone"
              placeholder={'رقم الهاتف'}
              onChange={handleChange}
              style={{ direction: 'ltr', textAlign: 'right' }}
              value={
                lawyerByNaqabeNumber?.phone
                  ? lawyerByNaqabeNumber?.phone
                  : values.phone
              }
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={'رقم الهاتف'}
            required
            rules={[
              {
                validator: async (_, phone) => {
                  const phoneNumber = parsePhoneNumber(phone, 'JO')
                  if (!phoneNumber.isValid()) {
                    return Promise.reject(new Error('رقم الهاتف غير صالح'))
                  }
                },
              },
            ]}
          >
            <Input
              name="phone"
              placeholder={'رقم الهاتف'}
              style={{ direction: 'ltr', textAlign: 'right' }}
              onChange={handleChange}
              value={values.phone}
            />
          </Form.Item>
        )}

        {lawyerByNaqabeNumber?.email ? (
          <Form.Item
            label={'البريد الإلكتروني'}
            required
            rules={[
              {
                validator: async (_, email) => {
                  const regex =
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                  if (!regex.test(email)) {
                    return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                  }
                },
              },
            ]}
          >
            <Input
              name="email"
              placeholder="example@example.com"
              onChange={handleChange}
              value={
                lawyerByNaqabeNumber?.email
                  ? lawyerByNaqabeNumber?.email
                  : values.email
              }
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={'البريد الإلكتروني'}
            required
            rules={[
              {
                validator: async (_, email) => {
                  const regex =
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                  if (!regex.test(email)) {
                    return Promise.reject(new Error('بريد إلكتروني خاطئ'))
                  }
                },
              },
            ]}
          >
            <Input
              name="email"
              placeholder="example@example.com"
              onChange={handleChange}
              value={values.email}
            />
          </Form.Item>
        )}

        <Form.Item label={'المدينة'} name={'city'} required>
          <Select
            // @ts-ignore
            options={filteredCities}
            placeholder={'اختر'}
            onSelect={(city) => setFieldValue('city', city)}
          />
        </Form.Item>

        <Form.Item label={'الفاكس'} name="fax">
          <Input
            name="fax"
            placeholder={'الفاكس'}
            onChange={handleChange}
            style={{ direction: 'ltr', textAlign: 'right' }}
          />
        </Form.Item>

        <Form.Item label={'الهاتف الارضي'} name="land_line">
          <Input
            name="land_line"
            placeholder={'الهاتف الارضي'}
            onChange={handleChange}
            style={{ direction: 'ltr', textAlign: 'right' }}
          />
        </Form.Item>

        <Form.Item label={'خرائط جوجل'} name="google_maps_link">
          <Input
            name="google_maps_link"
            placeholder={'خرائط جوجل'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'اليوتيوب'} name="youtube">
          <Input
            name="youtube"
            placeholder={'اليوتيوب'}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'التليجرام'} name="telegram">
          <Input
            name="telegram"
            placeholder={'التليجرام'}
            onChange={handleChange}
            style={{ direction: 'ltr', textAlign: 'right' }}
          />
        </Form.Item>

        <Form.Item label={'كلمة السر'} name="password" required>
          <Input
            name="password"
            type="password"
            placeholder="●●●●●●●●"
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
