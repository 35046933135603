import React, { useEffect, useState } from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  Row,
  Col,
  Avatar,
  ListButton,
  Typography,
  Upload,
  Space,
  Grid,
  Select,
  useList,
  notification,
  Switch,
  useGetIdentity,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { API_KEY, POST_UPLOAD_URI, UPLOAD_URI } from '../../Configs'
import { useFormik } from 'formik'
import { AccountStore, ConfigsStore } from '../Store'
import { EditAccountAction, UpdateMembership } from '../Store/Actions'
import styled from 'styled-components'
import { RiCloseCircleFill } from 'react-icons/ri'
import Colors from '../../Style/Colors'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Inputs {
  id?: string
  name?: string
  email?: string
  commercial_name?: string
  logo?: string
  address?: string
  membership_id?: string
  is_disabled?: string | boolean
  disable_review?: string | boolean
  goals?: string[]
  photos?: string[]
  fax?: string
  land_line?: string
  google_maps_link?: string
  youtube?: string
  telegram?: string
}

function EditCompany() {
  const screens = useBreakpoint()
  const { data: user } = useGetIdentity()
  const [getAccount, setAccount] = useState<any>()
  const [getGoals, setGoals] = useState<any>()
  const [photoName, setPhotoName] = useState<string>()

  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data

  const handleGetToken = async () => {
    const account = await AccountStore.get('account')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const goals = await ConfigsStore.get('specialties')
    setAccount(account)
    setGoals(goals)
  }

  useEffect(() => {
    handleGetToken()
  }, [])

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      id: record?.id,
      name: '',
      email: '',
      commercial_name: '',
      logo: '',
      membership_id: '',
      address: '',
      is_disabled: '',
      disable_review: '',
      goals: ('' as unknown) as string[],
      photos: ('' as unknown) as string[],
      fax: '',
      land_line: '',
      google_maps_link: '',
      youtube: '',
      telegram: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}
      if (submittedValues.name) inputs.name = submittedValues.name
      if (submittedValues.email) inputs.email = submittedValues.email
      if (submittedValues.address !== record.address) {
        inputs.address = submittedValues.address
      }

      if (submittedValues.commercial_name !== record.commercial_name) {
        inputs.commercial_name = submittedValues.commercial_name
      }
      if (submittedValues.logo) inputs.logo = submittedValues.logo
      if (submittedValues.photos) inputs.photos = submittedValues.photos

      if (submittedValues.fax) inputs.fax = submittedValues.fax
      if (submittedValues.land_line) {
        inputs.land_line = submittedValues.land_line
      }
      if (submittedValues.google_maps_link) {
        inputs.google_maps_link = submittedValues.google_maps_link
      }
      if (submittedValues.youtube) inputs.youtube = submittedValues.youtube
      if (submittedValues.telegram) inputs.telegram = submittedValues.telegram

      if (
        // @ts-ignore
        submittedValues.disable_review === false ||
        submittedValues.disable_review
      ) {
        inputs.disable_review = submittedValues.disable_review
      }
      if (
        // @ts-ignore
        submittedValues.is_disabled === false ||
        submittedValues.is_disabled
      ) {
        inputs.is_disabled = submittedValues.is_disabled
      }

      if (submittedValues.goals) {
        if (record?.membership?.type === 0) {
          if (submittedValues.goals?.length <= 1) {
            inputs.goals = submittedValues.goals
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة تخصص واحد فقط',
            })
          }
        }

        if (record?.membership?.type === 1) {
          if (submittedValues.goals?.length <= 3) {
            inputs.goals = submittedValues.goals
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة ثلاث تخصصات فقط',
            })
          }
        }

        if (record?.membership?.type === 2) {
          if (submittedValues.goals?.length <= 5) {
            inputs.goals = submittedValues.goals
          } else {
            return notification.info({
              message: 'تحذير',
              description: 'يمكنك إضافة خمس تخصصات فقط',
            })
          }
        }
      }

      if (submittedValues.membership_id) {
        const findMembership = membership?.data?.data?.find(
          (item) => item?.id === values.membership_id
        )
        if (findMembership?.type > record?.membership?.type) {
          UpdateMembership(
            'company',
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            true
          )
        }
        if (findMembership?.type < record?.membership?.type) {
          UpdateMembership(
            'company',
            record?.id,
            { membership_id: submittedValues.membership_id },
            resetForm,
            queryResult?.refetch,
            false
          )
        }
      }
      if (Object.keys(inputs)?.length) {
        EditAccountAction(
          'company',
          record?.id,
          inputs,
          resetForm,
          queryResult?.refetch,
          !!submittedValues.logo
        )
      }
      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  const membership = useList({ resource: 'membership' })

  const filteredMembership = (membership?.data?.data || [])?.map((item) => {
    return { label: item?.name, value: item?.id }
  })

  const filteredGoals = (getGoals || [])
    ?.filter((item: { type: number }) => item?.type === 4)
    ?.map((item: { specialty: string; id: string }) => {
      return { label: item?.specialty, value: item?.id }
    })

  const selectGoalsProps: any = {
    mode: 'tags',
    style: { width: '100%' },
    value: values.goals || [],
    options: filteredGoals || [],
    onChange: (goal: string[]) => setFieldValue('goals', goal),
    placeholder: 'تخصصات...',
    maxTagCount: 'responsive',
  }

  useEffect(() => {
    if (record && record?.goals?.length > 0) {
      setFieldValue(
        'goals',
        record?.goals?.map((goal: { id: string }) => goal?.id)
      )
    }
    handleGetToken()
  }, [record, setFieldValue])

  const handleRemovePhoto = (index: number) => {
    const ids = record?.photos
      .splice(index, 1)
      ?.map((photo: { id: string }) => photo?.id)
    setFieldValue(
      'photos',
      record?.photos
        .filter((item: { id: any }) => item?.id !== ids[0])
        ?.map((photo: { id: string }) => photo?.id)
    )
  }

  useEffect(() => {
    if (values.logo) {
      notification.success({
        message: 'نحاح',
        description: 'تم التحميل بنجاح',
      })
    }
  }, [values.logo])

  useEffect(() => {
    if (record) {
      setFieldValue('address', record.address)
      setFieldValue('commercial_name', record.commercial_name)
    }
  }, [record, setFieldValue])

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={'تعديل الشركة'}
        pageHeaderProps={{
          extra: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListButton>
                <Text>{'الشركات'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Row gutter={[64, 0]} wrap align={'middle'}>
          <Col xs={24} lg={7}>
            <Form.Item>
              <Form.Item name="logo" noStyle>
                <Upload.Dragger
                  name="file"
                  action={POST_UPLOAD_URI}
                  listType="picture"
                  showUploadList={false}
                  onChange={(file) => {
                    setFieldValue('logo', file?.file?.response?.id)
                    setPhotoName(file?.file?.response?.filename)
                  }}
                  maxCount={1}
                  headers={{
                    api_key: API_KEY,
                    authorization: `Bearer ${getAccount?.token}`,
                  }}
                  style={{
                    border: 'none',
                    width: '100%',
                    background: 'none',
                  }}
                >
                  <Space direction="vertical" size={2}>
                    <Avatar
                      style={{
                        width: '200px',
                        height: '200px',
                        marginBottom: screens?.xs ? 20 : 0,
                      }}
                      src={
                        photoName
                          ? `${UPLOAD_URI}/${photoName}`
                          : record?.logo?.filename
                          ? `${UPLOAD_URI}/${record?.logo?.filename}`
                          : noPhoto
                      }
                      alt="Profile Picture"
                    />
                  </Space>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={24} lg={17}>
            <Form {...formProps} layout="vertical">
              <Row gutter={[64, 0]} wrap align={'middle'}>
                <Col xs={24} lg={15} style={{ alignSelf: 'flex-start' }}>
                  <Form.Item required label={'الاسم'} name="name">
                    <Input name="name" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item label={'البريد الالكتروني'} name="email" required>
                    <Input
                      name="email"
                      onChange={handleChange}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'الاسم التجاري'} name="commercial_name">
                    <Input
                      name="commercial_name"
                      onChange={handleChange}
                      disabled={record?.membership_expiry && user?.role !== 0}
                    />
                  </Form.Item>

                  <Form.Item label={'العنوان'} name="address">
                    <Input name="address" onChange={handleChange} />
                  </Form.Item>

                  <Form.Item label={'العضوية'} name={['membership', 'name']}>
                    <Select
                      // @ts-ignore
                      options={filteredMembership}
                      onSelect={(id) => setFieldValue('membership_id', id)}
                    />
                  </Form.Item>
                  <Form.Item label={'التخصص'}>
                    <Select
                      {...selectGoalsProps}
                      disabled={record?.membership_expiry && user?.role !== 0}
                      showSearch={false}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={9} style={{ alignSelf: 'flex-start' }}>
                  <Form.Item label={'الفاكس'} name="fax">
                    <Input
                      name="fax"
                      placeholder={'الفاكس'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'الهاتف الارضي'} name="land_line">
                    <Input
                      name="land_line"
                      placeholder={'الهاتف الارضي'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'خرائط جوجل'} name="google_maps_link">
                    <Input
                      name="google_maps_link"
                      placeholder={'خرائط جوجل'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'اليوتيوب'} name="youtube">
                    <Input
                      name="youtube"
                      placeholder={'اليوتيوب'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'التليجرام'} name="telegram">
                    <Input
                      name="telegram"
                      placeholder={'التليجرام'}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item label={'توقيف الحساب'} name="is_disabled">
                    <Switch
                      defaultChecked={record?.is_disabled}
                      onChange={(val) => setFieldValue('is_disabled', val)}
                    />
                  </Form.Item>

                  <Form.Item label={'تعطيل التقييم'} name="disable_review">
                    <Switch
                      defaultChecked={record?.disable_review}
                      onChange={(val) => setFieldValue('disable_review', val)}
                    />
                  </Form.Item>

                  {(record?.photos || []).length > 0 && (
                    <Form.Item label={'الألبومات'}>
                      <PhotosRow>
                        {record?.photos?.map(
                          (
                            photo: { id: string; filename: string },
                            index: number
                          ) => {
                            return (
                              <PhotosWapper key={index}>
                                <Link
                                  href={`${UPLOAD_URI}/${photo?.filename}`}
                                  target="_blank"
                                >
                                  <ProfilePic
                                    src={`${UPLOAD_URI}/${photo?.filename}`}
                                  />
                                </Link>
                                <RemoveButton
                                  size={18}
                                  color={Colors.red}
                                  onClick={() => {
                                    handleRemovePhoto(index)
                                  }}
                                />
                              </PhotosWapper>
                            )
                          }
                        )}
                      </PhotosRow>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Edit>
    </>
  )
}

export default EditCompany

export const PhotosWapper = styled.div`
  position: relative;
`

export const Link = styled.a`
  text-decoration: none;
  margin-right: 5px;
`

export const ProfilePic = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`

export const RemoveButton = styled(RiCloseCircleFill)`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 1px;
  &:hover {
    opacity: 0.7;
  }
`

export const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 25px;
  margin-bottom: 20px;
`
