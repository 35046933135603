import * as React from 'react'
import { Create, Form, Input, useForm } from '@pankod/refine'

export const CreateSystemConfigs = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء فيديو'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item required label={'رابط الفيديو'} name="video_url">
          <Input name="video_url" />
        </Form.Item>
      </Form>
    </Create>
  )
}
