import * as React from 'react'
import {
  Edit,
  Form,
  Input,
  ListButton,
  notification,
  Select,
  Switch,
  Typography,
  useForm,
} from '@pankod/refine'
import { Association } from '../Store/types'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
import { ConfigsStore, AccountStore } from '../Store'
const { Option } = Select
const { Text } = Typography

interface Inputs {
  id?: string
  name?: string
  type?: string | number
  show?: boolean | string
}

export const EditCourtDegree = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<Association>()
  const record = queryResult?.data?.data
  const [currentCountry, setCurrentCountry] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  const { setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      name: '',
      type: '',
      show: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}
      if (submittedValues.name && submittedValues.name !== record?.name) {
        inputs.name = submittedValues.name
      }
      if (submittedValues.type && submittedValues.type !== record?.type) {
        inputs.type = +submittedValues.type
      }
      // @ts-ignore
      if (submittedValues.show !== record?.show) {
        inputs.show = submittedValues.show
      }

      if (Object.keys(inputs).length > 0) {
        EditAccountAction(
          'courtDegree',
          record?.id,
          inputs,
          resetForm,
          queryResult?.refetch
        )

        notification.success({
          message: 'نجاح',
          description: 'تم التحديث بنجاح',
        })
      }
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل درجة المحكمة'}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListButton>
              <Text>{'درجات المحاكم'}</Text>
            </ListButton>
          </div>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item required label={'اسم الدرجة'} name="name">
          <Input name="name" onChange={handleChange} />
        </Form.Item>

        <Form.Item label={'نوعية الحساب'} name="type" required>
          <Select
            placeholder={'اختر'}
            onChange={(val) => setFieldValue('type', val)}
          >
            <Option value={0}>{'محامي'}</Option>
            <Option value={currentCountry === 'QA' ? 5 : 1}>
              {currentCountry === 'QA' ? 'مستشار قانوني' : 'شرعي'}
            </Option>
            <Option value={2}>{'محكم'}</Option>
            <Option value={3}>{'خبير'}</Option>
            <Option value={4}>{'شركة'}</Option>
          </Select>
        </Form.Item>

        <Form.Item label={'إظهار'} name="show">
          <Switch
            defaultChecked={record?.show}
            onChange={(val) => setFieldValue('show', val)}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
