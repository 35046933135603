import React, { useState } from 'react'
import { Input, Select, Tooltip } from '@pankod/refine'
import { SearchModule } from '../../Containers/Store/Actions'
import { RiFilterOffLine } from 'react-icons/ri'
import {
  LAWYER_ENUM_ARRAY,
  LAWYER_ENUM_ARRAY_QATAR,
} from '../../Containers/Store/types'
import { ConfigsStore, AccountStore } from '../../Containers/Store'

const { Option } = Select

interface Props {
  path: string
  setSearchResults: (val: any) => void
  searchResults: any[]
  type?: number
  lawyer?: boolean
  lawyerVerified?: boolean
}

export const Search = ({
  path,
  setSearchResults,
  searchResults,
  type,
  lawyer,
  lawyerVerified,
}: Props) => {
  const [searchType, setSearchType] = useState<string>('name')
  const [loading, setLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [accountType, setAccountType] = useState<number>(type || 0)

  const [currentCountry, setCurrentCountry] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  const handleSearch = (value: string) => {
    if (searchType === 'name') {
      SearchModule(
        lawyerVerified
          ? { name: value, type: accountType, is_verified: true }
          : { name: value, type: accountType },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'email') {
      SearchModule(
        lawyerVerified
          ? { email: value.trim(), type: accountType, is_verified: true }
          : { email: value.trim(), type: accountType },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'poster_name') {
      SearchModule(
        { poster_name: value },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'title') {
      SearchModule(
        { title: value },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'user_name') {
      SearchModule(
        { user_name: value },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'specialty') {
      SearchModule(
        { specialty: value.trim() },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }

    if (searchType === 'phone') {
      SearchModule(
        lawyerVerified
          ? { phone: value.trim(), type: accountType, is_verified: true }
          : { phone: value.trim(), type: accountType },
        `${path}/search/result`,
        setSearchResults,
        setLoading
      )
    }
  }

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  return (
    <>
      <Input.Group compact>
        {lawyer && (
          <Select
            placeholder={'محامي'}
            style={{ width: 90 }}
            onChange={(val: number) => setAccountType(val)}
          >
            {(currentCountry === 'QA'
              ? LAWYER_ENUM_ARRAY_QATAR
              : LAWYER_ENUM_ARRAY
            )?.map((item, key) => (
              <Option
                key={key}
                value={key === 1 && currentCountry === 'QA' ? 5 : key}
              >
                {item}
              </Option>
            ))}
          </Select>
        )}
        <Select
          placeholder={'بحث حسب..'}
          style={{ width: 130 }}
          onChange={(val: string) => setSearchType(val)}
        >
          {(path.includes('company') ||
            path.includes('lawyer') ||
            path.includes('admin') ||
            path.includes('contact-us') ||
            path.includes('company')) && (
            <>
              <Option value="name">الاسم</Option>
              <Option value="email">البريد الالكتروني</Option>
            </>
          )}

          {path === 'association' && <Option value="name">الاسم</Option>}
          {path === 'courtDegree' && <Option value="name">الاسم</Option>}

          {path === 'newsletter' && (
            <Option value="email">البريد الالكتروني</Option>
          )}
          {path === 'blog' && (
            <>
              <Option value="poster_name">الاسم</Option>
              <Option value="title">العنوان</Option>
            </>
          )}
          {path === 'announcement' && <Option value="title">العنوان</Option>}
          {path === 'comment' && <Option value="user_name">الاسم</Option>}
          {path === 'specialty' && <Option value="specialty">التخصص</Option>}
          {path === 'lawyer' && <Option value="phone">رقم الهاتف</Option>}
        </Select>
        <Input.Search
          placeholder={'ابحث...'}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) =>
            value ? handleSearch(value) : setSearchResults([])
          }
          allowClear
          style={{ width: 250 }}
          loading={loading}
        />
      </Input.Group>
      {searchResults?.length > 0 && (
        <Tooltip
          placement="top"
          title={<p style={{ marginBottom: -1 }}>{'إلغاء البحث'}</p>}
          style={{ maxWidth: 20 }}
        >
          <RiFilterOffLine
            color="#f40c0c"
            size={30}
            onClick={() => {
              setSearchResults([])
              setSearchValue('')
            }}
            className="icons"
          />
        </Tooltip>
      )}
    </>
  )
}
