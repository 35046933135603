import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  notification,
  RefreshButton,
  Typography,
  Statistic,
} from '@pankod/refine'
import { AccountStore } from '../Store'
import { apiRequest } from '../../Util/api'
import { DailyAllUsers } from './components/DailyAllUsers'
import { DailyBlogs } from './components/DailyBlogs'
import { DailyComments } from './components/DailyComments'
import { DailyCompanies } from './components/DailyCompanies'
import { DailyLawyers } from './components/DailyLawyers'
import { RecentBlogs } from './components/RecentBlogs/RecentBlogs'
import { FaUsers, FaUsersSlash } from 'react-icons/fa'
import { BsBuilding } from 'react-icons/bs'

const DashboardPage = () => {
  const { Text } = Typography
  // const { data: permissionsData } = usePermissions()
  const [refresh, setRefresh] = useState<boolean>(true)
  // const [refreshOrders, setRefreshOrders] = useState<boolean>(false)
  const [dashboard, setDashboard] = useState<any>()

  useEffect(() => {
    const handleDashboard = async () => {
      try {
        const data = await apiRequest<any>({
          url: 'dashboard',
          method: 'GET',
          headers: {
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        if (data?.data) {
          setDashboard(data)
          setRefresh(false)
        }
      } catch (error: unknown) {
        // @ts-ignore
        // if (error?.message.includes('401')) {
        //   RefreshToken();
        // } else {
        notification.error({
          message: 'خطأ',
          // @ts-ignore
          description: t(error?.message),
        })
        // }
        setRefresh(false)
      }
    }

    refresh && handleDashboard()
  }, [refresh])

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setRefresh(true)
    }, 10000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  const record = dashboard?.data

  const size = window.screen.width
  const subsCustomWidth = size > 1200 ? '20%' : 'auto'
  const customWidth =
    size > 1200
      ? '20%'
      : size <= 1191 && size > 1156
      ? '19.8%'
      : size <= 1156 && size > 1038
      ? '19%'
      : size <= 1038 && size > 986
      ? '18.5%'
      : size <= 892 && size > 750
      ? '19%'
      : 'auto'

  return (
    <Card>
      {/* {(permissionsData?.includes('super_admin') ||
        permissionsData?.includes('view_dashboard_data')) && ( */}
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <RefreshButton
            onClick={() => setRefresh(true)}
            style={{ margin: '10px 10px' }}
          >
            {'تحديث'}
          </RefreshButton>
        </div>
      </>
      {/* )} */}

      <div style={{ margin: '10px 0px' }} />

      <Row
        gutter={[18, 24]}
        wrap
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col style={{ width: customWidth }}>
          <Card style={{ border: 'none', boxShadow: 'grey 0px 0px 2px' }}>
            <Statistic
              title={'حسابات المحامين المؤكدة'}
              value={record?.currentlySubscribedLawyers || 0}
              precision={0}
              valueStyle={{ color: '#50606B', fontSize: 18 }}
              prefix={
                <FaUsers
                  size={25}
                  color="#50606B"
                  style={{ marginRight: 5, paddingTop: 8 }}
                />
              }
            />
          </Card>
        </Col>
        <div style={{ width: 10 }} />

        <Col style={{ width: customWidth }}>
          <Card style={{ border: 'none', boxShadow: 'grey 0px 0px 2px' }}>
            <Statistic
              title={'حسابات الشركات المؤكدة'}
              value={record?.currentlySubscribedCompanies || 0}
              precision={0}
              valueStyle={{ color: '#50606B', fontSize: 18 }}
              prefix={
                <BsBuilding
                  size={25}
                  color="#50606B"
                  style={{ marginRight: 5, paddingTop: 8 }}
                />
              }
            />
          </Card>
        </Col>
        <div style={{ width: 10 }} />

        <Col style={{ width: subsCustomWidth }}>
          <Card style={{ border: 'none', boxShadow: 'grey 0px 0px 2px' }}>
            <Statistic
              title={'الحسابات المحذوفة'}
              value={
                record?.deletedCompanies || 0 + record?.deletedLawyers || 0
              }
              precision={0}
              valueStyle={{ color: '#50606B', fontSize: 18 }}
              prefix={
                <FaUsersSlash
                  size={25}
                  color="#50606B"
                  style={{ marginRight: 5, paddingTop: 10 }}
                />
              }
            />
          </Card>
        </Col>
      </Row>

      <div style={{ height: 10 }} />
      <div style={{ height: 10 }} />
      <div style={{ height: 10 }} />

      <Row gutter={[24, 24]}>
        {/* {(permissionsData?.includes('super_admin') ||
          permissionsData?.includes('view_dashboard_data')) && ( */}
        <>
          <Col lg={8} span={4} sm={8} xs={8}>
            <Card
              bodyStyle={{
                padding: 10,
              }}
              style={{
                border: 'none',
                boxShadow: 'grey 0px 0px 2px',
                borderRadius: 5,
              }}
            >
              <DailyAllUsers record={record} />
            </Card>
          </Col>

          <Col lg={8} span={4} sm={8} xs={8}>
            <Card
              bodyStyle={{
                padding: 10,
              }}
              style={{
                border: 'none',

                boxShadow: 'grey 0px 0px 2px',
                borderRadius: 5,
              }}
            >
              <DailyLawyers record={record} />
            </Card>
          </Col>

          <Col lg={8} span={4} sm={8} xs={8}>
            <Card
              bodyStyle={{
                padding: 10,
              }}
              style={{
                boxShadow: 'grey 0px 0px 2px',
                border: 'none',

                borderRadius: 5,
              }}
            >
              <DailyCompanies record={record} />
            </Card>
          </Col>

          <Col lg={8} span={4} sm={8} xs={8}>
            <Card
              bodyStyle={{
                padding: 10,
              }}
              style={{
                boxShadow: 'grey 0px 0px 2px',
                borderRadius: 5,
              }}
            >
              <DailyBlogs record={record} />
            </Card>
          </Col>

          <Col lg={8} span={4} sm={8} xs={8}>
            <Card
              bodyStyle={{
                padding: 10,
              }}
              style={{
                boxShadow: 'grey 0px 0px 2px',
                border: 'none',
                borderRadius: 5,
              }}
            >
              <DailyComments record={record} />
            </Card>
          </Col>
        </>
        {/* )} */}

        {/* {(permissionsData?.includes('super_admin') ||
          permissionsData?.includes('view_orders')) && ( */}
        <Col xl={24} lg={24} span={44} sm={24} xs={24}>
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text strong>{'المجلات الأخيرة'}</Text>
                {/* <RefreshButton onClick={() => setRefreshOrders(true)}>
                  تحديث
                </RefreshButton> */}
              </div>
            }
          >
            <RecentBlogs />
          </Card>
        </Col>
        {/* )} */}
      </Row>
    </Card>
  )
}

export default DashboardPage
