import React from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  useNavigation,
  BooleanField,
  useGetIdentity,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'

const { useBreakpoint } = Grid

function ShowNewsLetter() {
  const screens = useBreakpoint()
  const { data: user } = useGetIdentity()
  const { Title, Text } = Typography
  const { edit } = useNavigation()
  const { queryResult } = useShow({})
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'مجلتنا'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/newsletter"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'مجلتنا'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل المجلة'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'مجلتنا'}</Text>
              </ListButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="المجلة"
                  type="edit"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('newsletter', record.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        {record?.email && (
          <>
            <Title level={5}>{'البريد الالكتروني'}</Title>
            <Text>{record?.email}</Text>
          </>
        )}

        {/* {record?.updated_at && (
          <>
            <Title level={5}>{'تم التعديل في'}</Title>
            <Text>{dayjs(record?.updated_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </>
        )} */}

        <Title level={5}>{'مشترك'}</Title>
        <BooleanField value={record?.subscribed} />

        <Title level={5}>{'أنشأ في'}</Title>
        <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
      </Show>
    </>
  )
}

export default ShowNewsLetter
