import { TYPES_AR_ENUM_INDEX } from '../Containers/Store/types'

export function arraymove(arr: any[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export function lawyerTypeByIndex(index: number) {
  if (index >= 0) {
    const typeValues = Object.values(TYPES_AR_ENUM_INDEX)
    let typeValue = typeValues[index]

    if (typeValue === 'مستشار_قانوني') {
      typeValue = 'مستشار قانوني'
    }
    return typeValue
  }
  return ''
}
