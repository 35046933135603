import React from 'react'
import { Modal, Form, Select, notification } from '@pankod/refine'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { apiRequest } from '../../Util/api'
import { AccountStore, ConfigsStore } from '../../Containers/Store'
import { ADMIN_ENUM_ARRAY } from '../../Containers/Store/types'

interface Props {
  visible: any
  setVisible: (val: any) => void
  redirect?: string
  info?: any
  refresh?: () => void
  modalType?: string
}

interface Inputs {
  id?: string
  role?: number
}

const RolesModal = ({ visible, setVisible, refresh, modalType }: Props) => {
  const { setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: visible?.id,
      role: 1,
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        role: submittedValues.role,
      }

      try {
        await apiRequest<boolean>({
          url: `/admin/${visible?.id}`,
          method: 'PUT',
          data: inputs,
          headers: {
            device_uuid: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        setVisible('')
        refresh && refresh()
        resetForm()
        notification.success({
          message: 'نجاح',
          description: 'تم التحديث بنجاح',
        })
      } catch (error: any) {
        // if (error?.message.includes('401')) {
        //   RefreshToken();
        // } else {
        notification.error({
          message: 'خطأ',
          description: error?.message,
        })
      }
    },
  })

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={700}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => {
        Swal.fire({
          title: 'هل أنت متأكد أنك تريد الاستمرار؟',
          showCancelButton: true,
          confirmButtonColor: '#db2828',
          confirmButtonText: 'إرسال',
          cancelButtonText: 'إلغاء',
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmit()
          }
        })
      }}
    >
      <Form layout="vertical">
        <Form.Item label={'الدور'} required>
          <Select
            placeholder={'اختر دوراً...'}
            options={[
              { label: 'مدير النظام', value: 0 },
              { label: 'مشرف', value: 1 },
              { label: 'مدخل بيانات', value: 2 },
            ]}
            onChange={(val) => setFieldValue('role', val)}
            defaultValue={ADMIN_ENUM_ARRAY[+visible?.role]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RolesModal
