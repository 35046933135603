import {
  BooleanField,
  CreateButton,
  HttpError,
  List,
  Table,
  TextField,
  useGetIdentity,
  useNavigation,
  useTable,
} from '@pankod/refine'
import React from 'react'
import dayjs from 'dayjs'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'

const SearchConfigsList = () => {
  const { show, edit } = useNavigation()
  // const [searchResults, setSearchResults] = useState<any>([])
  const { data: user } = useGetIdentity()

  const { tableProps, tableQueryResult } = useTable<any, HttpError>({
    resource: 'search-configs',
    syncWithLocation: true,
  })

  // if (searchResults?.length > 0) {
  //   tableProps.dataSource = searchResults
  // }

  return (
    <List
      title="إعدادات البحث"
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <Search
              path="search-configs"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            /> */}
            {user?.role !== 2 && (
              <CreateButton style={{ marginRight: 10 }}>
                إنشاء إعدادات البحث
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      <Table
        {...tableProps}
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              show('search-configs', record.id)
            },
          }
        }}
      >
        <Table.Column
          dataIndex={['country', 'country']}
          title="الدولة"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={['created_at']}
          title="تاريخ الإنشاء"
          render={(value) => (
            <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
          )}
        />

        <Table.Column
          dataIndex={['deleted']}
          title="محذوف"
          render={(value) => <BooleanField value={value} />}
        />

        {user?.role !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar="النظام"
                  type="delete"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('search-configs', record?.id)
                  }
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `search-configs/${record?.id}`,
                      tableQueryResult?.refetch,
                      false,
                      undefined,
                      record?.deleted
                    )
                  }
                />
              )
            }}
          />
        )}
      </Table>
    </List>
  )
}

export default SearchConfigsList
