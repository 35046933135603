import React from 'react'
import { Modal, Form, Input, notification } from '@pankod/refine'
import { useFormik } from 'formik'
import { apiRequest } from '../../Util/api'
import { AccountStore, ConfigsStore } from '../../Containers/Store'

interface Props {
  visible: any
  setVisible: (val: any) => void
  redirect?: string
  info?: any
  refresh?: (val: boolean) => void
  modalType?: string
}

interface Inputs {
  oldPassword?: string
  password?: string
}

const ChangePasswordModal = ({ visible, setVisible, modalType }: Props) => {
  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        password: submittedValues.password,
      }
      if (submittedValues.oldPassword) {
        inputs.oldPassword = submittedValues.oldPassword
      }

      const url = '/admin/update-password'

      try {
        const data = await apiRequest<boolean>({
          url,
          method: 'POST',
          data: inputs,
          headers: {
            device: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })

        if (data?.data) {
          setVisible('')
          notification.success({
            message: 'نجاح',
            description: 'تم التحديث بنجاح',
          })
        }
      } catch (error: any) {
        setVisible('')
        notification.error({
          message: 'Error',
          description: error?.message,
        })
      }
    },
  })

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'أرسل'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        {!modalType && (
          <Form.Item
            label={'كلمة السر القديمة'}
            rules={[
              {
                validator: async (_, oldPassword) => {
                  if (oldPassword.length < 8) {
                    return Promise.reject(new Error('يجب أن تحتوي على 8 خانات'))
                  }
                },
              },
            ]}
          >
            <Input
              type="password"
              name={'oldPassword'}
              onChange={handleChange}
              placeholder={'كلمة السر القديمة'}
            />
          </Form.Item>
        )}

        <Form.Item
          label={'كلمة السر الجديدة'}
          name="password"
          rules={[
            {
              validator: async (_, password) => {
                if (password.length < 8) {
                  return Promise.reject(new Error('يجب أن تحتوي على 8 خانات'))
                }
              },
            },
          ]}
        >
          <Input
            type={'password'}
            name={'password'}
            onChange={handleChange}
            placeholder={'كلمة السر الجديدة'}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePasswordModal
