import React from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { City } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'

const CityList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const cities = useTable<City, HttpError>({
    resource: 'city',
    syncWithLocation: true,
    // onSearch: (params) => {
    //   const filters: CrudFilters = [];
    //   const { q, createdAt } = params;

    //   filters.push({
    //     field: 'filter_like',
    //     operator: 'eq',
    //     value: q,
    //   });

    //   filters.push(
    //     {
    //       field: 'createdAt_gte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[0].startOf('day'.toISOString()
    //         : undefined,
    //     },
    //     {
    //       field: 'createdAt_lte',
    //       operator: 'eq',
    //       value: createdAt
    //         ? createdAt[1].endOf('day'.toISOString()
    //         : undefined,
    //     }
    //   );

    //   return filters;
    // },
  })

  return (
    <>
      {/* <Row gutter={[16, 16]}> */}
      {/* <Col xl={18} xs={24}> */}
      <List
        // @ts-ignore
        title={`${'المدن'} (${cities?.tableQueryResult?.data?.total || 0})`}
        pageHeaderProps={{
          extra: user?.role !== 2 && <CreateButton>إنشاء مدينة</CreateButton>,
        }}
      >
        <Table
          {...cities?.tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('city', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex={['country', 'country']}
            title={'الدولة'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'city'}
            title={'المدينة'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="show"
            title={'إظهار'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الدولة"
                    type="edit"
                    edit
                    record={record}
                    onClickEdit={() => edit('city', record.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
      {/* </Col> */}
      {/* </Row> */}
    </>
  )
}

export default CityList
