import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  CreateButton,
  useGetIdentity,
  BooleanField,
} from '@pankod/refine'
import { IAccount } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'
import dayjs from 'dayjs'
import { Search } from '../../Components/Search'
import { lawyerTypeByIndex } from '../../Util/Helpers'

const SpecialtyList = () => {
  const { data: user } = useGetIdentity()
  const [searchResults, setSearchResults] = useState<any>([])
  const { show, edit } = useNavigation()

  const { tableProps, tableQueryResult } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
  })

  return (
    <List
      title={`${'التخصصات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="specialty"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {user?.role !== 2 && (
              <CreateButton style={{ marginRight: 10 }}>
                إنشاء تخصص{' '}
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('specialty', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="specialty"
            title={'التخصص'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="type"
            title={'نوع التخصص'}
            align="center"
            render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'أنشأ في'}
            render={(value) => (
              <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
            )}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="التخصص"
                    type="delete"
                    edit
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `specialty/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => edit('specialty', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('specialty', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="specialty"
            title={'التخصص'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="type"
            title={'نوع التخصص'}
            align="center"
            render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
          />
          <Table.Column
            dataIndex="created_at"
            title={'أنشأ في'}
            render={(value) => (
              <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
            )}
          />
          <Table.Column
            dataIndex="show"
            title={'إظهار'}
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="التخصص"
                    type="edit"
                    edit
                    showOrHide
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `specialty/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        { show: !record?.show },
                        record?.show
                      )
                    }
                    onClickEdit={() => edit('specialty', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      )}
    </List>
  )
}

export default SpecialtyList
