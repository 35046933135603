import React, { useEffect, useState } from 'react'
import {
  Show,
  useShow,
  Typography,
  Icons,
  Space,
  Avatar,
  Card,
  Row,
  Col,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  useNavigation,
  useGetIdentity,
  Table,
  TextField,
  List,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { UPLOAD_URI } from '../../Configs'
import { Research, TeamMember } from '../Store/types'
import { GrMapLocation, GrStatusInfo } from 'react-icons/gr'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import {
  DeleteRecordAction,
  EditAccountAction,
  FindTeamMembers,
  SwitchAccount,
  VerifyLawyerAction,
} from '../Store/Actions'
import { Link, PhotosWapper, ProfilePic } from './EditCompany'
import styled from 'styled-components'
import { SwitchAccountInputs } from '../Lawyer/LawyersListing'
import { FiMapPin } from 'react-icons/fi'

const { useBreakpoint } = Grid

function ShowCompany() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const [teamMembers, setTeamMembers] = useState<any[]>([])

  const { queryResult } = useShow({})

  const { data, isLoading, refetch } = queryResult
  const record = data?.data

  const handleSwitchToAccount = (lawyerId: string) => {
    const inputs: SwitchAccountInputs = {
      token: user?.token,
      company_id: lawyerId,
    }
    SwitchAccount(inputs)
  }

  useEffect(() => {
    if (record?.id) {
      FindTeamMembers(record?.id, setTeamMembers)
    }
  }, [record?.id])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'الشركة'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="/company" style={{ fontWeight: 700, color: Colors.seaBlue }}>
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'الشركة'}</h4>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space
              direction="vertical"
              style={{ width: '100%', height: '100%' }}
            >
              <Space
                direction="vertical"
                style={{ textAlign: 'center', width: '100%' }}
              >
                <Avatar
                  size={120}
                  src={
                    record?.logo?.filename
                      ? `${UPLOAD_URI}/${record?.logo?.filename}`
                      : noPhoto
                  }
                />
                <Typography.Title level={3}>{record?.name}</Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {record?.authorized_signatory && (
                  <Typography.Text>
                    <Icons.UserOutlined /> {record?.authorized_signatory}
                  </Typography.Text>
                )}
                {record?.mobile_phone && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icons.PhoneOutlined />
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.mobile_phone}</Text>
                    </div>
                  </div>
                )}
                {record?.email && (
                  <Typography.Text>
                    <Icons.MailOutlined /> {record?.email}
                  </Typography.Text>
                )}
                {record?.city?.city && (
                  <Typography.Text>
                    <GrMapLocation size={18} style={{ marginLeft: 4 }} />
                    {record?.city?.country?.country} / {record?.city?.city}
                  </Typography.Text>
                )}
                {record?.address && (
                  <Typography.Text>
                    <FiMapPin /> {record?.address}
                  </Typography.Text>
                )}
                <Typography.Text>
                  <GrStatusInfo
                    size={14}
                    style={{ marginBottom: -3, marginRight: 4, marginLeft: 4 }}
                  />
                  {record?.is_disabled ? 'غير مفعل' : 'فعال'}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <Show
            isLoading={isLoading}
            title={'تفاصيل الشركة'}
            pageHeaderProps={{
              extra: (
                <Space>
                  <RefreshButton>
                    <Text>{'تحديث'}</Text>
                  </RefreshButton>
                  <ListButton>
                    <Text>{'الشركات'}</Text>
                  </ListButton>
                  {user?.role !== 2 && (
                    <Actions
                      name_ar="الشركة"
                      type="delete"
                      edit
                      switchToAccount={record?.email?.includes(
                        'he_doesnt_have_email'
                      )}
                      record={record}
                      verifyAccount={record?.is_disabled}
                      onClickDelete={() =>
                        DeleteRecordAction(
                          `company/${record?.id}`,
                          refetch,
                          true,
                          undefined,
                          record?.deleted
                        )
                      }
                      onClickEdit={() =>
                        record?.id && edit('company', record?.id)
                      }
                      onClickSwitch={() =>
                        record?.id && handleSwitchToAccount(record?.id)
                      }
                      onClickVerifyAccount={() => {
                        if (record?.stand_alone_signup) {
                          VerifyLawyerAction('company', record?.id, refetch)
                        } else {
                          EditAccountAction(
                            'company',
                            record?.id,
                            { is_disabled: false },
                            undefined,
                            refetch
                          )
                        }
                      }}
                    />
                  )}
                </Space>
              ),
            }}
          >
            <div
              className="detailsWrapper"
              style={{ justifyContent: screens.md ? '' : 'space-between' }}
            >
              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.company_type && (
                  <>
                    <Title level={5}>{'نوع الشركة'}</Title>
                    <Text>{record?.company_type}</Text>
                  </>
                )}

                {record?.company_nature && (
                  <>
                    <Title level={5}>{'طبيعة الشركة'}</Title>
                    <Text>{record?.company_nature}</Text>
                  </>
                )}

                {record?.commercial_name && (
                  <>
                    <Title level={5}>{'الاسم التجاري'}</Title>
                    <Text>{record?.commercial_name}</Text>
                  </>
                )}

                {record?.membership?.name && (
                  <>
                    <Title level={5}>{'نوع الاشتراك'}</Title>
                    <Text>{record?.membership?.name}</Text>
                  </>
                )}

                {record?.work_phone && (
                  <>
                    <Title level={5}>{'هاتف العمل'}</Title>
                    <Text>{record?.work_phone}</Text>
                  </>
                )}

                {record?.fax_phone && (
                  <>
                    <Title level={5}>{'رقم الفاكس'}</Title>
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.fax_phone}</Text>
                    </div>
                  </>
                )}

                {record?.hours_of_operation && (
                  <>
                    <Title level={5}>{'ساعات العمل'}</Title>
                    <Text>{record?.hours_of_operation}</Text>
                  </>
                )}

                {record?.street_name && (
                  <>
                    <Title level={5}>{'اسم الشارع'}</Title>
                    <Text>{record?.street_name}</Text>
                  </>
                )}

                {record?.whats_app_phone && (
                  <>
                    <Title level={5}>{'رقم الواتس اب'}</Title>
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.whats_app_phone}</Text>
                    </div>
                  </>
                )}

                <Title level={5}>{'التقييم'}</Title>
                <Text>{record?.average_rate}</Text>

                <Title level={5}>{'عدد التقييمات'}</Title>
                <Text>{record?.rate_count}</Text>

                <Title level={5}>{'العنوان'}</Title>
                <Text>{record?.address || 'غير متوفر'}</Text>

                {record?.date_of_birth && (
                  <>
                    <Title level={5}>{'تاريخ الولادة'}</Title>
                    <Text>
                      {dayjs(record?.date_of_birth).format('YYYY-MM-DD')}
                    </Text>
                  </>
                )}
                {(record?.years_of_practice === 0 ||
                  record?.years_of_practice > 0) && (
                  <div style={{ marginTop: 10 }}>
                    <Title level={5}>{'سنوات الخبرة'}</Title>
                    <Text>{record?.years_of_practice}</Text>
                  </div>
                )}

                {record?.fax && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'الفاكس'}
                    </Title>
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.fax}</Text>
                    </div>
                  </>
                )}

                {record?.land_line && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'الهاتف الارضي'}
                    </Title>
                    <div style={{ direction: 'ltr', textAlign: 'end' }}>
                      <Text>{record?.land_line}</Text>
                    </div>
                  </>
                )}

                {record?.google_maps_link && (
                  <>
                    <Title level={5} style={{ marginTop: 5, marginBottom: -2 }}>
                      {'خرائط جوجل'}
                    </Title>
                    <a
                      href={record?.google_maps_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      رابط
                    </a>
                  </>
                )}

                {record?.youtube && (
                  <>
                    <Title level={5} style={{ marginTop: 5, marginBottom: -2 }}>
                      {'اليوتيوب'}
                    </Title>
                    <a href={record?.youtube} target="_blank" rel="noreferrer">
                      رابط
                    </a>
                  </>
                )}

                {record?.telegram && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'التليجرام'}
                    </Title>
                    <div style={{ direction: 'ltr', textAlign: 'end' }}>
                      <Text>{record?.telegram}</Text>
                    </div>
                  </>
                )}
              </div>

              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.biography && (
                  <div style={{ width: 300 }}>
                    <Title level={5}>{'السيرة الذاتية'}</Title>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                      {record?.biography}
                    </Text>
                  </div>
                )}

                {record?.goals?.length > 0 && (
                  <>
                    <Title level={5}>{'غايات الشركة'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.goals?.map(
                        (item: { specialty: string }, key: number) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {`${key + 1}- `}
                              <Text style={{ marginRight: 3 }}>
                                {item?.specialty}
                              </Text>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                )}

                {record?.specialties?.length > 0 && (
                  <>
                    <Title level={5}>{'التخصصات'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.specialties?.map(
                        (item: { specialty: string }, key: number) => {
                          return (
                            <>
                              {`${key + 1}- `} <Text>{item?.specialty}</Text>
                            </>
                          )
                        }
                      )}
                    </div>
                  </>
                )}

                {record?.research?.length > 0 && (
                  <>
                    <Title level={5}>{'الأبحاث'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.research?.map((item: Research, key: number) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {`${key + 1}- `}
                            <a
                              href={item.url}
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              <Text
                                style={{
                                  color: item.url
                                    ? Colors.seaBlue
                                    : Colors.darkGray,
                                  marginRight: 3,
                                }}
                              >
                                {item?.label}
                              </Text>
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                {record?.clients?.length > 0 && (
                  <>
                    <Title level={5}>{'العملاء'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.clients?.map((item: string, key: number) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {`${key + 1}- `}
                            <Text style={{ marginRight: 3 }}>{item}</Text>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                {record?.activities?.length > 0 && (
                  <>
                    <Title level={5}>{'النشاطات'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.activities?.map((item: string, key: number) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {`${key + 1}- `}
                            <Text style={{ marginRight: 3 }}>{item}</Text>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                {record?.team?.length > 0 && (
                  <>
                    <Title level={5}>{'الخبرات العملية'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.team?.map((team: TeamMember) => {
                        return (
                          <>
                            <img
                              className="teamMemberImage"
                              src={
                                team?.photo
                                  ? `${UPLOAD_URI}/image/${team?.photo?.filename}`
                                  : noPhoto
                              }
                              alt="team"
                            />
                            <Text>
                              {` ${team?.role} `} {` ${team?.name} `}
                            </Text>
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
                {record?.photos?.length > 0 && (
                  <>
                    <Title level={5}>{'الألبومات'}</Title>
                    <div className="arrayFieldsWrapper">
                      <PhotosRow>
                        {record?.photos?.map(
                          (
                            photo: { id: string; filename: string },
                            index: number
                          ) => {
                            return (
                              <PhotosWapper key={index}>
                                <Link
                                  href={`${UPLOAD_URI}/${photo?.filename}`}
                                  target="_blank"
                                >
                                  <ProfilePic
                                    src={`${UPLOAD_URI}/${photo?.filename}`}
                                  />
                                </Link>
                              </PhotosWapper>
                            )
                          }
                        )}
                      </PhotosRow>
                    </div>
                  </>
                )}
              </div>

              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.date_of_establishment && (
                  <>
                    <Title level={5}>{'تاريخ التأسيس'}</Title>
                    <Text>
                      {dayjs(record?.date_of_establishment).format(
                        'YYYY-MM-DD, HH:mm'
                      )}
                    </Text>
                  </>
                )}

                {record?.membership_expiry && (
                  <>
                    <Title level={5}>{'موعد انتهاء الاشتراك'}</Title>
                    <Text>
                      {dayjs(record?.membership_expiry).format(
                        'YYYY-MM-DD, HH:mm'
                      )}
                    </Text>
                  </>
                )}

                {/* {record?.updated_at && (
                  <>
                    <Title level={5}>{'تم تعديل الحساب في'}</Title>
                    <Text>
                      {dayjs(record?.updated_at).format('YYYY-MM-DD, HH:mm')}
                    </Text>
                  </>
                )} */}

                <Title level={5}>{'أنشأ في'}</Title>
                <Text>
                  {dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}
                </Text>

                <Title level={5}>{'تم تأكيد البريد الإلكتروني'}</Title>
                <BooleanField value={record?.is_email_confirmed} />

                {/* <Title level={5}>{'حساب مؤكد'}</Title>
                <BooleanField value={record?.is_shari_verified} /> */}

                <Title level={5}>{'تقييم معطل'}</Title>
                <BooleanField value={record?.disable_review} />

                <Title level={5}>{'تسجيل مستقل'}</Title>
                <BooleanField value={record?.stand_alone_signup} />

                <Title level={5}>{'محذوف'}</Title>
                <BooleanField value={record?.deleted} />
              </div>
            </div>
          </Show>
        </Col>
      </Row>
      <div style={{ marginTop: 20 }} />
      {(teamMembers || [])?.length > 0 && (
        <List
          title={`${'فريق العمل'}`}
          pageHeaderProps={{
            extra: <></>,
          }}
        >
          <Table dataSource={teamMembers} rowKey="id">
            <Table.Column
              dataIndex={'name'}
              title={'الاسم'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={'role'}
              title={'الوظيفة'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record: any): any => {
                return (
                  <>
                    {record?.photo?.filename ? (
                      <a
                        href={`${UPLOAD_URI}/${record?.photo?.filename}`}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <Text
                          style={{
                            color: Colors.seaBlue,
                          }}
                        >
                          أظهر الصورة
                        </Text>
                      </a>
                    ) : (
                      <></>
                    )}
                  </>
                )
              }}
            />
          </Table>
        </List>
      )}
    </>
  )
}

export default ShowCompany

export const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
