import * as React from 'react'
import {
  Create,
  Form,
  Input,
  Switch,
  useForm,
  useGetIdentity,
} from '@pankod/refine'

export const CreateCountry = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const { data: user } = useGetIdentity()

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء دولة'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'الدولة'}
          name="country"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="country" disabled={user?.role !== 0} />
        </Form.Item>

        <Form.Item
          label={'كود الدولة'}
          name="isoCode"
          required
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="isoCode" disabled={user?.role !== 0} />
        </Form.Item>

        <Form.Item label={'العملة'} name="currency">
          <Input name="currency" disabled={user?.role !== 0} />
        </Form.Item>

        <Form.Item
          required
          label={'رقم الدعم الفني'}
          name="whats_app_phone"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input
            name="whats_app_phone"
            placeholder="Ex: +962 7777 77777"
            disabled={user?.role !== 0}
          />
        </Form.Item>

        <Form.Item label={'محذوف'} name="disabled">
          <Switch disabled={user?.role !== 0} />
        </Form.Item>
      </Form>
    </Create>
  )
}
