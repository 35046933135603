import React from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  CreateButton,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'
import { DeleteRecordAction } from '../Store/Actions'

const SystemConfigsList = () => {
  const { edit } = useNavigation()
  const { data: user } = useGetIdentity()

  const { tableProps, tableQueryResult } = useTable({
    syncWithLocation: true,
  })

  return (
    <>
      <List
        title={'إعدادات النظام'}
        pageHeaderProps={{
          extra: (
            <>
              {user?.role !== 2 && tableProps?.dataSource?.length === 0 && (
                <CreateButton>إنشاء فيديو</CreateButton>
              )}
            </>
          ),
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="video_url"
            title={'رابط الفيديو'}
            render={(value) => (
              <a href={value} target="_blank" rel="noreferrer">
                <TextField value={value} style={{ color: 'blue' }} />
              </a>
            )}
            key={'id'}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الفيديو"
                    type="delete"
                    edit
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `system-configs/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    record={record}
                    onClickEdit={() => edit('system-configs', record?.id)}
                  />
                )
              }}
            />
          )}
        </Table>
      </List>
    </>
  )
}

export default SystemConfigsList
