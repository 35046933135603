import React, { useEffect, useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  BooleanField,
  useGetIdentity,
  Select,
  Switch,
} from '@pankod/refine'
import { Actions } from '../../../Components/ActionButtons'
import { ConfigsStore } from '../../Store'
import {
  SwitchAccount,
  DeleteRecordAction,
  VerifyLawyerAction,
  EditAccountAction,
} from '../../Store/Actions'
import {
  IAccount,
  TYPES_ENUM_ARRAY_QATAR,
  TYPES_ENUM_ARRAY,
} from '../../Store/types'
import { lawyerTypeByIndex } from '../../../Util/Helpers'
import { Search } from '../../../Components/Search'
import { EditMemberShip } from '../../../Components/MemberShip'

export interface SwitchAccountInputs {
  token: string
  lawyer_id?: string
  company_id?: string
}

type Props = {
  currentCountry: string
}

const ListVerifiedLawyers = ({ currentCountry }: Props) => {
  const { data: user } = useGetIdentity()
  const { show, edit } = useNavigation()
  const [sort, setSort] = useState<string>('')
  const [searchResults, setSearchResults] = useState<any>([])

  const [filteredByPhone, setFilteredByPhone] = React.useState<boolean>(true)
  const [filteredByDisabled, setFilteredByDisabled] = React.useState<boolean>()
  const [
    filteredByLawyerType,
    setFilteredByLawyerType,
  ] = React.useState<string>('-1')

  const data = useTable<IAccount, HttpError>({
    resource: 'lawyerConfirmed',
    permanentFilter: [
      {
        field: 'is_disabled',
        operator: 'eq',
        value: filteredByDisabled,
      },
      {
        field: 'is_phone_confirmed',
        operator: 'eq',
        value: filteredByPhone,
      },
      {
        field: 'type',
        operator: 'eq',
        value: filteredByLawyerType === '-1' ? -1 : `"${filteredByLawyerType}"`,
      },
    ],
  })

  if (searchResults?.length > 0) {
    data.tableProps.dataSource = searchResults
  }

  const handleSwitchToAccount = (lawyerId: string) => {
    const inputs: SwitchAccountInputs = {
      token: user?.token,
      lawyer_id: lawyerId,
    }
    SwitchAccount(inputs)
  }

  const getSortValue = async () => {
    const sortValue = await ConfigsStore.get('sort')
    setSort(sortValue || 'created_at')
  }

  useEffect(() => {
    getSortValue()
  }, [data.tableProps.dataSource])

  return (
    <List
      title={`${'المحامون المؤكدون'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="lawyer"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              lawyer
              lawyerVerified
              type={0}
            />

            <div style={{ margin: '0px 10px' }}>
              <p>حساب موقف: </p>

              <Switch
                checked={filteredByDisabled}
                onChange={() => setFilteredByDisabled(!filteredByDisabled)}
                title="حساب موقف"
              />
            </div>
            <div style={{ margin: '0px 10px' }}>
              <p>حساب مؤكد برقم الهاتف: </p>

              <Switch
                checked={filteredByPhone}
                onChange={() => setFilteredByPhone(!filteredByPhone)}
              />
            </div>

            <Select
              style={{ width: 140, color: '#000', margin: '0px 10px' }}
              value={
                filteredByLawyerType === '-1'
                  ? 'جميع أنواع الحسابات'
                  : filteredByLawyerType
              }
              options={[{ label: 'جميع أنواع الحسابات', value: '-1' }]?.concat(
                TYPES_ENUM_ARRAY?.map((type, index) => {
                  if (currentCountry === 'QA' && index === 1) {
                    return {
                      label: TYPES_ENUM_ARRAY_QATAR[4],
                      value: '5',
                    }
                  }
                  return {
                    label: type,
                    value: `${index}`,
                  }
                })
              )}
              onChange={(val: string) => {
                setFilteredByLawyerType(val)
              }}
            />

            <Select
              placeholder={'الترتيب'}
              style={{ width: 120, color: '#000' }}
              value={sort}
              onChange={(val: string) => {
                ConfigsStore.set('sort', val)
                setTimeout(() => {
                  data.tableQueryResult.refetch()
                }, 400)
              }}
            >
              <Select.Option value="created_at">
                {'تاريخ الإنشاء'}
              </Select.Option>
              <Select.Option value="type"> {'نوع الحساب'}</Select.Option>
            </Select>
          </div>
        ),
      }}
    >
      <Table
        {...data.tableProps}
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              show('lawyer', record.id)
            },
          }
        }}
      >
        <Table.Column
          dataIndex="name"
          title={'الاسم'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="type"
          title={'نوع الحساب'}
          align="center"
          render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
        />
        <Table.Column
          dataIndex="phone"
          title={'رقم الهاتف'}
          render={(value) => (
            <div style={{ direction: 'ltr' }}>
              <TextField value={value ? value : 'لا يوجد'} />
            </div>
          )}
        />

        <Table.Column
          dataIndex="email"
          title={'البريد الالكتروني'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['membership', 'name']}
          title={'نوع الاشتراك'}
          align="center"
          render={(value) => <TextField value={value || 'لا يوجد'} />}
        />
        <Table.Column
          dataIndex={'stand_alone_signup'}
          title={'تسجيل مستقل'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex={'is_disabled'}
          title={'حساب موقف'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex={'is_phone_confirmed'}
          title={'حساب مؤكد برقم الهاتف'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        {user?.role !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <div>
                  <Actions
                    name_ar="المحامي"
                    type="delete"
                    edit
                    verifyAccount={record?.is_disabled}
                    switchToAccount={record?.email?.includes(
                      'he_doesnt_have_email'
                    )}
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `lawyer/${record?.id}`,
                        data.tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => edit('lawyer', record?.id)}
                    onClickSwitch={() => handleSwitchToAccount(record?.id)}
                    onClickVerifyAccount={() => {
                      if (record?.stand_alone_signup) {
                        VerifyLawyerAction(
                          'lawyer',
                          record?.id,
                          data.tableQueryResult?.refetch
                        )
                      } else {
                        EditAccountAction(
                          'lawyer',
                          record?.id,
                          { is_disabled: false },
                          undefined,
                          data.tableQueryResult?.refetch
                        )
                      }
                    }}
                  />
                  <EditMemberShip record={record} categoryType="lawyer" />
                </div>
              )
            }}
          />
        )}
      </Table>
    </List>
  )
}

export default ListVerifiedLawyers
