import React, { useState } from 'react'
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  useLogin,
  Checkbox,
} from '@pankod/refine'
import logo from '../../../Assets/Images/darkLogo.png'
import './style.css'
import { ConfigsStore } from '../../Store'
import Colors from '../../../Style/Colors'

const { Title } = Typography
export interface ILoginForm {
  username: string
  password: string
  remember: boolean
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>()
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const { mutate: login } = useLogin<ILoginForm>()

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'تسجيل الدخول إلى حسابك'}
    </Title>
  )
  // rgb(246, 250, 253)
  return (
    <AntdLayout
      style={{
        background:
          'radial-gradient(circle, rgba(246,250,253,1) 0%, rgba(212,233,249,1) 100%)',
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="150"
              alt="logo"
            />
            <Card
              title={CardTitle}
              headStyle={{
                borderBottom: 0,
              }}
              style={{
                boxShadow: 'rgb(193 193 193) 0px 0px 1px 1px',
              }}
            >
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                color={'#6CCEF4'}
                onFinish={(values) => {
                  login(values)
                }}
                requiredMark={false}
                // initialValues={{
                //   remember: false,
                //   username: 'admin',
                //   password: 'admin',
                // }}
              >
                <Form.Item
                  name="username"
                  label={'البريد الإلكتروني'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@sample.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={'كلمة السر'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div
                  style={{
                    marginBottom: '25px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                      }}
                      onClick={() => setRememberMe(!rememberMe)}
                    >
                      {'تذكرني'}
                    </Checkbox>
                  </Form.Item>
                  <a
                    style={{
                      fontSize: '12px',
                    }}
                    href="/forgot-password"
                  >
                    {'هل نسيت كلمة السر؟'}
                  </a>
                </div>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={'#6CCEF4'}
                  style={{ background: Colors.gradientFromLeft }}
                  onClick={() => {
                    if (rememberMe) {
                      ConfigsStore.set('rememberMe', true)
                    } else {
                      sessionStorage.setItem('logoutOnEndSession', 'true')
                    }
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: '#6CCEF4',
                    }}
                  >
                    {'تسجيل الدخول'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
