import React from 'react'
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
} from '@pankod/refine'
import logo from '../../../Assets/Images/darkLogo.png'

import '../ForgotPassword//styles.css'
import Colors from '../../../Style/Colors'
import { ResetPasswordAction } from '../../Store/Actions'

const { Title } = Typography

export const ResetPassword: React.FC = () => {
  const navigateToLogin = () => {
    window.location.replace('/login')
  }

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'تغيير كلمة السر'}
    </Title>
  )
  return (
    <AntdLayout
      style={{
        background:
          'radial-gradient(circle, rgba(246,250,253,1) 0%, rgba(212,233,249,1) 100%)',
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="130"
              alt="logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={'#6CCEF4'}
                onFinish={(val) => {
                  ResetPasswordAction(val?.code, navigateToLogin)
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="code"
                  label={'كلمة السر الجديدة'}
                  rules={[{ required: true }]}
                >
                  <Input type="password" size="large" placeholder="●●●●●" />
                </Form.Item>

                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={'#6CCEF4'}
                  style={{ background: Colors.gradientFromLeft }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: '#6CCEF4',
                    }}
                  >
                    {'أرسل'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
