import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  BooleanField,
  useTable,
  CreateButton,
  HttpError,
  useNavigation,
  useGetIdentity,
} from '@pankod/refine'
import { Association } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import { Search } from '../../Components/Search'
import { DeleteRecordAction } from '../Store/Actions'
import { lawyerTypeByIndex } from '../../Util/Helpers'

const CourtDegreeList = () => {
  const { show, edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps, tableQueryResult } = useTable<Association, HttpError>({
    resource: 'courtDegree',
    syncWithLocation: true,
  })

  if (searchResults.length > 0) {
    tableProps.dataSource = searchResults
  }

  return (
    <List
      title={`${'درجات المحاكم'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="courtDegree"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
            />
            {user?.role !== 2 && (
              <CreateButton style={{ marginRight: 10 }}>
                {'إنشاء درجة محكمة'}{' '}
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      <Table
        {...tableProps}
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              show('courtDegree', record.id)
            },
          }
        }}
      >
        <Table.Column
          dataIndex={'name'}
          title={'اسم الدرجة'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'type'}
          title={'النوعية'}
          render={(value) => <TextField value={lawyerTypeByIndex(+value)} />}
        />

        <Table.Column
          dataIndex="show"
          title={'محذوف'}
          align="center"
          render={(value) => <BooleanField value={!value} />}
        />
        {user?.roles !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            fixed="right"
            key="actions"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar="درجة المحكمة"
                  edit
                  type="show"
                  record={record}
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `courtDegree/${record?.id}`,
                      tableQueryResult?.refetch,
                      false,
                      { show: !record?.show },
                      record?.show
                    )
                  }
                  onClickEdit={() => edit('courtDegree', record.id)}
                />
              )
            }}
          />
        )}
      </Table>
    </List>
  )
}

export default CourtDegreeList
