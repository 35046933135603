import { Dropdown, Icons, Menu, Tooltip } from '@pankod/refine'
import React from 'react'
import { FcInfo } from 'react-icons/fc'
import { HiOutlineSwitchHorizontal } from 'react-icons/hi'

type ActionProps = {
  type: string
  edit?: boolean
  recordName?: string
  name_ar?: string
  record?: any
  showOrHide?: boolean
  deleteRecord?: boolean
  role?: boolean
  switchToAccount?: boolean
  verifyAccount?: boolean
  showApproveButton?: boolean
  showRejectButton?: boolean
  showRejectDocumentButton?: boolean
  archived?: boolean
  onClickApprove?: () => void
  onClickReject?: () => void
  onClickDelete?: () => void
  onClickEdit?: () => void
  onClickRole?: () => void
  onClickSwitch?: () => void
  onClickVerifyAccount?: () => void
  onClickRejectDocument?: () => void
}

export const Actions: React.FC<ActionProps> = ({
  name_ar,
  // recordName,
  type,
  deleteRecord,
  record,
  edit,
  role,
  switchToAccount,
  verifyAccount,
  showApproveButton,
  showRejectButton,
  archived,
  showOrHide,
  showRejectDocumentButton,
  onClickRejectDocument,
  onClickApprove,
  onClickReject,
  onClickDelete,
  onClickEdit,
  onClickRole,
  onClickSwitch,
  onClickVerifyAccount,
}) => {
  const moreMenu = (record: any) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      {(type === 'approveReject' || type === 'specialty') && (
        <>
          {!showApproveButton && (
            <Menu.Item
              key="accept"
              style={{
                fontSize: 15,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
              icon={
                <Icons.CheckCircleOutlined
                  style={{
                    color: '#52c41a',
                    fontSize: 17,
                    fontWeight: 500,
                  }}
                />
              }
              onClick={onClickApprove}
            >
              {`اعتماد ${name_ar}`}
            </Menu.Item>
          )}
          {type !== 'specialty' && !showRejectButton && (
            <Menu.Item
              key="reject"
              style={{
                fontSize: 15,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
              icon={
                <Icons.CloseCircleOutlined
                  style={{
                    color: '#EE2A1E',
                    fontSize: 17,
                  }}
                />
              }
              onClick={onClickReject}
            >
              {`رفض ${name_ar}`}
            </Menu.Item>
          )}
          {showRejectDocumentButton && (
            <Menu.Item
              key="reject"
              style={{
                fontSize: 15,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
              icon={
                <Icons.CloseCircleOutlined
                  style={{
                    color: '#EE2A1E',
                    fontSize: 17,
                  }}
                />
              }
              onClick={onClickRejectDocument}
            >
              {`رفض ${name_ar}`}
            </Menu.Item>
          )}
        </>
      )}
      {verifyAccount && (
        <Menu.Item
          key="switch"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          onClick={onClickVerifyAccount}
          icon={
            <Icons.CheckCircleOutlined
              style={{
                color: '#52c41a',
                fontSize: 17,
                fontWeight: 500,
              }}
            />
          }
        >
          {'تفعيل الحساب'}
        </Menu.Item>
      )}
      {(type === 'delete' || deleteRecord) && (
        <Menu.Item
          key="delete"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          icon={
            <FcInfo
              color={'#EE2A1E'}
              size={20}
              style={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          }
          onClick={onClickDelete}
        >
          {record?.deleted || record?.disabled ? 'استرجاع' : 'حذف'}
        </Menu.Item>
      )}
      {type === 'show' && (
        <Menu.Item
          key="delete"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          icon={
            <FcInfo
              color={'#EE2A1E'}
              size={20}
              style={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          }
          onClick={onClickDelete}
        >
          {!record?.show ? 'استرجاع' : 'حذف'}
        </Menu.Item>
      )}

      {type === 'show-inside' && (
        <Menu.Item
          key="delete"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          icon={
            <FcInfo
              color={'#EE2A1E'}
              size={20}
              style={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          }
          onClick={onClickDelete}
        >
          {record?.show ? 'استرجاع' : 'حذف'}
        </Menu.Item>
      )}
      {showOrHide && (
        <Menu.Item
          key="delete"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          icon={
            <FcInfo
              color={'#EE2A1E'}
              size={20}
              style={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          }
          onClick={onClickDelete}
        >
          {record?.show ? 'إخفاء' : 'إظهار'}
        </Menu.Item>
      )}
      {edit && (
        <Menu.Item
          key="edit"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          onClick={onClickEdit}
          icon={<Icons.EditFilled style={{ fontSize: 16 }} />}
        >
          {'تعديل'}
        </Menu.Item>
      )}
      {role && (
        <Menu.Item
          key="role"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          onClick={onClickRole}
          icon={<Icons.EditFilled style={{ fontSize: 16 }} />}
        >
          {'تعديل الدور'}
        </Menu.Item>
      )}

      {switchToAccount && (
        <Menu.Item
          key="switch"
          style={{
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
          }}
          onClick={onClickSwitch}
          icon={
            <HiOutlineSwitchHorizontal
              size={20}
              style={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          }
        >
          {'تسجيل الدخول'}
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Tooltip
      placement="top"
      title={<p style={{ marginBottom: -1 }}>{'المزيد'}</p>}
      style={{ maxWidth: 20 }}
    >
      <Dropdown overlay={moreMenu(record)} trigger={['click']}>
        {type === 'specialty' ? (
          <button
            style={{
              color: 'rgb(82, 146, 202)',
              fontWeight: 'bold',
            }}
            onClick={(e) => e.stopPropagation()}
            className="transparent-button"
          >
            مراجعة
          </button>
        ) : (
          <Icons.MoreOutlined
            style={{ fontSize: 27 }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </Dropdown>
    </Tooltip>
  )
}
