import React from 'react'
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  notification,
  Switch,
  useGetIdentity,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { EditAccountAction } from '../Store/Actions'
const { Text } = Typography

interface Inputs {
  id?: string
  country?: string
  city?: string
  currency?: string
  whats_app_phone?: string
  disabled?: boolean | string
  show?: boolean | string
  isoCode?: string
}

function EditCountry() {
  const { formProps, saveButtonProps, queryResult } = useForm<any>({})
  const record = queryResult?.data?.data
  const { data: user } = useGetIdentity()

  const { setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: record?.id,
      country: '',
      city: '',
      currency: '',
      whats_app_phone: '',
      show: '',
      disabled: '',
      isoCode: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {}
      if (submittedValues.country) {
        inputs.country = submittedValues.country
      }
      if (submittedValues.city) {
        inputs.city = submittedValues.city
      }
      // @ts-ignore
      if (submittedValues.show === false || submittedValues.show) {
        inputs.show = submittedValues.show
      }
      // @ts-ignore
      if (submittedValues.disabled === false || submittedValues.disabled) {
        inputs.disabled = submittedValues.disabled
      }

      if (submittedValues.currency) {
        inputs.currency = submittedValues.currency
      }

      if (submittedValues.whats_app_phone) {
        inputs.whats_app_phone = submittedValues.whats_app_phone
      }

      if (submittedValues.isoCode) {
        inputs.isoCode = submittedValues.isoCode
      }

      EditAccountAction(
        record?.city ? 'city' : 'country',
        record?.id,
        inputs,
        resetForm,
        queryResult?.refetch
      )

      notification.success({
        message: 'نجاح',
        description: 'تم التحديث بنجاح',
      })
    },
  })

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  return (
    <>
      <Edit
        saveButtonProps={buttonProps}
        title={record?.city ? 'تعديل المدينة' : 'تعديل الدولة'}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ListButton>
                <Text>{'الدول'}</Text>
              </ListButton>
            </div>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          {record?.city ? (
            <>
              <Form.Item
                required
                label={'المدينة'}
                name={'city'}
                rules={[
                  {
                    validator: async (_, type) => {
                      if (!type) {
                        return Promise.reject(new Error('مطلوب'))
                      }
                    },
                  },
                ]}
              >
                <Input
                  name="city"
                  onChange={handleChange}
                  disabled={user?.role !== 0}
                />
              </Form.Item>
              <Form.Item label={'إظهار'} name="show">
                <Switch
                  defaultChecked={record?.show}
                  disabled={user?.role !== 0}
                  onChange={(val) => setFieldValue('show', val)}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item required label={'الدولة'} name="country">
                <Input
                  name="country"
                  onChange={handleChange}
                  disabled={user?.role !== 0}
                />
              </Form.Item>

              <Form.Item label={'كود الدولة'} name="isoCode">
                <Input
                  name="isoCode"
                  onChange={handleChange}
                  disabled={user?.role !== 0}
                />
              </Form.Item>

              <Form.Item label={'العملة'} name="currency" required>
                <Input
                  name="currency"
                  onChange={handleChange}
                  disabled={user?.role !== 0}
                />
              </Form.Item>

              <Form.Item
                required
                label={'رقم الدعم الفني'}
                name="whats_app_phone"
              >
                <Input
                  name="whats_app_phone"
                  onChange={handleChange}
                  disabled={user?.role !== 0}
                />
              </Form.Item>

              <Form.Item label={'محذوف'} name="disabled">
                <Switch
                  defaultChecked={record?.disabled}
                  disabled={user?.role !== 0}
                  onChange={(val) => setFieldValue('disabled', val)}
                />
              </Form.Item>

              <Form.Item label={'إظهار'} name="show">
                <Switch
                  defaultChecked={record?.show}
                  disabled={user?.role !== 0}
                  onChange={(val) => setFieldValue('show', val)}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Edit>
    </>
  )
}

export default EditCountry
