import React, { useCallback, useEffect, useState } from 'react'
import { Dropdown, Space } from 'antd'
import {
  Button,
  HttpError,
  Menu,
  Typography,
  useGetIdentity,
  useTable,
} from '@pankod/refine'
import { Country } from '../../Containers/Store/types'
import { AccountStore, ConfigsStore } from '../../Containers/Store'

const CountriesDropdown = () => {
  const { Text } = Typography
  const { data: user } = useGetIdentity()

  const [countries, setCountries] = useState<Country[]>([])
  const [currentCountry, setCurrentCountry] = useState<string>()

  const getCountry = useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    setCountries(account?.countries || [])
    setCurrentCountry(
      country || (account?.countries && account?.countries[0]?.id)
    )
  }, [])

  const { tableProps } = useTable<Country, HttpError>({
    resource: 'country',
    initialPageSize: 200,
  })

  if (countries?.length > 0 && (tableProps.dataSource || [])?.length > 0) {
    if (user?.role !== 0) {
      tableProps.dataSource = countries || []
    }
  }

  useEffect(() => {
    getCountry()
  }, [getCountry])

  return (
    <Dropdown
      placement="bottomRight"
      overlayStyle={{
        flex: 1,
        position: 'absolute',
      }}
      {...tableProps}
      overlay={
        <Menu
          onClick={async (e) => {
            await ConfigsStore.set('country', e.key)
            setTimeout(() => {
              window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
            }, 500)
          }}
        >
          {tableProps?.dataSource &&
            (tableProps?.dataSource || [])?.length > 0 &&
            tableProps?.dataSource?.map((item) => {
              return (
                // !item.disabled &&
                // item.show && (
                <Menu.Item key={item.id}>
                  <Space>
                    <Text ellipsis strong>
                      {item.country}
                    </Text>
                  </Space>
                </Menu.Item>
                // )
              )
            })}

          {currentCountry && <Menu.Divider style={{ margin: '4px 0' }} />}
          {currentCountry &&
            tableProps.dataSource &&
            (tableProps?.dataSource || [])?.length > 0 &&
            tableProps.dataSource?.map((item) => {
              if (item.id === currentCountry) {
                return (
                  <Menu.Item key={item.id} disabled>
                    <Space>
                      <Text ellipsis strong>
                        {item.country} ✓
                      </Text>
                    </Space>
                  </Menu.Item>
                )
              }
              return null
            })}
        </Menu>
      }
    >
      <Button
        type="link"
        onClick={(e) => {
          e.preventDefault()
        }}
      >
        <Space>
          <Text>{'اختيار الدولة'}</Text>
        </Space>
      </Button>
    </Dropdown>
  )
}

export default CountriesDropdown
