import React, { useEffect, useState } from 'react'
import {
  Show,
  useShow,
  Typography,
  Icons,
  Space,
  Avatar,
  Card,
  Row,
  Col,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  useNavigation,
  useGetIdentity,
  List,
  Table,
  TextField,
} from '@pankod/refine'
import noPhoto from '../../Assets/Images/noPhoto.png'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { UPLOAD_URI, UPLOAD_URI_ID } from '../../Configs'
import {
  Association,
  Certificate,
  Research,
  TrainingCourses,
  WorkExperience,
} from '../Store/types'
import { GrMapLocation, GrStatusInfo } from 'react-icons/gr'
import { MdDeleteForever } from 'react-icons/md'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import {
  DeleteRecordAction,
  EditAccountAction,
  FindLawyerCertificates,
  RejectSpecialtyAction,
  SwitchAccount,
  VerifyLawyerAction,
  VerifySpecialtyAction,
} from '../Store/Actions'
import { arraymove, lawyerTypeByIndex } from '../../Util/Helpers'
import _ from 'lodash'
import { SwitchAccountInputs } from './LawyersListing'
import dayjs from 'dayjs'
import { AiOutlineCheck } from 'react-icons/ai'
import SpecialtyPhotos from '../../Components/SpecialtyPhotos/SpecialtyPhotos'
import Swal from 'sweetalert2'
import { AccountStore, ConfigsStore } from '../Store'
const { useBreakpoint } = Grid

function ShowLawyer() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { edit } = useNavigation()
  const { data: user } = useGetIdentity()
  const [certificates, setCertificates] = useState<Certificate[]>([])
  const [specialtyPhotosModal, setSpecialtyPhotosModal] = useState<
    string[] | undefined
  >([])
  const [currentCountry, setCurrentCountry] = React.useState<string>()

  const getCountry = React.useCallback(async () => {
    const country = await ConfigsStore.get('country')
    const account = await AccountStore.get('account')
    const filterCountry = account?.countries?.find(
      (item) => item.id === country
    )

    setCurrentCountry(filterCountry?.isoCode)
  }, [])

  React.useEffect(() => {
    getCountry()
  }, [getCountry])

  const handleViewImage = (value: string[] | undefined) => {
    setSpecialtyPhotosModal(value)
  }

  const closeModal = () => {
    setSpecialtyPhotosModal([])
  }

  const [pendingSpecialties, setPendingSpecialties] = useState<any[]>()

  const { queryResult } = useShow({})

  const { data, isLoading, refetch } = queryResult
  const record = data?.data

  useEffect(() => {
    if (record?.id) {
      FindLawyerCertificates(record?.id, setCertificates)
    }
  }, [record?.id])

  const handleSwitchToAccount = (lawyerId: string) => {
    const inputs: SwitchAccountInputs = {
      token: user?.token,
      lawyer_id: lawyerId,
    }
    SwitchAccount(inputs)
  }

  useEffect(() => {
    if (
      record?.pending_specialties?.length > 0 &&
      record?.specialty_photos?.length > 0
    ) {
      const arr: any = []
      record?.pending_specialties?.filter((item: any) => {
        const obj: any = {}
        if (
          record?.specialty_photos
            ?.map((val: any) => val?.specialty)
            ?.includes(item?.id)
        ) {
          obj.id = item.id
          obj.specialty = item.specialty
          obj.photo = record?.specialty_photos
        } else {
          obj.id = item.id
          obj.specialty = item.specialty
          obj.photo = []
        }
        return arr?.push(obj)
      })

      arr?.push('') // to prevent undefined when using differenceBy
      const unique = _.differenceBy(
        arr,
        record?.verified_specialties,
        (x: any) => x.specialty
      )

      const index = (unique || [])?.findIndex(
        (el: { photo: number[] }) => el?.photo?.length === 0
      )
      const reordered = arraymove(unique, index, unique?.length - 1)

      const removeUndefined = reordered?.filter((item) => item !== undefined)
      setPendingSpecialties(removeUndefined)
    } else {
      setPendingSpecialties(record?.pending_specialties)
    }
  }, [
    record?.pending_specialties,
    record?.specialty_photos,
    record?.verified_specialties,
  ])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'المحامي'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="/lawyer" style={{ fontWeight: 700, color: Colors.seaBlue }}>
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'المحامي'}</h4>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space
              direction="vertical"
              style={{ width: '100%', height: '100%' }}
            >
              <Space
                direction="vertical"
                style={{ textAlign: 'center', width: '100%' }}
              >
                <Avatar
                  size={120}
                  src={
                    record?.photo?.filename
                      ? `${UPLOAD_URI}/${record?.photo?.filename}`
                      : noPhoto
                  }
                />
                <Typography.Title level={3}>{record?.name}</Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Typography.Text>
                  <Icons.UserOutlined />
                  {lawyerTypeByIndex(+record?.type)}
                </Typography.Text>

                {record?.phone && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icons.PhoneOutlined />
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.phone}</Text>
                    </div>
                  </div>
                )}
                {record?.email && (
                  <Typography.Text>
                    <Icons.MailOutlined /> {record?.email}
                  </Typography.Text>
                )}
                {record?.city?.city && (
                  <Typography.Text>
                    <GrMapLocation size={18} style={{ marginLeft: 4 }} />
                    {record?.city?.country?.country} / {record?.city?.city}
                  </Typography.Text>
                )}
                <Typography.Text>
                  <GrStatusInfo
                    size={14}
                    style={{ marginBottom: -3, marginRight: 4, marginLeft: 4 }}
                  />
                  {record?.is_disabled ? 'غير مفعل' : 'فعال'}
                </Typography.Text>

                {record?.gender && (
                  <Typography.Text>
                    <BsGenderAmbiguous size={18} /> {record?.gender}
                  </Typography.Text>
                )}
              </Space>
            </Space>
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <Show
            isLoading={isLoading}
            title={'تفاصيل المحامي'}
            pageHeaderProps={{
              extra: (
                <Space>
                  <RefreshButton>
                    <Text>{'تحديث'}</Text>
                  </RefreshButton>
                  <ListButton>
                    <Text>{'المحامين'}</Text>
                  </ListButton>
                  {user?.role !== 2 && (
                    <Actions
                      name_ar="المحامي"
                      type="delete"
                      edit
                      switchToAccount={record?.email?.includes(
                        'he_doesnt_have_email'
                      )}
                      record={record}
                      verifyAccount={record?.is_disabled}
                      onClickDelete={() =>
                        DeleteRecordAction(
                          `lawyer/${record?.id}`,
                          refetch,
                          true,
                          undefined,
                          record?.deleted
                        )
                      }
                      onClickEdit={() =>
                        record?.id && edit('lawyer', record?.id)
                      }
                      onClickSwitch={() =>
                        record?.id && handleSwitchToAccount(record?.id)
                      }
                      onClickVerifyAccount={() => {
                        if (record?.stand_alone_signup) {
                          VerifyLawyerAction('lawyer', record?.id, refetch)
                        } else {
                          EditAccountAction(
                            'lawyer',
                            record?.id,
                            { is_disabled: false },
                            undefined,
                            refetch
                          )
                        }
                      }}
                    />
                  )}
                </Space>
              ),
            }}
          >
            <div
              className="detailsWrapper"
              style={{ justifyContent: screens.md ? '' : 'space-between' }}
            >
              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.ejazeh_number && (
                  <>
                    <Title level={5}>{'رقم الاجازة'}</Title>
                    <Text>{record?.ejazeh_number}</Text>
                  </>
                )}

                {record?.naqabeh_number && (
                  <>
                    <Title level={5}>{'رقم النقابة'}</Title>
                    <Text>{record?.naqabeh_number}</Text>
                  </>
                )}

                {record?.ssn && (
                  <>
                    <Title level={5}>
                      {currentCountry === 'QA'
                        ? 'الرقم الشخصي'
                        : 'الرقم الوطني'}
                    </Title>
                    <Text>{record?.ssn}</Text>
                  </>
                )}

                {record?.membership?.name && (
                  <>
                    <Title level={5}>{'نوع الاشتراك'}</Title>
                    <Text>{record?.membership?.name}</Text>
                  </>
                )}

                {record?.work_phone && (
                  <>
                    <Title level={5}>{'هاتف العمل'}</Title>
                    <Text>{record?.work_phone}</Text>
                  </>
                )}

                <Title level={5}>{'التقييم'}</Title>
                <Text>{record?.average_rate}</Text>

                <Title level={5}>{'عدد التقييمات'}</Title>
                <Text>{record?.rate_count}</Text>

                <Title level={5}>{'اسم الشارع'}</Title>
                <Text>{record?.street_name || 'غير متوفر'}</Text>

                <Title level={5}>{'رقم المبنى'}</Title>
                <Text>{record?.building_name || 'غير متوفر'}</Text>

                {record?.whats_app_phone && (
                  <>
                    <Title level={5}>{'رقم الواتس اب'}</Title>
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.whats_app_phone}</Text>
                    </div>
                  </>
                )}

                {record?.date_of_birth && (
                  <>
                    <Title level={5}>{'تاريخ الولادة'}</Title>
                    <Text>
                      {dayjs(record?.date_of_birth).format('YYYY-MM-DD')}
                    </Text>
                  </>
                )}
                {(record?.years_of_practice === 0 ||
                  record?.years_of_practice > 0) && (
                  <div style={{ marginTop: 10 }}>
                    <Title level={5}>{'سنوات الخبرة'}</Title>
                    <Text>{record?.years_of_practice} سنوات</Text>
                  </div>
                )}

                {record?.fax && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'الفاكس'}
                    </Title>
                    <div style={{ direction: 'ltr' }}>
                      <Text>{record?.fax}</Text>
                    </div>
                  </>
                )}
                {record?.land_line && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'الهاتف الارضي'}
                    </Title>
                    <div style={{ direction: 'ltr', textAlign: 'end' }}>
                      <Text>{record?.land_line}</Text>
                    </div>
                  </>
                )}

                {record?.google_maps_link && (
                  <>
                    <Title level={5} style={{ marginTop: 5, marginBottom: -2 }}>
                      {'خرائط جوجل'}
                    </Title>
                    <a
                      href={record?.google_maps_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      رابط
                    </a>
                  </>
                )}

                {record?.website && (
                  <>
                    <Title level={5} style={{ marginTop: 5, marginBottom: -2 }}>
                      {'موقع الكتروني'}
                    </Title>
                    <a href={record?.website} target="_blank" rel="noreferrer">
                      رابط
                    </a>
                  </>
                )}

                {record?.youtube && (
                  <>
                    <Title level={5} style={{ marginTop: 5, marginBottom: -2 }}>
                      {'اليوتيوب'}
                    </Title>
                    <a href={record?.youtube} target="_blank" rel="noreferrer">
                      رابط
                    </a>
                  </>
                )}

                {record?.telegram && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'التليجرام'}
                    </Title>
                    <div style={{ direction: 'ltr', textAlign: 'end' }}>
                      <Text>{record?.telegram}</Text>
                    </div>
                  </>
                )}

                {record?.twitter_handle && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'تويتر'}
                    </Title>
                    <Text>{record?.twitter_handle}</Text>
                  </>
                )}

                {record?.linkedin_id && (
                  <>
                    <Title level={5} style={{ marginTop: 5 }}>
                      {'لينكد إن'}
                    </Title>
                    <Text>{record?.linkedin_id}</Text>
                  </>
                )}
              </div>

              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.biography && (
                  <div style={{ width: 300, marginBottom: 10 }}>
                    <Title level={5}>{'السيرة الذاتية'}</Title>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                      {record?.biography}
                    </Text>
                  </div>
                )}
                {(pendingSpecialties || [])?.length > 0 && (
                  <>
                    <Title level={5}>{'التخصصات المعلقة'}</Title>
                    <div className="arrayFieldsWrapper">
                      {pendingSpecialties?.map((item: any, key: number) => {
                        if (
                          record?.verified_specialties
                            ?.map(
                              (spec: { specialty: string }) => spec.specialty
                            )
                            ?.includes(item.specialty)
                        ) {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Text>{'تم اعتماد جميع التخصصات'}</Text>
                              <AiOutlineCheck
                                color="#1da57a"
                                style={{ marginRight: 5 }}
                              />
                            </div>
                          )
                        }
                        return (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            {item?.photo?.length > 0 ? (
                              <div
                                style={{
                                  color: 'rgb(40 201 150)',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  handleViewImage(
                                    item?.photo
                                      ?.find(
                                        (photo: { specialty: string }) =>
                                          photo.specialty === item?.id
                                      )
                                      ?.photos?.map(
                                        (id: string) => `${UPLOAD_URI_ID}/${id}`
                                      )
                                  )
                                }
                              >
                                {` ${key + 1}- `}
                                {item?.specialty}
                              </div>
                            ) : (
                              <p style={{ marginBottom: 0 }}>
                                {item?.specialty}
                              </p>
                            )}
                            {user?.role !== 2 &&
                              item?.specialty &&
                              item?.photo?.length > 0 && (
                                <Actions
                                  name_ar="التخصص"
                                  type="specialty"
                                  showRejectDocumentButton
                                  record={item?.id}
                                  onClickRejectDocument={() => {
                                    Swal.fire({
                                      title: 'هل أنت متأكد أنك تريد الاستمرار؟',
                                      showCancelButton: true,
                                      confirmButtonColor: '#db2828',
                                      confirmButtonText: 'إرسال',
                                      cancelButtonText: 'إلغاء',
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        record?.id &&
                                          RejectSpecialtyAction(
                                            record?.id,
                                            {
                                              specialty_id: item?.id,
                                            },
                                            refetch
                                          )
                                      }
                                    })
                                  }}
                                  onClickApprove={() =>
                                    Swal.fire({
                                      title: 'هل أنت متأكد أنك تريد الاستمرار؟',
                                      showCancelButton: true,
                                      confirmButtonColor: '#db2828',
                                      confirmButtonText: 'إرسال',
                                      cancelButtonText: 'إلغاء',
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        VerifySpecialtyAction(
                                          'lawyer',
                                          record?.id,
                                          { specialty: [item.id] },
                                          undefined,
                                          refetch
                                        )
                                      }
                                    })
                                  }
                                />
                              )}
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                {record?.verified_specialties?.length > 0 && (
                  <>
                    <Title level={5}>{'التخصصات المؤكدة'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.verified_specialties?.map(
                        (
                          item: { specialty: string; id: string },
                          key: number
                        ) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: 200,
                              }}
                            >
                              <div>
                                {` ${key + 1}- `}
                                <Text>{item?.specialty}</Text>
                              </div>
                              {user?.role !== 2 && (
                                <MdDeleteForever
                                  color={Colors.red}
                                  size={18}
                                  className="icons"
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'هل أنت متأكد أنك تريد الاستمرار؟',
                                      showCancelButton: true,
                                      confirmButtonColor: '#db2828',
                                      confirmButtonText: 'إرسال',
                                      cancelButtonText: 'إلغاء',
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        EditAccountAction(
                                          'lawyer',
                                          record?.id,
                                          {
                                            verified_specialties: record?.verified_specialties
                                              ?.map(
                                                (mapped: { id: string }) =>
                                                  mapped?.id
                                              )
                                              ?.filter(
                                                (sliced: string) =>
                                                  sliced !== item?.id
                                              ),
                                            search_specialties: record?.verified_specialties
                                              ?.map(
                                                (mapped: { id: string }) =>
                                                  mapped?.id
                                              )
                                              ?.filter(
                                                (sliced: string) =>
                                                  sliced !== item?.id
                                              ),
                                          },
                                          queryResult?.refetch
                                        )
                                      }
                                    })
                                  }}
                                />
                              )}
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                )}

                {record?.research?.length > 0 && (
                  <>
                    <Title level={5}>{'الأبحاث'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.research?.map((item: Research, key: number) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {` ${key + 1}- `}
                            <a
                              href={item.url}
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              <Text
                                style={{
                                  color: item.url
                                    ? Colors.seaBlue
                                    : Colors.darkGray,
                                  marginRight: 3,
                                }}
                              >
                                {item?.label}
                              </Text>
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                {record?.training_courses?.length > 0 && (
                  <>
                    <Title level={5}>{'الدورات التدريبية'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.training_courses?.map(
                        (item: TrainingCourses, key: number) => {
                          return (
                            <Text>
                              {`${key + 1}- `} {item?.title} مكان الحصول:{' '}
                              {item?.acquired_from} بتااريخ:{' '}
                              {dayjs(item?.acquired_at).format('YYYY-MM-DD')}
                            </Text>
                          )
                        }
                      )}
                    </div>
                  </>
                )}

                {record?.work_experience?.length > 0 && (
                  <>
                    <Title level={5}>{'الخبرات العملية'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.work_experience?.map(
                        (item: WorkExperience, key: number) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {`${key + 1}- `}
                              <div>
                                <Text style={{ marginRight: 5 }}>
                                  الخبرة: {item?.name}
                                </Text>
                                <Text style={{ marginRight: 10 }}>
                                  سنوات الخبرة: {item?.years} سنة
                                </Text>
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                )}

                {record?.verified_by?.length > 0 && (
                  <>
                    <Title level={5}>{'الجهات'}</Title>
                    <div className="arrayFieldsWrapper">
                      {record?.verified_by?.map(
                        (item: Association, key: number) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {`${key + 1}- `}
                              <Text style={{ marginRight: 5 }}>
                                {item?.name}
                              </Text>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                )}
              </div>

              <div style={{ margin: screens.md ? '' : '20px' }}>
                {record?.membership_expiry && (
                  <>
                    <Title level={5}>{'موعد انتهاء الاشتراك'}</Title>
                    <Text>
                      {dayjs(record?.membership_expiry).format(
                        'YYYY-MM-DD, HH:mm'
                      )}
                    </Text>
                  </>
                )}

                {/* {record?.updated_at && (
                  <>
                    <Title level={5}>{'تم تعديل الحساب في'}</Title>
                    <Text>
                      {dayjs(record?.updated_at).format('YYYY-MM-DD, HH:mm')}
                    </Text>
                  </>
                )} */}

                <Title level={5}>{'أنشأ في'}</Title>
                <Text>
                  {dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}
                </Text>

                {/* <Title level={5}>{'حساب مؤكد'}</Title>
                <BooleanField value={record?.verified} /> */}

                <Title level={5}>{'تم تأكيد البريد الإلكتروني'}</Title>
                <BooleanField value={record?.is_email_confirmed} />

                <Title level={5}>{'تقييم معطل'}</Title>
                <BooleanField value={record?.disable_review} />

                <Title level={5}>{'تسجيل مستقل'}</Title>
                <BooleanField value={record?.stand_alone_signup} />

                <Title level={5}>{'محذوف'}</Title>
                <BooleanField value={record?.deleted} />
              </div>
            </div>
          </Show>
        </Col>
      </Row>
      <div style={{ marginTop: 20 }} />
      {certificates?.length > 0 && (
        <List
          title={`${'الشهادات'}`}
          pageHeaderProps={{
            extra: <></>,
          }}
        >
          <Table dataSource={certificates} rowKey="id">
            <Table.Column
              dataIndex={'certificate_name'}
              title={'اسم الشهادة'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={'certificate_id'}
              title={'رقم الشهادة'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={'certificate_issued_from'}
              title={'مكان الحصول'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={'certificate_url'}
              title={'رابط الشهادة'}
              render={(value) =>
                value ? (
                  <a href={value} target={'_blank'} rel="noreferrer">
                    <TextField value={value} />
                  </a>
                ) : (
                  <TextField value={'غير متوفر'} />
                )
              }
            />

            <Table.Column
              dataIndex={'certificate_issue_date'}
              title={'تاريخ الحصول'}
              render={(value) => (
                <TextField value={dayjs(value).format('YYYY-MM-DD')} />
              )}
            />

            <Table.Column
              dataIndex="deleted"
              title={'محذوف'}
              align="center"
              render={(value) => <BooleanField value={value} />}
            />

            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              fixed="right"
              key="actions"
              render={(_text, record: Certificate): any => {
                return (
                  <>
                    {record?.photo?.filename ? (
                      <a
                        href={`${UPLOAD_URI}/${record?.photo?.filename}`}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <Text
                          style={{
                            color: Colors.seaBlue,
                          }}
                        >
                          أظهر الوثيقة
                        </Text>
                      </a>
                    ) : (
                      <></>
                    )}
                  </>
                )
              }}
            />
          </Table>
        </List>
      )}
      <SpecialtyPhotos uploads={specialtyPhotosModal} closeModal={closeModal} />
    </>
  )
}

export default ShowLawyer
