import React from 'react'
import Colors from '../../Style/Colors'
import {
  Grid,
  ListButton,
  RefreshButton,
  Show,
  Space,
  Typography,
  useShow,
} from '@pankod/refine'
import dayjs from 'dayjs'

const { useBreakpoint } = Grid

const ShowContactUs = () => {
  const { queryResult } = useShow({})
  const { data, isLoading } = queryResult
  const { Title, Text } = Typography

  const screens = useBreakpoint()
  const phoneNumber = data?.data?.phoneNumber?.slice(
    1,
    data?.data?.phoneNumber.length
  )
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'تواصل معنا'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/contact-us"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'تواصل معنا'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title="القائمة"
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'الرجوع إلى القائمة'}</Text>
              </ListButton>
            </Space>
          ),
        }}
      >
        <Title level={5}>{'الاسم'}</Title>
        <Text>{data?.data?.name}</Text>

        <Title level={5}>{'البريد الإلكتروني'}</Title>
        <Text>{data?.data?.email}</Text>

        <Title level={5}>{'رقم الهاتف'}</Title>
        <Text>{phoneNumber}</Text>
        <Text>+</Text>

        <Title level={5}>{'الرسالة'}</Title>
        <Text style={{ whiteSpace: 'pre-line' }}>
          {data?.data?.messageBody}
        </Text>

        <Title level={5}>{'التاريخ'}</Title>
        <Text>{dayjs(data?.data?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
      </Show>
    </>
  )
}

export default ShowContactUs
