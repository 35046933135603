import * as React from 'react'
import { Create, Form, Input, Select, useForm, useList } from '@pankod/refine'

export const CreateCity = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()
  const countries = useList({ resource: 'country' })

  const filteredCountries = (countries?.data?.data || [])?.map((item) => {
    return { label: item?.country, value: item?.id }
  })

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء مدينة'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          required
          label={'المدينة'}
          name="city"
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Input name="city" />
        </Form.Item>

        <Form.Item
          label={'الدولة'}
          name={'country'}
          required
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Select
            // @ts-ignore
            options={filteredCountries}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
