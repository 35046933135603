import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  BooleanField,
  CreateButton,
  useGetIdentity,
} from '@pankod/refine'
import { IAccount } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import {
  DeleteRecordAction,
  EditAccountAction,
  SwitchAccount,
  VerifyLawyerAction,
} from '../Store/Actions'
import { SwitchAccountInputs } from '../Lawyer/LawyersListing'
import { Search } from '../../Components/Search'
import { EditMemberShip } from '../../Components/MemberShip'

const ComapnyList = () => {
  const { data: user } = useGetIdentity()
  const { show, edit } = useNavigation()
  const [searchResults, setSearchResults] = useState<any>([])

  const { tableProps, tableQueryResult } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
  })

  const handleSwitchToAccount = (companyId: string) => {
    const inputs: SwitchAccountInputs = {
      token: user?.token,
      company_id: companyId,
    }
    SwitchAccount(inputs)
  }

  return (
    <List
      title={`${'الشركات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="company"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              type={4}
            />
            {user?.role !== 2 && (
              <CreateButton style={{ marginRight: 10 }}>
                إنشاء شركة{' '}
              </CreateButton>
            )}
          </div>
        ),
      }}
    >
      {(searchResults || [])?.length > 0 ? (
        <Table
          dataSource={searchResults}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('company', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          {/* <Table.Column
            dataIndex="authorized_signatory"
            title={'اسم المفوض بالتوقيع'}
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          /> */}
          <Table.Column
            dataIndex="email"
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={'registration_number'}
            title={'رقم التسجيل'}
            align="center"
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />
          <Table.Column
            dataIndex={['membership', 'name']}
            title={'نوع الاشتراك'}
            align="center"
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />

          <Table.Column
            dataIndex={'stand_alone_signup'}
            title={'تسجيل مستقل'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'is_disabled'}
            title={'حساب موقف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <Actions
                    name_ar="الشركة"
                    type="delete"
                    edit
                    verifyAccount={record?.is_disabled}
                    switchToAccount={record?.email?.includes(
                      'he_doesnt_have_email'
                    )}
                    record={record}
                    onClickDelete={() =>
                      DeleteRecordAction(
                        `company/${record?.id}`,
                        tableQueryResult?.refetch,
                        false,
                        undefined,
                        record?.deleted
                      )
                    }
                    onClickEdit={() => edit('company', record?.id)}
                    onClickSwitch={() => handleSwitchToAccount(record?.id)}
                    onClickVerifyAccount={() => {
                      if (record?.stand_alone_signup) {
                        VerifyLawyerAction(
                          'company',
                          record?.id,
                          tableQueryResult?.refetch
                        )
                      } else {
                        EditAccountAction(
                          'company',
                          record?.id,
                          { is_disabled: false },
                          undefined,
                          tableQueryResult?.refetch
                        )
                      }
                    }}
                  />
                )
              }}
            />
          )}
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(record) => {
            return {
              onClick: () => {
                show('company', record.id)
              },
            }
          }}
        >
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="authorized_signatory"
            title={'اسم المفوض بالتوقيع'}
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />
          <Table.Column
            dataIndex="email"
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />
          {/* <Table.Column
            dataIndex={'registration_number'}
            title={'رقم التسجيل'}
            align="center"
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          /> */}
          <Table.Column
            dataIndex={['membership', 'name']}
            title={'نوع الاشتراك'}
            align="center"
            render={(value) => <TextField value={value || 'لا يوجد'} />}
          />
          <Table.Column
            dataIndex={'stand_alone_signup'}
            title={'تسجيل مستقل'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />

          <Table.Column
            dataIndex={'is_disabled'}
            title={'حساب موقف'}
            align="center"
            render={(value) => <BooleanField value={value} />}
          />
          {user?.role !== 2 && (
            <Table.Column<any>
              title={'الإجراءات'}
              dataIndex="actions"
              align="center"
              render={(_text, record): any => {
                return (
                  <div>
                    <Actions
                      name_ar="الشركة"
                      type="delete"
                      edit
                      verifyAccount={record?.is_disabled}
                      switchToAccount={record?.email?.includes(
                        'he_doesnt_have_email'
                      )}
                      record={record}
                      onClickDelete={() =>
                        DeleteRecordAction(
                          `company/${record?.id}`,
                          tableQueryResult?.refetch,
                          false,
                          undefined,
                          record?.deleted
                        )
                      }
                      onClickEdit={() => edit('company', record?.id)}
                      onClickSwitch={() => handleSwitchToAccount(record?.id)}
                      onClickVerifyAccount={() => {
                        if (record?.stand_alone_signup) {
                          VerifyLawyerAction(
                            'company',
                            record?.id,
                            tableQueryResult?.refetch
                          )
                        } else {
                          EditAccountAction(
                            'company',
                            record?.id,
                            { is_disabled: false },
                            undefined,
                            tableQueryResult?.refetch
                          )
                        }
                      }}
                    />
                    <EditMemberShip record={record} categoryType="company" />
                  </div>
                )
              }}
            />
          )}
        </Table>
      )}
    </List>
  )
}

export default ComapnyList
