import React, { useCallback, useEffect, useState } from 'react'
import {
  Show,
  useShow,
  Typography,
  Space,
  ListButton,
  RefreshButton,
  Grid,
  BooleanField,
  List,
  Table,
  TextField,
  useNavigation,
  notification,
  useGetIdentity,
} from '@pankod/refine'
import dayjs from 'dayjs'
import Colors from '../../Style/Colors'
import { Actions } from '../../Components/ActionButtons'
import { AcceptOrRejectAction, DeleteRecordAction } from '../Store/Actions'
import { AccountStore, ConfigsStore } from '../Store'
import { apiRequest } from '../../Util/api'

const { useBreakpoint } = Grid

function ShowBlog() {
  const screens = useBreakpoint()
  const { data: user } = useGetIdentity()
  const { Title, Text } = Typography
  const { show } = useNavigation()
  const [comments, setComments] = useState<any>()

  const { queryResult } = useShow({})
  const { data, isLoading, refetch } = queryResult

  const record = data?.data

  const handleDashboard = useCallback(
    async (id: string) => {
      try {
        const comment = await apiRequest<any>({
          url: `comment/by-blog/${id}`,
          method: 'GET',
          headers: {
            device_id: await ConfigsStore.get('device_uuid'),
            authorization: `Bearer ${
              (await AccountStore.get('account'))?.token
            }`,
          },
        })
        if (data?.data) {
          setComments(comment?.data)
        }
      } catch (error: any) {
        // if (error?.message.includes('401')) {
        //   RefreshToken();
        // } else {
        notification.error({
          message: 'خطأ',
          description: error?.message,
        })
        // }
      }
    },
    [data?.data]
  )

  useEffect(() => {
    data?.data?.id && handleDashboard(data?.data?.id)
  }, [data?.data?.id, handleDashboard])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'المجلة القانونية'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="/blog" style={{ fontWeight: 700, color: Colors.seaBlue }}>
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'المجلة القانونية'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'تفاصيل المجلة'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              <ListButton>
                <Text>{'المجلة القانونية'}</Text>
              </ListButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="المجلة"
                  type="approveReject"
                  record={record}
                  deleteRecord
                  showApproveButton={record?.approved === true}
                  showRejectButton={record?.approved === false}
                  onClickApprove={() =>
                    AcceptOrRejectAction(
                      `blog/${record?.id}`,
                      { approved: true },
                      'PUT',
                      refetch
                    )
                  }
                  onClickReject={() =>
                    AcceptOrRejectAction(
                      `blog/${record?.id}`,
                      { approved: false },
                      'PUT',
                      refetch
                    )
                  }
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `blog/${record?.id}`,
                      refetch,
                      true,
                      undefined,
                      record?.deleted
                    )
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            {record?.poster_name && (
              <>
                <Title level={5}>{'الاسم'}</Title>
                <Text>{record?.poster_name}</Text>
              </>
            )}

            <Title level={5}>{'عنوان المجلة'}</Title>
            <Text>{record?.title}</Text>

            {/* {record?.updated_at && (
              <>
                <Title level={5}>{'تم التعديل في'}</Title>
                <Text>
                  {dayjs(record?.updated_at).format('YYYY-MM-DD, HH:mm')}
                </Text>
              </>
            )} */}

            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            {record?.body && (
              <>
                <Title level={5}>{'النص'}</Title>
                <div
                  style={{
                    width: record?.body?.length > 50 ? 400 : 200,
                    whiteSpace: 'pre-line',
                  }}
                >
                  <Text>{record?.body}</Text>
                </div>
              </>
            )}
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'معتمد'}</Title>
            <BooleanField value={record?.approved} />

            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.deleted} />
          </div>
        </div>
      </Show>

      <div style={{ marginTop: 20, marginBottom: 20 }} />
      {(comments || [])?.length > 0 && (
        <List
          title={`${'التعليقات'}`}
          pageHeaderProps={{
            extra: <></>,
          }}
        >
          <Table
            rowKey="id"
            dataSource={comments || []}
            style={{ cursor: 'pointer' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  record.id && show('comment', record.id)
                },
              }
            }}
          >
            <Table.Column
              dataIndex="user_name"
              title={'الاسم'}
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['blog', 'title']}
              title={'عنوان المجلة'}
              render={(value) => <TextField value={value || 'لا يوجد'} />}
            />

            <Table.Column
              dataIndex={'created_at'}
              title={'أنشأ في'}
              align="center"
              render={(value) => (
                <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
              )}
            />
            <Table.Column
              dataIndex={'deleted'}
              title={'محذوف'}
              align="center"
              render={(value) => <BooleanField value={value} />}
            />
          </Table>
        </List>
      )}
    </>
  )
}

export default ShowBlog
