import localforage from 'localforage'
import { City, Specialty } from '../types'

interface IConfigsStore {
  store?: LocalForage
  device_uuid?: string
  logged_in?: boolean
  subServices?: any
  recaptcha?: string
  rememberMe?: any
  cookieExpiration?: any
  subServicesDocuments?: any
  groups?: any
  roles?: any
  adminRoles?: any
  resetPasswordEmail?: string
  resetPasswordToken?: any
  cities?: City[]
  specialties?: Specialty[]
  country?: string
  sort?: string
}

class Configs implements IConfigsStore {
  store: LocalForage

  constructor() {
    this.store = localforage.createInstance({
      name: 'Configs',
      driver: localforage.LOCALSTORAGE,
    })
  }

  async getStore() {
    return (
      (await this.store.getItem<IConfigsStore>('store')) ||
      ({} as IConfigsStore)
    )
  }

  async get<T extends keyof IConfigsStore>(key: T) {
    const storeData = await this.getStore()

    return storeData[key]
  }

  async set<T>(key: keyof IConfigsStore, payload: T) {
    const storeData = await this.getStore()
    // @ts-ignore
    storeData[key] = payload

    return this.store.setItem('store', storeData)
  }
}

export const ConfigsStore = new Configs()
