import * as React from 'react'
import { Create, Form, Input, Switch, useForm } from '@pankod/refine'

export const CreateMembership = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={'إنشاء عضوية'}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item required label={'العضوية'} name="name">
          <Input name="name" />
        </Form.Item>

        <Form.Item required label={'التكلفة'} name="cost">
          <Input name="cost" />
        </Form.Item>

        <Form.Item
          required
          label={'عدد التخصصات المسموحة (للشركة)'}
          name={['company_privileges', 'goals']}
        >
          <Input name="goals" />
        </Form.Item>

        <Form.Item
          required
          label={'عدد التخصصات المسموحة (للمحامي)'}
          name={['lawyer_privileges', 'pending_specialties']}
        >
          <Input name="pending_specialties" />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              maxHeight: 300,
              display: 'flex',
              flexWrap: 'wrap',
              width: '45%',
            }}
          >
            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'السيرة الذاتية (للمحامي)'}
              name={['lawyer_privileges', 'cv']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'الدرجة العلمية (للمحامي)'}
              name={['lawyer_privileges', 'scholar_degree']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'النشاطات (للمحامي)'}
              name={['lawyer_privileges', 'scholar_degree']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'الدورات التدريبية (للمحامي)'}
              name={['lawyer_privileges', 'training_courses']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'الابحاث (للمحامي)'}
              name={['lawyer_privileges', 'research']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'وسائل التواصل الاجتماعي (للمحامي)'}
              name={['lawyer_privileges', 'social_media']}
            >
              <Switch />
            </Form.Item>
          </div>

          <div
            style={{
              maxHeight: 300,
              display: 'flex',
              flexWrap: 'wrap',
              width: '45%',
            }}
          >
            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'النشاطات (للشركة)'}
              name={['company_privileges', 'activities']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'العملاء (للشركة)'}
              name={['company_privileges', 'clients']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'الأبحاث (للشركة)'}
              name={['company_privileges', 'research']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'الفريق (للشركة)'}
              name={['company_privileges', 'team']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'السيرة الذاتية (للشركة)'}
              name={['company_privileges', 'biography']}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              style={{ margin: '5px 10px' }}
              label={'وسائل التواصل الاجتماعي (للشركة)'}
              name={['company_privileges', 'social_media']}
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Create>
  )
}
