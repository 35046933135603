import React from 'react'
import {
  Show,
  Typography,
  Space,
  RefreshButton,
  Grid,
  useGetIdentity,
  List,
  Table,
  TextField,
  useNavigation,
  useShow,
} from '@pankod/refine'
import { Actions } from '../../Components/ActionButtons'
import Colors from '../../Style/Colors'
import { HomePageContent } from '../Store/types'
import styled from 'styled-components'

const { useBreakpoint } = Grid

function ShowHomePageContent() {
  const screens = useBreakpoint()
  const { Title, Text } = Typography
  const { data: user } = useGetIdentity()
  const { edit } = useNavigation()

  const { queryResult } = useShow<HomePageContent>({})
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontWeight: 700, fontSize: screens.md ? 30 : 20 }}>
          {'محتوى الصفحة الرئيسية'}
        </h1>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a
            href="/home-page-content"
            style={{ fontWeight: 700, color: Colors.seaBlue }}
          >
            {'الصفحة الرئيسية'}
          </a>
          <h4 style={{ fontWeight: 700, marginLeft: 2, marginRight: 2 }}>
            {'/'}
          </h4>
          <h4 style={{ fontWeight: 700 }}>{'محتوى الصفحة الرئيسية'}</h4>
        </div>
      </div>
      <Show
        isLoading={isLoading}
        title={'محتوى الصفحة الرئيسية'}
        pageHeaderProps={{
          extra: (
            <Space>
              <RefreshButton>
                <Text>{'تحديث'}</Text>
              </RefreshButton>
              {user?.role !== 2 && (
                <Actions
                  name_ar="النظام"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('home-page-content', record?.id)
                  }
                  type={'edit'}
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'الدولة'}</Title>
            <Text>{record?.country?.country}</Text>

            <Title level={5}>{'العنوان الرئيسي'}</Title>
            <DescWrapper>{record?.main.title}</DescWrapper>

            <Title level={5}>{'المحتوى الأول'}</Title>
            <DescWrapper>{record?.main.firstContent}</DescWrapper>

            <Title level={5}>{'المحتوى الثاني'}</Title>
            <DescWrapper>{record?.main.secondContent}</DescWrapper>
          </div>
          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'عنوان تعرف أكثر'}</Title>
            <DescWrapper>{record?.learnMore.title}</DescWrapper>

            <Title level={5}>{'محتوى تعرف أكثر'}</Title>
            <DescWrapper>{record?.learnMore.content}</DescWrapper>
          </div>

          <div style={{ margin: screens.md ? '' : '20px' }}>
            <Title level={5}>{'عنوان الأسئلة الشائعة'}</Title>
            <DescWrapper>{record?.afaq.title}</DescWrapper>

            <Title level={5}>{'محتوى الأسئلة الشائعة'}</Title>
            <DescWrapper>{record?.afaq.content}</DescWrapper>
          </div>
        </div>
      </Show>

      <div style={{ marginTop: 20 }} />

      <List
        title={`${'نقاط الأسئلة الشائعة'}`}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Table dataSource={record?.afaqPoints || []} rowKey="id">
          <Table.Column
            dataIndex={'title'}
            title={'العنوان'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={'content'}
            title={'المحتوى'}
            render={(value) => <TextField value={value} />}
          />
        </Table>
      </List>
    </>
  )
}

export default ShowHomePageContent

const DescWrapper = styled.div`
  width: 300px;
  margin-bottom: 20px;
`
