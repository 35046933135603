// import { notification } from '@pankod/refine';
import axios from 'axios'
import dayjs from 'dayjs'
import { API_KEY } from '../../../Configs'
import { apiRequest } from '../../../Util/api'
import { AccountStore } from '../Stores/Account'
import { ConfigsStore } from '../Stores/Configs'
import { IAccount } from '../types'
import { notification } from '@pankod/refine'
import * as Sentry from '@sentry/react'

export const axiosInstance = axios.create()

const setHeaders = async () => {
  const token = await AccountStore.get('account')
  const account = await AccountStore.get('account')
  const country =
    (await ConfigsStore.get('country')) ||
    (account?.countries && account?.countries[0]?.id)

  if (token?.token) {
    axiosInstance.defaults.headers = {
      api_key: API_KEY,
      Authorization: `Bearer ${token?.token}`,
      device: await ConfigsStore.get('device_uuid'),
      'x-country-id': country,
    }
  } else {
    axiosInstance.defaults.headers = {
      api_key: API_KEY,
      'x-country-id': country,
    }
  }
}
setHeaders()

export const LoginAction = async (LoginInput: {
  email: string
  password: string
}) => {
  try {
    const { data: accountData } = await apiRequest<IAccount>({
      url: 'admin/login',
      method: 'POST',
      data: LoginInput,
      headers: {
        device: await ConfigsStore.get('device_uuid'),
      },
    })
    if (!accountData) throw new Error('Account Not Found!')
    // await GetGroupsRoleAction(accountData?.admin?.group_id);
    await AccountStore.set('account', accountData)
    await ConfigsStore.set('logged_in', true)
    await ConfigsStore.set('resetPasswordEmail', '')
    await ConfigsStore.set('sort', 'created_at')
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      }
      await ConfigsStore.set('cookieExpiration', cookies)
    }

    const country =
      (await ConfigsStore.get('country')) ||
      (accountData?.countries && accountData?.countries[0]?.id)
    await ConfigsStore.set('country', country)
    axiosInstance.defaults.headers = {
      Authorization: `Bearer ${accountData.token}`,
      api_key: API_KEY,
      device: await ConfigsStore.get('device_uuid'),
      'x-country-id': country,
    }
  } catch (error: any) {
    throw new Error('Wrong credentials')
  }
}

export const LogoutAction = async (withRefresh?: boolean) => {
  await AccountStore.set('account', {})
  await ConfigsStore.set('logged_in', false)
  await ConfigsStore.set('rememberMe', false)
  await ConfigsStore.set('cookieExpiration', '')
  await ConfigsStore.set('adminRoles', '')
  await ConfigsStore.set('resetPasswordEmail', '')
  await ConfigsStore.set('country', '')
  await ConfigsStore.set('sort', 'created_at')
  // set sentry user to null on logout
  Sentry.setUser(null)
  sessionStorage.clear()
  if (withRefresh) {
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return Promise.resolve()
}

export const ForgotPasswordAction = async (
  inputs: { email: string },
  navigateToVerification: (email: string) => void
) => {
  try {
    const { data: ForgotPasswordData } = await apiRequest<boolean>({
      url: 'admin/forget-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_uuid: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    if (!ForgotPasswordData) {
      notification.error({
        message: 'خطأ',
        description: 'حدث خطأ ما، الرجاء المحاولة مرة أخرى',
      })
    }
    navigateToVerification(inputs.email)
    await ConfigsStore.set('resetPasswordEmail', inputs?.email)
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: 'الحساب غير موجود',
    })
  }
}

export const RefetchAccountAction = async (
  id: string,
  withRefresh?: boolean
) => {
  const account = await AccountStore.get('account')
  try {
    const { data } = await apiRequest<IAccount>({
      url: `admin/${id}`,
      method: 'GET',
      headers: {
        device_uuid: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })

    if (
      data?.name !== account?.name ||
      data?.email !== account?.email ||
      data?.role !== account?.role ||
      data?.countries?.length !== account?.countries?.length
    ) {
      await AccountStore.set('account', {
        ...data,
        token: account?.token,
      })
      if (withRefresh) {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    }
  } catch (error: any) {}
}

export const VerificationAction = async (
  code: number,
  email?: string,
  navigate?: () => void
) => {
  const inputs = { code, email }
  try {
    const { data: verificationData } = await apiRequest<boolean>({
      url: 'admin/verify-code',
      method: 'POST',
      data: inputs,
      headers: {
        device_uuid: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
      },
    })
    if (!verificationData) {
      notification.error({
        message: 'خطأ',
        description: 'حدث خطأ ما، الرجاء المحاولة مرة أخرى',
      })
    }
    await ConfigsStore.set('resetPasswordToken', verificationData)

    navigate && navigate()
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: 'رمز التحقق غير صحيح',
    })
  }
}

export const ResetPasswordAction = async (
  password: string,
  navigate?: () => void
) => {
  const inputs = { password }
  try {
    const { data: resetPasswordData } = await apiRequest<boolean>({
      url: 'admin/reset-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_uuid: await ConfigsStore.get('device_uuid'),
        authorization: `Bearer ${
          (await ConfigsStore.get('resetPasswordToken'))?.token
        }`,
      },
    })
    if (!resetPasswordData) {
      notification.error({
        message: 'خطأ',
        description: 'حدث خطأ ما، الرجاء المحاولة مرة أخرى',
      })
    }

    notification.success({
      message: 'نجاح',
      description: 'تمت العملية بنجاح',
    })
    navigate && navigate()
    await ConfigsStore.set('resetPasswordEmail', '')
    await ConfigsStore.set('resetPasswordToken', '')
  } catch (error: any) {
    // if (error?.message.includes('401')) {
    //   RefreshToken();
    // } else {
    notification.error({
      message: 'خطأ',
      description: error?.message,
    })
    // }
  }
}

// export const GetRolesAction = async (id?: string) => {
//   const group_id = await AccountStore.get('account');
//   try {
//     const { data: roles } = await apiRequest<any>({
//       url: `admin-role/role`,
//       method: 'GET',
//       headers: {
//         device_uuid: await ConfigsStore.get('device_uuid'),
//         authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
//       },
//     });
//     if (!roles) throw new Error('Something went wrong!');
//     await ConfigsStore.set('roles', roles);
//   } catch (error: any) {
//     if (error?.message.includes('401') && group_id) {
//       RefreshToken();
//     } else {
//       throw new Error('error');
//     }
//   }
// };

// export const GetGroupsAction = async () => {
//   try {
//     const { data: groups } = await apiRequest<any>({
//       url: 'admin-role/group',
//       method: 'GET',
//       headers: {
//         device_uuid: await ConfigsStore.get('device_uuid'),
//         authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
//       },
//     });
//     await ConfigsStore.set('groups', groups);
//     if (!groups) throw new Error('Something went wrong!');
//   } catch (error: any) {
//     if (error?.message.includes('401')) {
//       RefreshToken();
//     } else {
//       throw new Error('error');
//     }
//   }
// };

// export const GetGroupRoleAction = async (
//   id?: string,
//   setRoles?: (role: any) => void
// ) => {
//   if (id) {
//     try {
//       const { data: roles } = await apiRequest<any>({
//         url: `/admin-role/find-group-roles/${id}`,
//         method: 'GET',
//         headers: {
//           device_uuid: await ConfigsStore.get('device_uuid'),
//           authorization: `Bearer ${(await AccountStore.get('account'))?.token}`,
//         },
//       });
//       if (!roles) throw new Error('Something went wrong!');
//       setRoles && setRoles(roles);
//     } catch (error: any) {
//       throw new Error('error');
//     }
//   }
// };

// export const RefreshToken = async () => {
//   try {
//     const { data: token } = await apiRequest<any>({
//       url: 'admin/refresh-token',
//       method: 'POST',
//       headers: {
//         device_uuid: await ConfigsStore.get('device_uuid'),
//         authorization: `Bearer ${
//           (await AccountStore.get('account'))?.refresh_token
//         }`,
//       },
//     });
//     if (!token) throw new Error('Something went wrong!');
//     const account = await AccountStore?.get('account');
//     // @ts-ignore
//     account.token = token?.token;
//     // @ts-ignore
//     account.refresh_token = token?.refresh_token;
//     await AccountStore?.set('account', account);
//     window.location.reload();
//   } catch (error: any) {
//     await AccountStore.set('account', {});
//     await ConfigsStore.set('logged_in', false);
//     await ConfigsStore.set('rememberMe', false);
//     await ConfigsStore.set('cookieExpiration', '');
//     await ConfigsStore.set('adminRoles', '');
//     await ConfigsStore.set('resetPasswordEmail', '');
//     sessionStorage.clear();
//     window.location.reload();
//   }
// };
