import * as React from 'react'
import {
  Button,
  Edit,
  Form,
  Input,
  Select,
  Spin,
  useForm,
  useNavigation,
} from '@pankod/refine'
import { useFormik } from 'formik'
import { SEARCH_BUTTON_ENUM, SEARCH_BUTTON_ENUM_ARRAY } from '../Store/types'
import { EditAction } from '../Store/Actions'

interface Inputs {
  buttonParams: string
  labelAr: string
  labelEn: string
  button: SEARCH_BUTTON_ENUM
}

export const EditSearchConfigs = () => {
  const { saveButtonProps, queryResult } = useForm()
  // const countries = useList({ resource: 'country' })
  const { show } = useNavigation()

  const record = queryResult?.data?.data

  // const filteredCountries = (countries?.data?.data || [])?.map((item) => {
  //   return { label: item?.country, value: item?.id }
  // })

  const selectButtons = SEARCH_BUTTON_ENUM_ARRAY.map(
    (item: any, index: number) => {
      return { label: item, value: index }
    }
  )

  const navigateToShow = () => {
    record?.id && show('search-configs', record?.id)
  }

  const [inputs, setInputs] = React.useState<Inputs[]>(() => {
    const initialInputs = record?.configs?.map((config: Inputs) => ({
      buttonParams: config.buttonParams,
      button: config.button,
      labelEn: config.labelEn,
      labelAr: config.labelAr,
    })) || [
      {
        buttonParams: '',
        button: SEARCH_BUTTON_ENUM.search_for_lawyer,
        labelEn: '',
        labelAr: '',
      },
    ]
    return initialInputs
  })

  const { handleSubmit } = useFormik({
    initialValues: {
      // country: '',
    },
    onSubmit: async () => {
      const filteredInputs = inputs.filter(
        (input) => input.labelAr || input.button
      )
      const payload = {
        configs: filteredInputs.map((input: Inputs) => ({
          buttonParams: input.buttonParams,
          button: input.button,
          labelEn: input.labelAr,
          labelAr: input.labelAr,
        })),
        // country: values.country,
      }

      EditAction('search-configs', record?.id, payload, navigateToShow)
    },
  })

  const addInput = () => {
    if (inputs.length < 5) {
      setInputs([
        ...inputs,
        {
          buttonParams: '',
          button: SEARCH_BUTTON_ENUM.search_for_lawyer,
          labelEn: '',
          labelAr: '',
        },
      ])
    } else {
      alert('Cannot add more than 5 inputs')
    }
  }

  const removeInput = (index: number) => {
    setInputs([...inputs.slice(0, index), ...inputs.slice(index + 1)])
  }

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  }

  // React.useEffect(() => {
  //   if (record) {
  //     setFieldValue('country', record?.country?.id)
  //   }
  // }, [record, setFieldValue])

  if (!record) {
    return <Spin />
  }

  return (
    <Edit saveButtonProps={buttonProps} title={'تعديل إعدادات بحث'}>
      <Form
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        {inputs.map(
          (
            input: {
              labelAr: string | number | readonly string[] | undefined
              button: any
            },
            index: number
          ) => (
            <div key={index}>
              <Form.Item
                required
                label={'الاسم'}
                // name={`configs[${index}].labelAr`}
                rules={[
                  {
                    validator: async (_, type) => {
                      if (!type) {
                        return Promise.reject(new Error('مطلوب'))
                      }
                    },
                  },
                ]}
              >
                <Input
                  name={`configs[${index}].labelAr`}
                  value={input.labelAr}
                  onChange={(event) =>
                    // @ts-ignore
                    setInputs([
                      ...inputs.slice(0, index),
                      { ...input, labelAr: event.target.value },
                      ...inputs.slice(index + 1),
                    ])
                  }
                />
              </Form.Item>

              <Form.Item
                label={'نوع البحث'}
                // name={`configs[${index}].button`}
                required
                rules={[
                  {
                    validator: async (_, type) => {
                      if (!type) {
                        return Promise.reject(new Error('مطلوب'))
                      }
                    },
                  },
                ]}
              >
                <Select
                  options={selectButtons}
                  value={input.button}
                  onChange={(value) =>
                    // @ts-ignore
                    setInputs([
                      ...inputs.slice(0, index),
                      { ...input, button: value },
                      ...inputs.slice(index + 1),
                    ])
                  }
                />
              </Form.Item>

              {index > 0 && (
                <Button
                  type="default"
                  size="large"
                  onClick={() => removeInput(index)}
                  style={{ marginBottom: 10, width: 100 }}
                >
                  حذف
                </Button>
              )}
            </div>
          )
        )}

        {inputs.length < 5 && (
          <Button
            type="default"
            size="large"
            onClick={addInput}
            style={{ marginBottom: 10, width: 100 }}
          >
            إضافة
          </Button>
        )}

        {/* <Form.Item
          label={'الدولة'}
          // name={'country'}
          required
          rules={[
            {
              validator: async (_, type) => {
                if (!type) {
                  return Promise.reject(new Error('مطلوب'))
                }
              },
            },
          ]}
        >
          <Select
            // @ts-ignore
            options={filteredCountries}
            value={values.country}
            onChange={(value) => setFieldValue('country', value)}
          />
        </Form.Item> */}
      </Form>
    </Edit>
  )
}
