import React, { useState } from 'react'
import {
  List,
  TextField,
  Table,
  useTable,
  HttpError,
  useNavigation,
  CreateButton,
  BooleanField,
  useGetIdentity,
} from '@pankod/refine'
import { IAccount } from '../Store/types'
import { Actions } from '../../Components/ActionButtons'
import dayjs from 'dayjs'
import MassNewsletterModal from '../../Components/MassNewsletterModal'
import { Search } from '../../Components/Search'

const NewsLetterListing = () => {
  const { data: user } = useGetIdentity()
  const [visible, setVisible] = useState(false)
  const [searchResults, setSearchResults] = useState<any>([])

  const { show, edit } = useNavigation()

  const { tableProps } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
  })

  return (
    <>
      <List
        title={`${'مجلتنا'}`}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Search
                path="newsletter"
                setSearchResults={setSearchResults}
                searchResults={searchResults}
              />
              {user?.role !== 2 && (
                <CreateButton
                  style={{ marginRight: 10 }}
                  onClick={() => setVisible(true)}
                >
                  إنشاء مجلة
                </CreateButton>
              )}
            </div>
          ),
        }}
      >
        {(searchResults || [])?.length > 0 ? (
          <Table
            dataSource={searchResults}
            rowKey="id"
            style={{ cursor: 'pointer' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show('newsletter', record.id)
                },
              }
            }}
          >
            <Table.Column
              dataIndex="email"
              title={'البريد الالكتروني'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="created_at"
              title={'أنشأ في'}
              render={(value) => (
                <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
              )}
            />

            <Table.Column
              dataIndex={'subscribed'}
              title={'مشترك'}
              align="center"
              render={(value) => <BooleanField value={value} />}
            />
            {user?.role !== 2 && (
              <Table.Column<any>
                title={'الإجراءات'}
                dataIndex="actions"
                align="center"
                render={(_text, record): any => {
                  return (
                    <Actions
                      name_ar="المجلة"
                      type="edit"
                      edit
                      record={record}
                      onClickEdit={() => edit('newsletter', record?.id)}
                    />
                  )
                }}
              />
            )}
          </Table>
        ) : (
          <Table
            {...tableProps}
            rowKey="id"
            style={{ cursor: 'pointer' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show('newsletter', record.id)
                },
              }
            }}
          >
            <Table.Column
              dataIndex="email"
              title={'البريد الالكتروني'}
              render={(value) => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="created_at"
              title={'أنشأ في'}
              render={(value) => (
                <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
              )}
            />

            <Table.Column
              dataIndex={'subscribed'}
              title={'مشترك'}
              align="center"
              render={(value) => <BooleanField value={value} />}
            />
            {user?.role !== 2 && (
              <Table.Column<any>
                title={'الإجراءات'}
                dataIndex="actions"
                align="center"
                render={(_text, record): any => {
                  return (
                    <Actions
                      name_ar="المجلة"
                      type="edit"
                      edit
                      record={record}
                      onClickEdit={() => edit('newsletter', record?.id)}
                    />
                  )
                }}
              />
            )}
          </Table>
        )}
      </List>
      <MassNewsletterModal visible={visible} setVisible={setVisible} />
    </>
  )
}

export default NewsLetterListing
